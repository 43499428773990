.props-modal .modal-content {
    background-color: #121212 !important;
    color: #ffffff;
    border-radius: 12px;
    border: 1px solid #333;
}

.props-modal .modal-header {
    background-color: #1a1a1a !important;
    border-bottom: 1px solid #333;
    color: #ffffff;
    padding: 15px 20px;
}

.modal-title-content {
    display: flex;
    align-items: center;
    gap: 12px;
}

.game-info {
    font-size: 0.9em;
    color: #6c757d;
    margin-left: 12px;
}

.props-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.category-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    color: #2c3e50;
}

.market-count {
    font-size: 0.8em;
    font-weight: normal;
}

.props-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    background-color: #1a1a1a !important;
    color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.props-table thead th {
    background-color: #2d2d2d !important;
    border-bottom: 2px solid #404040;
    color: #ffffff !important;
    font-weight: 600;
    padding: 8px 12px;
    text-align: center;
}

.prop-row {
    transition: background-color 0.2s;
}

.prop-row:hover {
    background-color: #f8f9fa;
}

.player-name {
    font-weight: 500;
    color: #ffffff !important;
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    word-wrap: break-word;
    text-align: left;
    padding: 8px 12px;
    font-size: 0.95rem;
}

.line-value {
    font-family: monospace;
    font-weight: 600;
    font-size: 1.1em;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 6px 10px;
    border-radius: 4px;
    display: inline-block;
    min-width: 50px;
    text-align: center;
}

.value-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    color: white;
    font-weight: 500;
    text-align: center;
    min-width: 40px;
}

.odds {
    font-family: monospace;
    font-weight: 500;
}

.positive-odds {
    color: #4ade80 !important;
    font-weight: 600;
    padding: 6px 8px;
    border-radius: 4px;
    background-color: rgba(74, 222, 128, 0.1);
}

.negative-odds {
    color: #f87171 !important;
    font-weight: 600;
    padding: 6px 8px;
    border-radius: 4px;
    background-color: rgba(248, 113, 113, 0.1);
}

.bookmaker .badge {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.8em;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 40px;
    color: #6c757d;
}

.props-modal .modal-header .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
}

.props-modal .modal-body {
    background-color: #121212 !important;
    padding: 20px;
}

.bookmaker-section {
    background-color: var(--bg-primary);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid var(--bg-tertiary);
}

.bookmaker-section h4 {
    color: var(--text-primary);
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.props-section {
    margin-top: 1rem;
}

.props-section h5 {
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.props-table th,
.props-table td {
    padding: 12px 8px;
    vertical-align: middle;
    border: 1px solid #333;
    overflow: visible;
    white-space: normal;
    word-wrap: break-word;
}

.props-table tbody td {
    padding: 8px 4px;
    text-align: center;
    border-top: 1px solid var(--bg-tertiary);
    background-color: var(--bg-primary);
    color: var(--text-primary);
}

.props-table tbody tr:hover td {
    background-color: var(--bg-tertiary);
}

/* Column widths */
.props-table th:first-child,
.props-table td:first-child {
    width: 20%;
    text-align: left;
    padding-left: 15px;
}

.props-table th:nth-child(2),
.props-table td:nth-child(2) {
    width: 8%;
}

.props-table th:nth-child(n+3):nth-child(-n+8),
.props-table td:nth-child(n+3):nth-child(-n+8) {
    width: 9%;
}

.props-table th:nth-child(9),
.props-table td:nth-child(9) {
    width: 10%;
}

.props-table th:nth-child(10),
.props-table td:nth-child(10) {
    width: 10%;
}

/* Bookmaker columns */
.props-table th[colspan="3"]:not(:last-of-type) {
    width: 18%;
}

/* Historical section */
.props-table th[colspan="3"]:last-of-type {
    width: 16%;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Value column */
.props-table th:last-child,
.props-table td:last-child {
    width: 7%;
    text-align: center;
    padding: 4px;
    border-left: 1px solid var(--bg-tertiary);
}

/* Individual odds columns */
.props-table tr:nth-child(2) th {
    width: auto; /* Let the browser handle individual column widths */
    text-align: center;
    padding: 4px;
}

/* Historical median columns */
.historical-median {
    font-size: 0.85rem;
    padding: 4px 8px;
    text-align: right;
    width: calc(18% / 3);
    background-color: rgba(var(--bg-tertiary-rgb), 0.05);
}

.historical-median .odds-value {
    display: inline-block;
    min-width: 45px;
    text-align: right;
}

/* Value grade colors to match odds colors */
.value-badge.bg-success {
    color: var(--stat-green-text);
    background-color: var(--stat-green-bg);
}

.value-badge.bg-warning {
    color: var(--stat-yellow-text);
    background-color: var(--stat-yellow-bg);
}

.value-badge.bg-secondary {
    color: var(--stat-red-text);
    background-color: var(--stat-red-bg);
}

/* Sample size */
.sample-size {
    font-size: 0.65rem;
    margin-left: 2px;
    color: var(--text-secondary);
}

/* Remove any conflicting styles */
.value-column {
    padding: 4px;
}

/* Value column header */
.props-table th:last-child {
    text-align: center;
    font-size: 0.8rem;
    color: var(--text-secondary);
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Historical median columns */
.historical-median {
    font-size: 0.85rem;
    padding: 4px 8px;
    text-align: right;
    width: calc(18% / 3);
    background-color: rgba(var(--bg-tertiary-rgb), 0.05);
}

.historical-median .odds-value {
    display: inline-block;
    min-width: 45px;
    text-align: right;
}

/* Odds values */
.positive-odds {
    color: var(--stat-green-text);
    background-color: var(--stat-green-bg);
}

.negative-odds {
    color: var(--stat-red-text);
    background-color: var(--stat-red-bg);
}

.error-message {
    text-align: center;
    padding: 1rem;
    color: var(--text-secondary);
    background-color: var(--bg-secondary);
    border-radius: 8px;
}

/* Responsive design */
@media (max-width: 1200px) {
    .props-modal .modal-dialog {
        max-width: 95%;
    }
    
    .props-table {
        font-size: 0.85rem;
    }
    
    .props-table th,
    .props-table td {
        padding: 8px 4px;
    }
    
    .player-name {
        font-size: 0.85rem;
    }
    
    .range {
        display: none;
    }
}

@media (max-width: 992px) {
    .props-modal .modal-dialog {
        max-width: 95%;
        margin: 10px auto;
    }
    
    .props-table {
        font-size: 0.8rem;
    }
}

/* Update modal size */
.props-modal .modal-dialog {
    max-width: 85%;
}

.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.refresh-props-button {
    background-color: var(--accent-primary);
    border: none;
    color: white;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 500;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    transition: all 0.2s ease;
}

.refresh-props-button:hover:not(:disabled) {
    background-color: #1557b0;
    transform: translateY(-2px);
}

.refresh-props-button:disabled {
    background-color: var(--bg-tertiary);
    color: var(--text-secondary);
    cursor: not-allowed;
}

/* Season average column styling */
.season-average {
    font-weight: 500;
    color: var(--text-primary);
    background-color: var(--bg-tertiary);
}

/* Player name probability highlighting */
.player-name {
    font-size: 0.8rem;
    font-weight: 500;
    padding: 6px 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: -4px;
    transition: all 0.2s ease;
}

/* Over/Under highlighting styles */
.player-name.over-likely {
    background: rgba(72, 187, 120, 0.1);
    border-left: 3px solid var(--stat-green-text);
}

.player-name.under-likely {
    background: rgba(245, 101, 101, 0.1);
    border-left: 3px solid var(--stat-red-text);
}

.player-name.neutral {
    background: rgba(var(--bg-tertiary-rgb), 0.08);
    border-left: 3px solid var(--text-secondary);
}

/* Update tooltip styles */
.player-name[title] {
    cursor: help;
    white-space: pre-line;  /* Preserve line breaks in tooltip */
}

/* Custom tooltip styling */
.tooltip .tooltip-inner {
    max-width: 350px !important;
    width: auto;
    padding: 12px !important;
    text-align: left;
    background-color: #2a2a2a !important;
    color: #fff !important;
    border: 1px solid #404040 !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    font-size: 0.9em;
    z-index: 9999 !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    white-space: normal !important;
    hyphens: auto !important;
}

/* Add emphasis to suggested bet */
.tooltip .tooltip-inner strong {
    color: var(--stat-green-text);
    font-weight: bold;
}

/* Bet recommendation badge */
.bet-recommendation {
    font-size: 0.7rem;
    font-weight: 600;
    padding: 3px 6px;
    border-radius: 4px;
    margin-left: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.player-name.over-likely .bet-recommendation {
    background: rgba(72, 187, 120, 0.15);
    color: var(--stat-green-text);
}

.player-name.under-likely .bet-recommendation {
    background: rgba(245, 101, 101, 0.15);
    color: var(--stat-red-text);
}

/* Hover effects */
.player-name:hover {
    transform: translateX(2px);
}

.player-name.over-likely:hover {
    background: rgba(72, 187, 120, 0.15);
}

.player-name.under-likely:hover {
    background: rgba(245, 101, 101, 0.15);
}

.player-name.neutral:hover {
    background: rgba(var(--bg-tertiary-rgb), 0.12);
}

/* Add hover effect to show full range */
.historical-median:hover .range {
    opacity: 1;
}

/* Loading state for historical data */
.loading-historical {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.value-indicator {
    display: flex;
    align-items: center;
    gap: 4px;
}

.value-indicator-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.value-indicator-dot.high {
    background-color: var(--stat-green-text);
}

.value-indicator-dot.medium {
    background-color: var(--stat-yellow-text);
}

.value-indicator-dot.low {
    background-color: var(--stat-gray-text);
}

/* Add a subtle border to separate historical columns */
.props-table td.historical-median {
    border-left: 1px solid var(--bg-tertiary);
    border-right: 1px solid rgba(var(--bg-tertiary-rgb), 0.1);
}

/* Hover effects */
.props-table tbody tr:hover {
    background-color: rgba(var(--bg-tertiary-rgb), 0.05);
}

/* Add neutral odds style */
.neutral-odds {
    padding: 2px 3px;
    min-width: 35px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    font-size: 0.8rem;
    color: var(--text-secondary);
    background-color: var(--bg-tertiary);
}

/* Value tooltip styling */
.value-tooltip-container {
    display: inline-block;
    cursor: help;
}

/* Update value badge for tooltip interaction */
.value-badge {
    transition: opacity 0.2s ease;
}

.value-tooltip-container:hover .value-badge {
    opacity: 0.9;
}

/* Remove any conflicting styles */
.props-table tbody tr:hover td {
    background: transparent;
}

.props-table tbody tr:hover .player-name {
    opacity: 1;
}

/* Modern tooltip styling */
.modern-tooltip {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    padding: 4px;
}

.tooltip-grade {
    font-weight: 600;
    padding: 8px 12px;
    border-radius: 6px;
    margin-bottom: 8px;
    font-size: 0.9rem;
}

/* Grade styling */
.value-grade {
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    min-width: 40px;
}

.grade-a-plus {
    background-color: #28a745;
    color: white;
}

.grade-a {
    background-color: #34c759;
    color: white;
}

.grade-b-plus {
    background-color: #5856d6;
    color: white;
}

.grade-b {
    background-color: #007bff;
    color: white;
}

.grade-c-plus {
    background-color: #ff9500;
    color: white;
}

.grade-c {
    background-color: #ff3b30;
    color: white;
}

.grade-c-minus {
    background-color: #dc3545;
    color: white;
}

.grade-d {
    background-color: #6c757d;
    color: white;
}

.grade-n-a {
    background-color: #e9ecef;
    color: #495057;
}

.tooltip-content {
    padding: 8px 0;
}

.tooltip-header {
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--text-primary);
}

.tooltip-detail {
    font-size: 0.85rem;
    color: var(--text-secondary);
    margin: 4px 0;
    line-height: 1.4;
}

.tooltip-suggestion {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid rgba(var(--bg-tertiary-rgb), 0.2);
}

.highlight {
    color: var(--stat-green-text);
    font-weight: 600;
}

.warning {
    color: var(--stat-red-text);
    font-style: italic;
}

/* Tippy theme overrides */
.tippy-box[data-theme~='custom'] {
    background-color: var(--bg-primary);
    border: 1px solid var(--bg-tertiary);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    max-width: 320px !important;
    color: var(--text-primary);
}

.tippy-box[data-theme~='custom'] .tippy-content {
    padding: 12px;
}

.tippy-box[data-theme~='custom'] .tippy-arrow {
    color: var(--bg-primary);
}

.tippy-box[data-theme~='custom'][data-placement^='top'] > .tippy-arrow:before {
    border-top-color: var(--bg-primary);
}

.tippy-box[data-theme~='custom'][data-placement^='bottom'] > .tippy-arrow:before {
    border-bottom-color: var(--bg-primary);
}

.tippy-box[data-theme~='custom'][data-placement^='left'] > .tippy-arrow:before {
    border-left-color: var(--bg-primary);
}

.tippy-box[data-theme~='custom'][data-placement^='right'] > .tippy-arrow:before {
    border-right-color: var(--bg-primary);
}

/* Tooltip section styling */
.tooltip-section {
    padding: 8px 0;
    border-bottom: 1px solid rgba(var(--bg-tertiary-rgb), 0.2);
}

.tooltip-section:last-child {
    border-bottom: none;
}

.tooltip-section .tooltip-header {
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 4px;
    font-size: 0.85rem;
}

/* Update existing tooltip styles */
.modern-tooltip {
    min-width: 220px;
}

.tooltip-grade.over-likely {
    background: rgba(72, 187, 120, 0.1);
    color: var(--stat-green-text);
}

.tooltip-grade.under-likely {
    background: rgba(245, 101, 101, 0.1);
    color: var(--stat-red-text);
}

/* Highlight values */
.highlight {
    font-weight: 600;
    font-family: monospace;
    padding: 1px 4px;
    border-radius: 3px;
    background: rgba(var(--bg-tertiary-rgb), 0.1);
}

/* Adjust Tippy arrow for player name tooltips */
.player-name-cell .tippy-box[data-placement^='right'] > .tippy-arrow:before {
    border-right-color: var(--bg-primary);
}

.props-modal.dark-theme {
    --bg-primary: #1a1a1a;
    --bg-secondary: #2d2d2d;
    --text-primary: #ffffff;
    --text-secondary: #b3b3b3;
    --border-color: #404040;
}

.props-modal.dark-theme .modal-content,
.props-modal.dark-theme .modal-body,
.props-modal.dark-theme .props-table {
    background-color: var(--bg-primary);
    color: var(--text-primary);
}

.props-table {
    border-collapse: separate;
    border-spacing: 0;
}

.props-table th,
.props-table td {
    border: 1px solid var(--border-color);
    padding: 8px;
}

.player-name-container {
    display: flex;
    align-items: center;
    padding: 4px 0;
    max-width: none;
    width: 100%;
}

.bet-direction-badge {
    font-size: 0.7em;
    padding: 3px 6px;
    cursor: help;
    order: -1; /* This puts the badge before the player name */
    min-width: 85px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.bet-direction-badge:hover {
    opacity: 0.9;
}

/* Update success and danger colors for better visibility */
.badge.bg-success {
    background-color: #00c851 !important;
}

.badge.bg-danger {
    background-color: #ff3547 !important;
}

.odds-header {
    text-align: center;
    font-size: 0.85em;
    white-space: nowrap;
}

.odds-cell {
    text-align: right;
    padding: 8px 12px;
}

.bookmaker-badge {
    font-size: 0.8em;
    padding: 4px 8px;
}

.bookmaker-badge.best {
    background-color: #28a745;
}

/* Additional styles for tooltips and other elements... */

/* Fix header styling */
.props-table thead tr.header-row th {
    background-color: #2d2d2d !important;
    color: #ffffff !important;
    border: 1px solid var(--border-color, #444);
    font-weight: 600;
    padding: 14px 8px;
    text-transform: uppercase;
    font-size: 0.85rem;
    letter-spacing: 0.5px;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Fix modal header */
.props-modal.dark-theme .modal-header {
    background-color: var(--bg-secondary);
    border-bottom: 1px solid var(--border-color);
    color: var(--text-primary);
}

/* Fix category header */
.category-header {
    color: var(--text-primary) !important;
}

/* Fix table borders and backgrounds */
.props-table {
    background-color: var(--bg-primary) !important;
    border: 1px solid var(--border-color);
}

.props-table td {
    background-color: var(--bg-primary) !important;
    border: 1px solid var(--border-color);
    color: var(--text-primary);
}

/* Fix player name styling */
.player-name {
    color: var(--text-primary) !important;
}

/* Fix odds styling */
.odds-cell {
    background-color: var(--bg-primary) !important;
    color: var(--text-primary);
}

.positive-odds {
    color: #00ff00 !important; /* Brighter green for better visibility */
}

.negative-odds {
    color: #ff4444 !important; /* Brighter red for better visibility */
}

/* Fix hover effects */
.props-table tr:hover td {
    background-color: var(--bg-secondary) !important;
}

/* Fix value badge */
.value-badge {
    opacity: 0.9;
    font-weight: 600;
}

/* Fix bookmaker badge */
.bookmaker-badge {
    opacity: 0.9;
}

/* Fix close button */
.props-modal.dark-theme .modal-header .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
}

/* Fix tooltip styling */
.tooltip-inner {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
}

/* Compact the odds columns */
.odds-header {
    padding: 8px 4px !important;
    font-size: 0.8em;
    white-space: nowrap;
}

.odds-cell {
    padding: 8px 4px !important;
}

/* Make the modal scrollable if needed */
.props-modal .modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

/* Add these styles */
.recommendation-details {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid var(--border-color);
}

.recommendation-details div {
    font-size: 0.85em;
    line-height: 1.4;
}

.bet-direction-badge {
    cursor: help;
    transition: opacity 0.2s;
}

.bet-direction-badge:hover {
    opacity: 0.8;
}

.ev-value {
    display: block;
    padding: 2px 4px;
    border-radius: 3px;
    margin: 2px 0;
}

.excellent-ev { background-color: #28a745; color: white; }
.good-ev { background-color: #5cb85c; color: white; }
.neutral-ev { background-color: #6c757d; color: white; }
.negative-ev { background-color: #dc3545; color: white; }

.bet-direction-badge {
    font-size: 0.8em;
    padding: 3px 6px;
}

.movement-indicator {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    font-size: 0.9em;
    cursor: help;
}

.movement-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-success { color: #28a745 !important; }
.text-danger { color: #dc3545 !important; }
.text-muted { color: #6c757d !important; }

/* Make tooltips more visible in dark theme */
.tooltip .tooltip-inner {
    background-color: #2a2a2a;
    border: 1px solid #404040;
    padding: 8px;
    font-size: 0.9em;
    max-width: 300px;
}

.tooltip .tooltip-inner div {
    margin: 2px 0;
    white-space: nowrap;
}

/* Market Efficiency Section Styles */
.market-efficiency-section {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.market-efficiency-section .section-header {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
    display: flex;
    align-items: center;
}

.efficiency-description {
    color: #6c757d;
    margin-bottom: 15px;
    font-size: 0.85rem;
}

.efficiency-metrics {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
}

.efficiency-metric {
    flex: 1 1 200px;
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 12px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
}

.efficiency-metric:hover {
    transform: translateY(-2px);
}

.metric-header {
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 8px;
    color: #495057;
}

.metric-value {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 4px;
}

.metric-label {
    font-size: 0.85rem;
    color: #6c757d;
    margin-bottom: 8px;
}

.metric-details {
    font-size: 0.75rem;
    color: #6c757d;
    display: flex;
    justify-content: center;
    gap: 8px;
}

/* Dark theme support */
.props-modal.dark-theme .market-efficiency-section {
    background-color: #2a2a2a;
    color: #e0e0e0;
    border: 1px solid #444;
}

.props-modal.dark-theme .market-efficiency-section .section-header {
    color: #ecf0f1;
}

.props-modal.dark-theme .efficiency-description {
    color: #bdc3c7;
}

.props-modal.dark-theme .efficiency-metric {
    background-color: #34495e;
}

.props-modal.dark-theme .metric-header {
    color: #ecf0f1;
}

.props-modal.dark-theme .metric-label,
.props-modal.dark-theme .metric-details {
    color: #bdc3c7;
}

/* Tooltip improvements */
.tooltip-inner {
    max-width: 350px !important;
    width: auto;
    padding: 12px !important;
    text-align: left;
    background-color: #2a2a2a !important;
    color: #fff !important;
    border: 1px solid #404040 !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    font-size: 0.9em;
    z-index: 9999 !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    white-space: normal !important;
    hyphens: auto !important;
}

.enhanced-tooltip .tooltip-inner {
    background-color: #1e1e1e !important;
    border: 1px solid #505050 !important;
    padding: 14px !important;
    max-width: 400px !important;
}

.bet-tooltip-content {
    min-width: 250px;
    max-width: 100%;
    word-wrap: break-word !important;
    word-break: break-word !important;
    white-space: normal !important;
    overflow-wrap: break-word !important;
}

.tooltip-header {
    font-weight: bold;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 4px;
    color: #fff;
    font-size: 1.1em;
    overflow-wrap: break-word !important;
    white-space: normal !important;
}

.recommendation-reason {
    margin-bottom: 8px;
    font-size: 0.9em;
    line-height: 1.5;
    white-space: normal !important;
    word-wrap: break-word !important;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    color: #ddd;
    padding-right: 4px;
}

.significant-edge {
    margin-top: 8px;
    font-weight: bold;
    color: #ffc107;
}

.significant-edge span {
    color: #ffc107;
    margin-right: 2px;
}

/* Ensure tooltips are visible */
.tooltip {
    opacity: 1 !important;
    z-index: 9999 !important;
    pointer-events: none;
}

/* Ensure tooltip arrows are visible */
.tooltip .arrow::before {
    border-top-color: #2a2a2a !important;
    border-bottom-color: #2a2a2a !important;
}

/* Star rating colors */
.rating-star {
    color: #ffc107;
    margin-right: 2px;
}

.bet-rating {
    margin-top: 5px;
}

/* Improve spacing in bet details */
.bet-details {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
}

.bet-point {
    font-weight: 600;
    font-family: monospace;
}

.bet-type-badge {
    font-size: 0.7em;
    padding: 3px 6px;
}

.bet-odds {
    font-weight: 600;
    font-family: monospace;
    padding: 2px 4px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.1);
    min-width: 60px;
    text-align: center;
    display: inline-block;
}

.bet-odds-details {
    margin-top: 8px !important;
    white-space: normal !important;
    word-wrap: break-word !important;
    line-height: 1.5;
}

.text-success {
    color: #28a745 !important;
}

.text-danger {
    color: #dc3545 !important;
}

.positive-odds {
    color: #28a745;
}

.negative-odds {
    color: #dc3545;
}

.bet-rating {
    margin-top: 4px;
    color: #ffc107;
}

/* Improve market efficiency section */
.market-efficiency-section {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.dark-theme .market-efficiency-section {
    background-color: #2a2a2a;
    color: #e0e0e0;
    border: 1px solid #444;
}

/* Additional tooltip improvements */
.ev-tooltip .tooltip-inner {
    max-width: 200px !important;
}

/* Improve badge spacing */
.bet-direction-badge {
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
}

.bet-direction-badge .me-1 {
    margin-right: 4px;
}

/* Improve odds display */
.odds-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px;
}

.odds-value {
    margin-right: 8px;
    font-weight: bold;
    flex: 1;
    text-align: left;
}

.ev-badge {
    font-size: 0.8em;
    padding: 3px 6px;
    border-radius: 4px;
    white-space: nowrap;
}

/* Star styling */
.star {
    display: inline-block;
    margin-left: 2px;
}

.edge-rating {
    margin-left: 4px;
}

/* Improve tooltip positioning */
.tooltip {
    z-index: 9999 !important;
}

/* Ensure tooltips don't get cut off */
.modal-body {
    overflow: visible;
}

/* Fix odds table alignment */
.props-tables-container {
    overflow-x: auto;
    margin-bottom: 20px;
}

.market-section {
    margin-bottom: 30px !important;
    background-color: #1a1a1a;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #333;
}

.market-title {
    color: #ffffff !important;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
}

/* Props tables container */
.props-tables-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 10px;
}

.props-table {
    margin-bottom: 0;
    border-collapse: collapse;
}

/* Fix column widths for bookmaker columns */
.props-table th:nth-child(3),
.props-table th:nth-child(4),
.props-table th:nth-child(5),
.props-table th:nth-child(6),
.props-table th:nth-child(7),
.props-table th:nth-child(8) {
    width: 10%;
}

/* Fix cell content alignment */
.props-table td {
    vertical-align: middle;
}

/* Fix odds display */
.odds-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px;
}

.odds-value {
    display: inline-block;
    min-width: 40px;
    text-align: right;
    margin-right: 8px;
    font-weight: bold;
}

/* Fix EV badge display */
.ev-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 45px;
    font-size: 0.8em;
    padding: 3px 6px;
    border-radius: 4px;
    white-space: nowrap;
}

.ev-badge.high {
    background-color: #28a745;
    color: white;
}

.ev-badge.medium {
    background-color: #17a2b8;
    color: white;
}

.ev-badge.low {
    background-color: #ffc107;
    color: black;
}

.ev-badge.negative {
    background-color: #dc3545;
    color: white;
}

/* Fix player name container */
.player-name-container {
    display: flex;
    align-items: center;
    padding: 4px 0;
    max-width: none;
    width: 100%;
}

.player-name {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    max-width: none;
    display: inline-block;
    text-align: left;
}

/* Fix bet direction badge */
.bet-direction-badge {
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    white-space: nowrap;
}

.bet-direction-badge .me-1 {
    margin-right: 4px;
}

/* Fix movement indicator */
.movement-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.movement-indicator {
    margin-left: 4px;
    display: inline-flex;
}

/* Fix best odds display */
.best-odds {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    text-align: left;
    padding: 4px;
}

.best-over, .best-under {
    white-space: nowrap;
    font-size: 0.85em;
}

/* Fix market header */
.market-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
}

.market-count {
    font-size: 0.8em;
    opacity: 0.8;
}

/* Fix line container */
.line-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Fix table cell padding and alignment */
.props-table td, 
.props-table th {
    padding: 10px 8px;
    vertical-align: middle;
    border: 1px solid var(--border-color, #404040);
}

.props-table td:first-child, 
.props-table th:first-child {
    text-align: left;
    padding-left: 15px;
    min-width: 180px;
    max-width: 250px;
}

.props-table td:not(:first-child), 
.props-table th:not(:first-child) {
    text-align: center;
}

/* Ensure consistent dark theme */
.props-table {
    background-color: #1a1a1a !important;
    color: #ffffff;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    width: 100%;
}

.props-table tbody tr {
    background-color: #1a1a1a;
}

.props-table tbody tr:nth-child(odd) {
    background-color: #252525;
}

.props-table tbody tr:hover {
    background-color: #333333 !important;
}

.props-table tbody tr:hover td {
    background-color: transparent !important;
}

/* Ensure tooltips don't affect layout */
.tooltip {
    pointer-events: none;
    z-index: 9999 !important;
}

/* Ensure tooltips don't get cut off */
.modal-body {
    overflow: visible;
}

/* Best Book styling */
td:last-child .d-flex {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
}

td:last-child small {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 4px 8px;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    font-weight: 500;
}

/* Improve positive and negative odds display */
.positive-odds, .negative-odds {
    display: inline-block;
    text-align: center;
    min-width: 60px;
}

/* Sort indicators styling */
.sort-asc, .sort-desc {
    position: relative;
}

.sort-asc span, .sort-desc span {
    margin-left: 5px;
    font-size: 0.8rem;
}

/* Add hover effect to sortable headers */
th[role="columnheader"] {
    cursor: pointer;
    transition: background-color 0.2s;
}

th[role="columnheader"]:hover {
    background-color: #3d3d3d !important;
}

/* Responsive table container */
.table-responsive {
    overflow-x: auto;
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Efficiency tooltip styles */
.efficiency-tooltip {
    min-width: 250px;
    max-width: 400px;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
}

.efficiency-detail {
    margin-bottom: 8px;
    font-size: 0.9em;
    color: #ddd;
    line-height: 1.5;
    white-space: normal !important;
    word-wrap: break-word !important;
}

.efficiency-explanation {
    font-size: 0.9em;
    line-height: 1.5;
    color: #bbb;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 10px;
    margin-top: 10px;
    white-space: normal !important;
    word-wrap: break-word !important;
}

/* Make efficiency metrics clickable */
.efficiency-metric {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.efficiency-metric:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* New bet tooltip structure styles */
.bet-main-details {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 1em;
    text-align: center;
    word-break: normal;
}

.recommendation-reasons {
    padding: 0 4px;
    margin-bottom: 8px;
}

/* Make tooltip text more compact but still legible */
.tooltip-inner {
    max-width: 350px !important;
    width: auto;
    padding: 12px !important;
    text-align: left;
    background-color: #2a2a2a !important;
    color: #fff !important;
    border: 1px solid #404040 !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    font-size: 0.9em;
    line-height: 1.4;
    z-index: 9999 !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    white-space: normal !important;
    hyphens: auto !important;
}

.recommendation-reason {
    margin-bottom: 6px;
    font-size: 0.85em;
    line-height: 1.4;
    white-space: normal !important;
    word-wrap: break-word !important;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    color: #ddd;
    padding-right: 4px;
}

.significant-edge {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-weight: bold;
    color: #ffc107;
    text-align: center;
}

/* Efficiency metrics container */
.efficiency-metrics-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 8px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 8px;
    border-radius: 4px;
}

.efficiency-metrics-container .efficiency-detail {
    flex-basis: 30%;
    margin-bottom: 4px;
    font-size: 0.85em;
}

/* Improved efficiency tooltip */
.efficiency-tooltip {
    min-width: 250px;
    max-width: 350px;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
}

.efficiency-explanation {
    font-size: 0.85em;
    line-height: 1.5;
    color: #bbb;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 10px;
    margin-top: 6px;
    white-space: normal !important;
    word-wrap: break-word !important;
}

.efficiency-explanation strong {
    color: #ffc107;
    display: block;
    margin-top: 5px;
}

/* Enhanced Tooltip Styles */
.tooltip.enhanced-tooltip {
    opacity: 1 !important;
    z-index: 9999 !important;
    pointer-events: none;
}

.tooltip.enhanced-tooltip .tooltip-inner {
    max-width: 350px !important;
    width: auto !important;
    background-color: rgba(33, 37, 41, 0.98) !important;
    color: white !important;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.4) !important;
    padding: 12px !important;
    text-align: left !important;
    word-wrap: break-word !important;
    white-space: normal !important;
    font-size: 13px !important;
}

.tooltip.enhanced-tooltip .arrow::before {
    border-top-color: rgba(33, 37, 41, 0.98) !important;
}

.tooltip.enhanced-tooltip strong {
    color: #ffc107 !important;
    font-weight: 600 !important;
}

/* Make all tooltip trigger elements show a pointer cursor */
[data-toggle="tooltip"] {
    cursor: pointer !important;
}

/* Market Efficiency Section */
.market-efficiency-section {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
}

.section-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #333;
}

.efficiency-description {
    color: #666;
    margin-bottom: 12px;
}

.efficiency-metrics {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
}

.efficiency-metric {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 6px;
    padding: 10px;
    flex: 1;
    min-width: 120px;
    max-width: 180px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.2s ease;
}

.efficiency-metric:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.metric-header {
    font-size: 13px;
    font-weight: 600;
    color: #555;
    margin-bottom: 5px;
}

.metric-value {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 2px;
}

.metric-label {
    font-size: 13px;
    color: #666;
    margin-bottom: 8px;
}

.metric-details {
    font-size: 11px;
    color: #777;
}

/* FanDuel Recommendation Styles */
.fanduel-recommendations {
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
}

.recommendations-header {
    background-color: #1A1E21;
    color: white;
    padding: 10px 15px;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.fanduel-logo {
    color: #1493FF;
    font-weight: 700;
    margin-right: 8px;
}

.recommendations-table {
    margin-bottom: 0;
}

.market-cell {
    font-weight: 500;
    width: 150px;
    vertical-align: middle !important;
}

.bet-cell {
    width: calc(50% - 150px);
    vertical-align: middle !important;
    padding: 8px !important;
}

.bet-content {
    display: flex;
    flex-direction: column;
    padding: 5px;
    position: relative;
}

.bet-player {
    font-weight: 500;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bet-details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 13px;
}

.bet-point {
    font-weight: 500;
}

.bet-type {
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
}

.over-type {
    background-color: rgba(40, 167, 69, 0.2);
    color: #28a745;
}

.under-type {
    background-color: rgba(220, 53, 69, 0.2);
    color: #dc3545;
}

.bet-odds {
    font-weight: 600;
}

.positive-odds {
    color: #28a745;
}

.negative-odds {
    color: #dc3545;
}

.bet-rating {
    margin-top: 5px;
}

.rating-star {
    color: #ffc107;
    font-size: 14px;
}

/* Specific table styling for recommendations */
.recommendations-table {
    background-color: #212529;
}

.recommendations-table th {
    background-color: #343a40;
    color: #fff;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-color: #495057;
}

.recommendations-table td {
    border-color: #495057;
    vertical-align: middle;
}

.recommendations-table tbody tr {
    transition: background-color 0.2s;
}

.recommendations-table tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

/* Test Change - This should make all tooltips have a bright, noticeable border */
.tooltip {
    opacity: 1 !important;
    z-index: 9999 !important;
    pointer-events: none;
}

.tooltip .tooltip-inner {
    border: 3px solid #28a745 !important; /* Changed from magenta to green */
    max-width: 350px !important;
    background-color: rgba(50, 50, 50, 0.98) !important;
    color: white !important;
    padding: 12px !important;
    text-align: left !important;
    font-size: 14px !important;
}

.refresh-props-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 10px;
    font-size: 0.9rem;
}

.refresh-props-btn svg {
    margin-right: 2px;
} 