.protected-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    padding: 2rem;
    background-color: var(--bg-secondary);
    border-radius: 12px;
}

.auth-prompt {
    text-align: center;
    padding: 2rem;
    background-color: var(--bg-tertiary);
    border-radius: 8px;
    max-width: 400px;
}

.auth-prompt h3 {
    color: var(--text-primary);
    margin-bottom: 1rem;
}

.auth-prompt p {
    color: var(--text-secondary);
    margin-bottom: 1.5rem;
}

.auth-button {
    background-color: var(--accent-primary);
    border: none;
    padding: 0.75rem 2rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.auth-button:hover {
    transform: translateY(-2px);
    background-color: var(--accent-primary);
    filter: brightness(1.1);
} 