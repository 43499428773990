.odds-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #121212;
    color: #fff;
    border-radius: 12px;
    padding: 24px;
    margin: 0 auto;
    width: 100%;
    min-height: 200px;
    max-width: 1200px;
    box-sizing: border-box;
}

.odds-header {
    position: relative;
    width: 100%;
    background-color: #1a1a1a;
    padding: 20px 24px;
    border-radius: 10px;
    margin-bottom: 24px;
    border-bottom: 1px solid #333;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
}

.header-left {
    display: flex;
    align-items: center;
    padding-right: 16px;
}

.header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.header-left h2 {
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
    white-space: nowrap;
    letter-spacing: -0.5px;
    background: linear-gradient(90deg, #4facfe 0%, #00f2fe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 0 2px 10px rgba(79, 172, 254, 0.15);
    position: relative;
    padding-bottom: 3px;
}

.header-left h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 3px;
    background: linear-gradient(90deg, #4facfe 0%, #00f2fe 100%);
    border-radius: 3px;
}

.controls-group {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.odds-content {
    position: relative;
    width: 100%;
    padding: 0 4px;
}

/* Debug Info styling */
.debug-panel {
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 15px;
    margin-top: 20px;
    border: 1px solid #333;
}

.debug-panel h4 {
    margin-top: 0;
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.debug-panel div {
    margin-bottom: 5px;
    color: #ddd;
}

/* Alert styling */
.alert {
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 24px;
    text-align: center;
    font-weight: 500;
}

.alert-info {
    background-color: #1a2a3a;
    color: #61dafb;
    border: 1px solid #2a3a4a;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

/* Table styling */
.odds-table-container {
    position: relative;
    width: 100%;
    overflow-x: auto;
    border-radius: 10px;
    background-color: #1a1a1a;
    box-sizing: border-box;
    padding: 4px;
    margin-bottom: 24px;
}

.odds-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #1a1a1a;
    margin-bottom: 0;
}

.odds-table thead th {
    background-color: #000000;
    color: #ffffff;
    border: none;
    padding: 16px 12px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 10;
}

.odds-table th:first-child {
    text-align: left;
    padding-left: 20px;
}

.odds-table th:last-child {
    text-align: center;
}

.odds-table tbody tr {
    background-color: #1c1c1c;
    border-bottom: 1px solid #2d2d2d;
    transition: background-color 0.2s;
}

.odds-table tbody tr:hover {
    background-color: #252525;
}

.odds-table td {
    border: none;
    padding: 16px 12px;
    vertical-align: middle;
    text-align: center;
    transition: background-color 0.2s;
}

.odds-table td:first-child {
    text-align: left;
    padding-left: 20px;
}

.matchup-column {
    min-width: 200px;
    width: 25%;
}

.moneyline-column, .spread-column, .total-column {
    min-width: 150px;
    width: 20%;
}

.actions-column {
    min-width: 120px;
    width: 15%;
    text-align: center;
}

/* Button styling */
.props-button {
    background-color: #0d6efd;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 8px 16px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s;
    width: 100%;
    max-width: 120px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.props-button:hover {
    background-color: #0b5ed7;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.refresh-button {
    background-color: #28a745;
    border: none;
    padding: 6px 14px;
    font-size: 0.9rem;
    transition: background-color 0.2s;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.refresh-button:hover {
    background-color: #218838;
    transform: translateY(-1px);
}

/* Date selector - smaller size */
.date-selector {
    background-color: #2d2d2d;
    color: #fff;
    border: 1px solid #404040;
    border-radius: 6px;
    padding: 6px 10px;
    min-width: 130px;
    height: 36px;
    font-size: 0.9rem;
    transition: all 0.2s ease;
}

.date-selector:focus {
    background-color: #2d2d2d;
    color: #fff;
    border-color: #4facfe;
    box-shadow: 0 0 0 0.2rem rgba(79, 172, 254, 0.25);
    outline: none;
}

/* Auto-refresh controls - matching size */
.auto-refresh-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #ffffff;
    height: 36px;
    white-space: nowrap;
}

.auto-refresh-toggle {
    display: flex;
    align-items: center;
    margin: 0;
}

.auto-refresh-toggle .form-check-input {
    background-color: #333;
    border-color: #555;
    margin-right: 8px;
}

.auto-refresh-toggle .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.auto-refresh-toggle .form-check-label {
    color: #fff;
    font-size: 0.9rem;
}

.countdown-timer {
    color: #aaa;
    font-size: 0.9rem;
    white-space: nowrap;
    margin-left: 5px;
}

.api-info {
    margin-left: 15px;
    padding: 6px 10px;
    background-color: #e7f5ea;
    border: 1px solid #81c784;
    border-radius: 4px;
    color: #2e7d32;
    font-size: 0.85rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.api-info svg {
    margin-right: 6px;
}

.api-info.warning {
    background-color: #fff8e1;
    border-color: #ffca28;
    color: #f57f17;
}

.api-info.danger {
    background-color: #ffebee;
    border-color: #ef5350;
    color: #c62828;
}

.api-info.caution {
    background-color: #fff3e0;
    border-color: #ffb74d;
    color: #e65100;
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .odds-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 20px;
        gap: 16px;
    }
    
    .header-right {
        width: 100%;
        align-items: flex-start;
    }
    
    .controls-group {
        margin-top: 8px;
        width: 100%;
        justify-content: flex-start;
    }
}

@media (max-width: 768px) {
    .odds-container {
        padding: 16px 12px;
        border-radius: 10px;
    }
    
    .odds-header {
        padding: 14px 16px;
        margin-bottom: 16px;
    }
    
    .header-left h2 {
        font-size: 1.6rem;
        padding-bottom: 5px;
    }
    
    .header-left h2::after {
        width: 30px;
        height: 2px;
    }
    
    .controls-group {
        flex-wrap: wrap;
        gap: 12px;
    }
    
    .date-selector {
        width: calc(50% - 6px);
        min-width: 120px;
    }
    
    .refresh-button {
        width: calc(50% - 6px);
    }
    
    .auto-refresh-controls {
        width: 100%;
        justify-content: flex-start;
        margin-top: 8px;
    }
    
    .odds-table-container {
        padding: 2px;
        border-radius: 8px;
        margin-bottom: 16px;
    }
    
    .odds-table td, .odds-table th {
        padding: 12px 8px;
        font-size: 0.9rem;
    }
    
    .alert {
        padding: 16px;
        margin-bottom: 16px;
        border-radius: 8px;
    }
}

@media (max-width: 480px) {
    .odds-container {
        padding: 12px 8px;
    }
    
    .odds-header {
        padding: 12px;
    }
    
    .header-left h2 {
        font-size: 1.4rem;
    }
    
    .date-selector, .refresh-button {
        width: 100%;
    }
    
    .controls-group {
        gap: 8px;
    }
    
    .odds-table td, .odds-table th {
        padding: 10px 6px;
        font-size: 0.85rem;
    }
}

/* Keep existing team info styling */
.team-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #121212;
    padding: 10px;
    border-radius: 4px;
    width: 100%;
}

.team-info span {
    color: #ffffff;
    font-weight: 500;
}

.home-label, .away-label {
    color: #888;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #000000;
    padding: 2px 6px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 8px;
}

.game-details {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 5px 0;
}

.game-time {
    color: #aaa;
    font-size: 0.85rem;
}

.hold-badge {
    font-size: 0.75rem;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: 500;
}

.hold-badge.excellent-hold {
    background-color: #198754;
    color: white;
}

.hold-badge.good-hold {
    background-color: #0d6efd;
    color: white;
}

.hold-badge.fair-hold {
    background-color: #fd7e14;
    color: white;
}

.hold-badge.high-hold {
    background-color: #dc3545;
    color: white;
}

/* Odds styling */
.odds {
    padding: 8px;
    border-radius: 4px;
    background-color: #333333;
    margin: 4px 0;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-block;
    min-width: 100px;
}

.odds:hover {
    background-color: #444444;
}

.positive-odds {
    color: #28a745;
}

.negative-odds {
    color: #dc3545;
}

/* More specific selectors for table styling */
table.odds-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1c1c1c;
}

table.odds-table thead th {
    background-color: #000000;
    color: #ffffff;
    border: none;
    padding: 12px 8px;
    font-weight: 500;
    text-transform: uppercase;
}

table.odds-table tbody tr {
    background-color: #1c1c1c;
    border-bottom: 1px solid #2d2d2d;
}

table.odds-table td {
    border: none;
    padding: 12px 8px;
    vertical-align: middle;
}

.team-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #121212 !important;
    padding: 8px;
    border-radius: 4px;
}

.team-info span {
    color: #ffffff !important;
}

.home-label, .away-label {
    color: #888;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #000000;
    padding: 2px 6px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 8px;
}

.game-time {
    color: #888;
    font-size: 0.9rem;
    margin: 8px 0;
}

.odds {
    padding: 8px;
    border-radius: 4px;
    background-color: #333333;
    margin: 2px 0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.odds:hover {
    background-color: #444444;
}

/* Color coding for odds values */
.spread-odds, .total-odds {
    font-weight: 500;
}

.positive-odds {
    color: #28a745;  /* Green */
}

.negative-odds {
    color: #dc3545;  /* Red */
}

/* Numbers styling */
.spread-number, .total-number {
    color: #ffffff !important;
    font-weight: 600;
}

.spread-value, .total-value {
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 500;
    color: #ffffff !important;
}

.spread-container, .total-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #ffffff !important;
}

/* Only color the odds portion in green/red */
.spread-odds.positive-odds, .total-odds.positive-odds {
    color: #28a745 !important;
}

.spread-odds.negative-odds, .total-odds.negative-odds {
    color: #dc3545 !important;
}

.props-button {
    background-color: #7090c0;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 0.9rem;
    cursor: pointer;
}

.props-button:hover {
    background-color: #0052cc;
}

.controls-group {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.date-selector {
    background-color: #2d2d2d;
    color: #fff;
    border: 1px solid #404040;
    border-radius: 4px;
    padding: 8px 12px;
    min-width: 150px;
    height: 40px;
}

.date-selector:focus {
    background-color: #2d2d2d;
    color: #fff;
    border-color: #666;
}

.refresh-button {
    background-color: #28a745;
    border: none;
    padding: 8px 16px;
    font-size: 0.9rem;
    transition: background-color 0.2s;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.refresh-button:hover {
    background-color: #218838;
}

.auto-refresh-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #ffffff;
    height: 40px;
}

.countdown-timer {
    color: #888;
    font-size: 0.9em;
}

.api-info {
    color: #888;
    font-size: 0.9em;
    margin-top: 10px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
}

.error-message {
    background-color: #332d2d;
    color: #dc3545;
    border-color: #443333;
}

/* Tooltip customization */
.tooltip .tooltip-inner {
    background-color: #333333;
    color: #ffffff;
    padding: 8px;
    border-radius: 4px;
}

.date-input {
    background-color: var(--bg-tertiary);
    border: 1px solid var(--bg-tertiary);
    color: var(--text-primary);
    border-radius: 8px;
    padding: 10px;
    font-size: 0.9rem;
    transition: all 0.2s ease;
}

.date-input:focus {
    border-color: var(--accent-primary);
    box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.odds-row {
    transition: background-color 0.2s ease;
}

.odds-row:hover {
    background-color: var(--bg-primary);
}

.team-names {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    background-color: var(--bg-primary);
    border-radius: 8px;
    transition: all 0.2s ease;
}

.team-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 4px 0;
}

.team-label {
    font-size: 0.7rem;
    font-weight: 600;
    color: var(--text-secondary);
    background-color: var(--bg-tertiary);
    padding: 2px 6px;
    border-radius: 4px;
    letter-spacing: 0.5px;
}

.home-team, .away-team {
    color: var(--text-primary);
    font-weight: 500;
    font-size: 0.95rem;
    padding: 4px 8px;
    border-radius: 4px;
    flex: 1;
}

.props-button:disabled {
    background-color: var(--bg-tertiary);
    color: var(--text-secondary);
    cursor: not-allowed;
}

.refresh-button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    white-space: nowrap;
    font-size: 0.9rem;
}

.refresh-icon {
    font-size: 1rem;
    line-height: 1;
    display: flex;
    align-items: center;
}

.refresh-button .spinner-border {
    margin: 0;
    width: 1.2rem;
    height: 1.2rem;
}

/* Update column widths */
.odds-table th:nth-child(1),
.odds-table td:nth-child(1) {
    width: 30%;
}

.odds-table th:nth-child(2),
.odds-table td:nth-child(2),
.odds-table th:nth-child(3),
.odds-table td:nth-child(3),
.odds-table th:nth-child(4),
.odds-table td:nth-child(4) {
    width: 20%;
}

.odds-table th:nth-child(5),
.odds-table td:nth-child(5) {
    width: 10%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .odds-container {
        padding: 16px;
    }

    .header-content {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

    .odds-title {
        font-size: 1.5rem;
    }

    .date-selector {
        width: 100%;
    }

    .odds-table td, .odds-table th {
        padding: 12px;
        font-size: 0.9rem;
    }

    .controls-group {
        width: 100%;
        justify-content: flex-start;
    }

    .refresh-button {
        width: 100%;
    }

    .auto-refresh-button {
        width: 100%;
    }

    .odds-table th,
    .odds-table td {
        min-width: 120px;
    }

    .matchup-column {
        min-width: 200px;
    }
}

.odds-value.clickable {
    cursor: pointer;
    transition: transform 0.2s ease;
}

.odds-value.clickable:hover {
    transform: translateY(-2px);
    background-color: var(--bg-secondary);
}

.auto-refresh-button {
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.auto-refresh-button:hover {
    transform: translateY(-2px);
}

.auto-refresh-toggle {
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.auto-refresh-toggle .form-check-input {
    background-color: var(--bg-tertiary);
    border-color: var(--accent-primary);
    margin-right: 8px;
}

.auto-refresh-toggle .form-check-input:checked {
    background-color: var(--accent-primary);
    border-color: var(--accent-primary);
}

.auto-refresh-toggle .form-check-label {
    color: var(--text-primary);
    font-weight: 500;
    margin-left: 4px;
    white-space: nowrap;
}

/* Update responsive styles */
@media (max-width: 768px) {
    .auto-refresh-controls {
        width: 100%;
        min-width: unset;
        justify-content: space-between;
    }
}

.odds-container td {
    font-weight: bold;
    text-align: center;
}

/* Style for no odds available */
.no-odds {
    color: #666 !important;
}

/* Hold percentage badges - more specific selectors */
.odds-table .hold-badge {
    margin-left: 8px;
    font-size: 0.8rem;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 500;
}

/* Increase specificity and force the colors */
.odds-table .hold-badge.excellent-hold {
    background-color: #28a745 !important;
    color: white !important;
    border: none !important;
}

.odds-table .hold-badge.good-hold {
    background-color: #5cb85c !important;
    color: white !important;
    border: none !important;
}

.odds-table .hold-badge.fair-hold {
    background-color: #ffc107 !important;
    color: black !important;
    border: none !important;
}

.odds-table .hold-badge.high-hold {
    background-color: #dc3545 !important;
    color: white !important;
    border: none !important;
}

.game-details {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 4px 0;
    background-color: transparent;
}

/* Team display styling */
.location-indicator {
    background-color: #2d2d2d;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    color: #a0a0a0;
    text-transform: uppercase;
}

/* Color coding for total odds */
.total-odds.positive-odds {
    color: #28a745 !important;
}

.total-odds.negative-odds {
    color: #dc3545 !important;
}

.odds-table-container {
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 1px;
    margin-top: 20px;
}

/* Table styling */
.odds-table {
    width: 100%;
    background-color: transparent !important;
    color: #ffffff !important;
    margin-bottom: 0 !important;
    table-layout: fixed;
}

.odds-table thead th {
    background-color: #242424 !important;
    color: #ffffff !important;
    border-bottom: 1px solid #333 !important;
    padding: 15px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
}

.odds-table tbody tr {
    background-color: #1a1a1a;
    border-bottom: 1px solid #2d2d2d !important;
    transition: background-color 0.2s ease;
}

.odds-table tbody tr:hover {
    background-color: #242424;
}

.odds-table td {
    padding: 15px !important;
    vertical-align: middle !important;
    border: none !important;
    background-color: #1a1a1a !important;
    color: #ffffff;
}

/* Matchup column styling */
.matchup-column {
    width: 30%;
}

.team-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.home-label, .away-label {
    color: #888;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #2d2d2d;
    padding: 2px 6px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 8px;
}

.game-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 8px 0;
}

.game-time {
    color: #888;
    font-size: 0.9rem;
}

/* Odds styling */
.moneyline-column, .spread-column, .total-column {
    width: 20%;
    text-align: center;
}

.odds {
    padding: 8px;
    margin: 4px 0;
    border-radius: 4px;
    background-color: #242424;
    cursor: pointer;
    transition: all 0.2s ease;
}

.odds:hover {
    background-color: #333;
    transform: translateY(-1px);
}

.positive-odds {
    color: #4caf50 !important;
}

.negative-odds {
    color: #f44336 !important;
}

/* Hold badge styling */
.hold-badge {
    font-size: 0.8rem;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 500;
}

.hold-badge.excellent-hold {
    background-color: #4caf50 !important;
    color: white !important;
}

.hold-badge.good-hold {
    background-color: #8bc34a !important;
    color: white !important;
}

.hold-badge.fair-hold {
    background-color: #ffc107 !important;
    color: black !important;
}

.hold-badge.high-hold {
    background-color: #f44336 !important;
    color: white !important;
}

/* Actions column styling */
.actions-column {
    width: 10%;
    text-align: center;
}

.props-button {
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.props-button:hover {
    background-color: #1565c0;
    transform: translateY(-1px);
}

.props-button:disabled {
    background-color: #333;
    color: #666;
    cursor: not-allowed;
    transform: none;
}

/* Responsive styles */
@media (max-width: 768px) {
    .odds-container {
        padding: 10px;
        margin: 10px;
    }

    .odds-table thead th,
    .odds-table td {
        padding: 10px !important;
        font-size: 0.85rem;
    }

    .game-time {
        font-size: 0.8rem;
    }

    .props-button {
        padding: 6px 12px;
        font-size: 0.8rem;
    }
}

/* Tooltip styling */
.tooltip-inner {
    background-color: #242424 !important;
    color: #ffffff !important;
    border: 1px solid #333 !important;
    padding: 8px 12px !important;
    font-size: 0.9rem !important;
    max-width: 200px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
}

/* Header styling for HOME/AWAY labels */
.odds-table tr th[scope="col"] {
    background-color: #000000 !important;
    color: #ffffff !important;
    text-transform: uppercase;
    font-weight: 600;
    padding: 12px 8px;
    border: none;
}

/* Team name container styling */
.team-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #121212 !important;
    padding: 8px;
    border-radius: 4px;
}

.team-info span {
    color: #ffffff !important;
}

/* HOME/AWAY labels */
.home-label, .away-label {
    color: #888;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #000000;
    padding: 2px 6px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 8px;
}

/* Game time styling */
.game-time {
    color: #888;
    font-size: 0.9rem;
    margin: 8px 0;
}

/* Hold badge container */
.game-details {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 4px 0;
    background-color: transparent;
}

/* Table cell backgrounds */
.odds-table td {
    background-color: #1a1a1a !important;
    color: #ffffff;
    border: none !important;
    padding: 12px 8px;
}

/* Team names specific styling */
.team-info span:not(.home-label):not(.away-label):not(.game-time) {
    color: #ffffff !important;
    font-weight: 500;
    font-size: 1rem;
    background-color: #121212;
    padding: 4px 8px;
    border-radius: 4px;
}

/* Odds styling */
.spread-column .odds {
    background-color: #242424;
    color: #ffffff !important;
    padding: 8px;
    margin: 4px 0;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.spread-column .odds > * {
    color: #ffffff !important;
}

.spread-column .odds .spread-number,
.spread-column .odds .spread-value {
    color: #ffffff !important;
}

/* Only apply color to the odds portion */
.spread-column .odds .spread-odds.positive-odds {
    color: #4caf50 !important;
}

.spread-column .odds .spread-odds.negative-odds {
    color: #f44336 !important;
}

/* Total column styling */
.total-column .odds {
    background-color: #242424;
    color: #ffffff !important;
    padding: 8px;
    margin: 4px 0;
    border-radius: 4px;
}

.total-column .odds > * {
    color: #ffffff !important;
}

.total-column .odds .total-number,
.total-column .odds .total-value {
    color: #ffffff !important;
}

/* Only apply color to the odds portion */
.total-column .odds .total-odds.positive-odds {
    color: #4caf50 !important;
}

.total-column .odds .total-odds.negative-odds {
    color: #f44336 !important;
}

/* Ensure proper spacing */
.odds-container > *:not(:last-child) {
    margin-bottom: 20px;
}

/* Fix table responsiveness */
@media (max-width: 768px) {
    .odds-table {
        min-width: 800px; /* Ensure table doesn't collapse on mobile */
    }
    
    .odds-table-container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.refresh-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.auto-refresh-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 10px;
}

.api-info {
    margin-left: 15px;
    padding: 4px 10px;
    border-radius: 4px;
    background-color: rgba(25, 135, 84, 0.1);
    border: 1px solid rgba(25, 135, 84, 0.2);
    color: #198754;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.api-info.warning {
    background-color: rgba(255, 193, 7, 0.1);
    border: 1px solid rgba(255, 193, 7, 0.2);
    color: #ffc107;
}

.api-info.danger {
    background-color: rgba(220, 53, 69, 0.1); 
    border: 1px solid rgba(220, 53, 69, 0.2);
    color: #dc3545;
}

.admin-controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 10px;
    margin-top: 5px;
    gap: 12px;
    border-radius: 4px;
    background-color: rgba(33, 37, 41, 0.05);
}

.odds-display-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.odds-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.date-picker {
    display: flex;
    align-items: center;
    gap: 15px;
}

.date-input {
    width: 180px;
}

/* API Endpoint Switcher */
.api-endpoint-switcher {
    margin-left: 10px;
    display: inline-block;
}

.api-endpoint-switcher .dropdown-toggle {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    padding: 0.25rem 0.5rem;
}

.api-endpoint-switcher .dropdown-menu {
    font-size: 0.9rem;
}

.admin-controls {
    display: flex;
    align-items: center;
    padding: 8px;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}