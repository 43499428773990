.promo-banner {
  width: 100%;
  padding: 10px 0;
  position: relative;
  z-index: 998; /* High but below modals */
  transition: all 0.3s ease;
}

.promo-banner-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.promo-banner-content {
  text-align: center;
  font-weight: 500;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.promo-banner a.promo-banner-content:hover {
  text-decoration: underline;
}

.promo-banner-arrow {
  margin-left: 8px;
  font-size: 1.1rem;
  transition: transform 0.2s ease;
}

.promo-banner a.promo-banner-content:hover .promo-banner-arrow {
  transform: translateX(3px);
}

.promo-banner-close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: inherit;
  font-size: 1.5rem;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.promo-banner-close:hover {
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .promo-banner-content {
    font-size: 0.85rem;
    padding-right: 30px; /* Space for close button */
  }
  
  .promo-banner-close {
    font-size: 1.3rem;
  }
} 