:root {
    --stat-excellent: #00ff00;   /* Bright green for excellent stats */
    --stat-good: #7cba3d;        /* Light green for good stats */
    --stat-average: #f0f0f0;     /* White/gray for average stats */
    --stat-below-average: #ff6b6b; /* Red for below average stats */
}

.stats-display {
    margin-top: 2rem;
}

.team-title {
    color: var(--text-primary);
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 0.5rem;
}

.player-card {
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 15px;
    color: white;
    width: 100%;
    max-width: 300px;
    min-width: 250px;
    position: relative;
}

.player-card .card-header {
    background-color: var(--bg-tertiary);
    border-bottom: 1px solid var(--border-color);
    padding: 0.75rem;
}

.player-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.player-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.player-headshot {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid var(--border-color);
}

.player-name {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.player-name-section {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1;
    min-width: 0;
}

.player-name-text {
    font-size: 0.85rem;
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    line-height: 1.1;
    max-width: 160px;
}

.position-badge {
    font-size: 0.7rem;
    padding: 1px 4px;
    background-color: #444;
    border-radius: 4px;
    white-space: nowrap;
}

.player-stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    padding: 15px;
    width: 100%;
}

.stat-row {
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: 8px;
    align-items: center;
    margin-bottom: 6px;
    font-size: 0.9rem;
}

.stat-item {
    position: relative;  /* For star badge positioning */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    background-color: var(--bg-tertiary);
}

.stat-item label {
    color: var(--text-secondary);
    font-size: 0.75rem;
    margin-bottom: 2px;
    text-align: center;
}

.stat-item span {
    font-size: 0.9rem;
    font-weight: 500;
}

/* Color coding for stats */
.stat-excellent {
    color: var(--stat-excellent) !important;
}

.stat-good {
    color: var(--stat-good) !important;
}

.stat-average {
    color: var(--stat-average) !important;
}

.stat-below-average {
    color: var(--stat-below-average) !important;
}

.games-played {
    text-align: center;
    margin-top: 12px;
    padding-top: 8px;
    border-top: 1px solid var(--border-color);
    font-size: 0.9rem;
    color: var(--text-secondary);
}

/* Add to existing CSS */
.median-close {
    color: #ffd700;  /* Yellow color for close medians */
    cursor: help;    /* Show pointer cursor for tooltip */
    font-weight: 500;
}

/* Style the tooltip */
.tooltip {
    z-index: 9999 !important;
    pointer-events: none;
}

.tooltip.show {
    opacity: 0.98;
    pointer-events: auto;
}

.tooltip-inner {
    background-color: #2a2a2a;
    color: white;
    padding: 8px 10px;
    max-width: 240px !important;
    width: auto;
    font-size: 0.7rem;
    line-height: 1.3;
    text-align: left;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: normal;
    hyphens: auto;
    border-radius: 4px;
}

.tooltip-inner div {
    margin: 3px 0;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: normal;
    hyphens: auto;
}

.tooltip-inner .stat-row {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: flex-start;
    margin: 3px 0;
    flex-wrap: wrap;
    width: 100%;
}

.tooltip-inner .stat-label {
    color: #aaa;
    flex: 0 0 75px;
    font-size: 0.7rem;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.tooltip-inner .stat-value {
    font-weight: 500;
    flex: 1;
    text-align: right;
    font-size: 0.7rem;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    min-width: 0;
}

.tooltip-inner .stat-header {
    font-weight: bold;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin-bottom: 4px;
    padding-bottom: 2px;
    font-size: 0.75rem;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.tooltip-inner .trend-indicator {
    color: #4CAF50;
    margin-left: 3px;
    font-size: 0.7rem;
    display: inline-block;
}

.tooltip-inner .consistency-star {
    color: #FFD700;
    font-size: 0.7rem;
    margin-right: 3px;
    display: inline-block;
}

/* Player card specific tooltip styling with higher specificity */
.tooltip.show .player-card-tooltip.tooltip-inner,
.tooltip.show .tooltip-inner.player-card-tooltip,
.player-card-tooltip.tooltip-inner,
.tooltip-inner.player-card-tooltip {
    padding: 5px 7px !important;
    max-width: 120px !important; 
    font-size: 0.6rem !important;
    line-height: 1.15 !important;
    background-color: rgba(25, 25, 25, 0.92) !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px !important;
    border: 1px solid rgba(255, 255, 255, 0.08) !important;
    transition: all 0.2s ease !important;
    text-align: left !important;
    opacity: 0.98 !important;
}

.player-card-tooltip .stat-header,
.tooltip-inner.player-card-tooltip .stat-header {
    font-size: 0.65rem !important;
    margin-bottom: 3px !important;
    padding-bottom: 2px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
    font-weight: 600 !important;
    text-align: center !important;
    letter-spacing: 0.02em !important;
}

.player-card-tooltip .stat-row,
.tooltip-inner.player-card-tooltip .stat-row {
    margin: 1px 0 !important;
    padding: 0 !important;
    gap: 5px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    min-height: 16px !important;
    width: 100% !important;
}

.player-card-tooltip .stat-label,
.tooltip-inner.player-card-tooltip .stat-label {
    flex: 0 0 25px !important;
    font-size: 0.6rem !important;
    color: rgba(255, 255, 255, 0.65) !important;
    font-weight: normal !important;
    white-space: nowrap !important;
}

.player-card-tooltip .stat-value,
.tooltip-inner.player-card-tooltip .stat-value {
    font-size: 0.6rem !important;
    font-weight: 500 !important;
    color: white !important;
    text-align: right !important;
}

.player-card-tooltip .consistency-star,
.tooltip-inner.player-card-tooltip .consistency-star {
    font-size: 0.55rem !important;
    margin-right: 1px !important;
    color: #FFD700 !important;
}

.player-card-tooltip .trend-indicator,
.tooltip-inner.player-card-tooltip .trend-indicator {
    font-size: 0.55rem !important;
    margin-left: 1px !important;
    color: #4CAF50 !important;
}

/* Add styles for modal z-index */
.modal {
    z-index: 1050 !important;
}

.modal-backdrop {
    z-index: 1040 !important;
}

.stat-with-hitrate {
    background-color: #2a2a2a;
    padding: 3px 6px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 0.85rem;
    width: 100%;
    position: relative;
    min-height: 40px;
}

.stat-value-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: 0;
    padding: 0 2px;
}

.stat-value-label {
    font-size: 0.65rem;
    color: #888;
    margin-bottom: 2px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.stat-values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2px;
}

.stat-value-group span {
    font-size: 0.85rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    min-width: 0;
    padding: 0 1px;
}

.star-badge {
    position: absolute;
    top: -4px;
    right: -4px;
    color: gold;
    font-size: 0.75rem;
    z-index: 1;
}

.stat-label {
    font-weight: bold;
    color: #888;
    min-width: 60px;
}

/* Add these color styles */
.stat-value[style*="color: yellow"] {
    color: #FFD700 !important; /* Using a more visible gold color */
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.5); /* Add shadow for better contrast */
}

.stat-value[style*="color: green"] {
    color: #00FF00 !important; /* Bright green */
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.5);
}

.stat-value[style*="color: red"] {
    color: #FF4444 !important; /* Bright red */
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.5);
}

.insights-button {
    background-color: transparent;
    border: 1px solid #4a90e2;
    color: #4a90e2;
    padding: 1px 6px;
    font-size: 0.7rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    height: 20px;
    min-width: 60px;
}

.insights-button:hover {
    background-color: #4a90e2;
    color: #1a1a1a;
}

.insights-button i {
    font-size: 0.8rem;
}

.insights-icon {
    font-size: 0.8rem;
}

.trend-up {
    background-color: rgba(34, 139, 34, 0.3);
    border: 1px solid #228b22;
}

.trend-neutral {
    background-color: rgba(255, 165, 0, 0.3);
    border: 1px solid #ffa500;
}

.team-section {
    margin-bottom: 30px;
    width: 100%;
}

.team-header {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #2a2a2a;
    border-radius: 4px;
}

.medL5 {
    font-weight: 600;
    padding: 0 1px;
}

/* Add responsive adjustments */
@media (max-width: 768px) {
    .player-stats-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    
    .player-card {
        max-width: 100%;
    }
}

/* Remove these override styles that are causing problems */
/* Override Bootstrap's default tooltip styles more aggressively */
.tooltip .tooltip-inner,
.tooltip-inner {
    background-color: #2a2a2a;
    color: white;
    padding: 8px 10px;
    max-width: 240px !important;
    width: auto;
    font-size: 0.7rem;
    line-height: 1.3;
    text-align: left;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: normal;
    hyphens: auto;
    border-radius: 4px;
}

/* Remove any border that might be applied */
.tooltip::before,
.tooltip::after,
.tooltip *::before,
.tooltip *::after {
    display: none;
}

/* Override any tooltip arrow styling */
.tooltip .arrow,
.tooltip-arrow {
    display: none;
}

/* Override enhanced tooltip styles if they exist */
.enhanced-tooltip .tooltip-inner,
.enhanced-tooltip.tooltip-inner {
    max-width: initial;
    font-size: initial;
    padding: initial;
}

/* For React-Bootstrap OverlayTrigger tooltips */
.bs-tooltip-auto[x-placement^=top] .arrow::before, 
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
    display: initial;
}

/* Remove compact tooltip styles that are no longer used */
.compact-tooltip.tooltip-inner,
.tooltip.compact-tooltip .tooltip-inner,
.compact-tooltip .tooltip-inner {
    max-width: 130px !important;
    font-size: 0.55rem !important;
    padding: 3px 5px;
    line-height: 1;
    border: none !important;
}

.compact-tooltip .stat-row {
    margin: 0;
    line-height: 1.1;
}

.compact-tooltip .stat-label {
    flex: 0 0 52px;
    font-size: 0.55rem;
    color: #bbb;
}

.compact-tooltip .stat-value {
    font-size: 0.55rem;
}

.compact-tooltip .stat-header {
    font-size: 0.58rem;
    margin-bottom: 1px;
    padding-bottom: 1px;
}

/* Override any other tooltip styles */
.tooltip-inner {
    border: none;
}

/* Direct targeting of Bootstrap tooltip components for player cards */
.tooltip.bs-tooltip-auto[x-placement^=top] .tooltip-inner.player-card-tooltip,
.tooltip.bs-tooltip-auto[x-placement^=bottom] .tooltip-inner.player-card-tooltip,
.tooltip.bs-tooltip-auto[x-placement^=left] .tooltip-inner.player-card-tooltip,
.tooltip.bs-tooltip-auto[x-placement^=right] .tooltip-inner.player-card-tooltip,
.tooltip.bs-tooltip-top .tooltip-inner.player-card-tooltip,
.tooltip.bs-tooltip-bottom .tooltip-inner.player-card-tooltip,
.tooltip.bs-tooltip-left .tooltip-inner.player-card-tooltip,
.tooltip.bs-tooltip-right .tooltip-inner.player-card-tooltip,
.tooltip .tooltip-inner.player-card-tooltip {
    padding: 5px 7px !important;
    max-width: 120px !important; 
    font-size: 0.6rem !important;
    line-height: 1.15 !important;
    background-color: rgba(25, 25, 25, 0.92) !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px !important;
    border: 1px solid rgba(255, 255, 255, 0.08) !important;
    text-align: left !important;
    opacity: 0.98 !important;
}

/* Ensure tooltip arrow is hidden */
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow,
.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow,
.tooltip.bs-tooltip-auto[x-placement^=left] .arrow,
.tooltip.bs-tooltip-auto[x-placement^=right] .arrow,
.tooltip.bs-tooltip-top .arrow,
.tooltip.bs-tooltip-bottom .arrow,
.tooltip.bs-tooltip-left .arrow,
.tooltip.bs-tooltip-right .arrow {
    display: none !important;
}

/* Highest specificity override for player card tooltips */
div#root body .tooltip.show .tooltip-inner.player-card-tooltip,
html body .tooltip.show .tooltip-inner.player-card-tooltip,
body .tooltip.show .tooltip-inner.player-card-tooltip,
.tooltip.show .tooltip-inner.player-card-tooltip,
body[class] .tooltip.show .tooltip-inner.player-card-tooltip,
#root .tooltip.show .tooltip-inner.player-card-tooltip,
.tooltip .tooltip-inner.player-card-tooltip,
[class].tooltip [class].tooltip-inner.player-card-tooltip {
    padding: 5px 7px !important;
    max-width: 120px !important; 
    font-size: 0.6rem !important;
    line-height: 1.15 !important;
    background-color: rgba(25, 25, 25, 0.92) !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px !important;
    border: 1px solid rgba(255, 255, 255, 0.08) !important;
    text-align: left !important;
    opacity: 0.98 !important;
}

/* Universal override for any tooltip with player-card-tooltip class */
*[class*="tooltip-inner"][class*="player-card-tooltip"] {
    padding: 5px 7px !important;
    max-width: 120px !important; 
    min-width: 100px !important;
    font-size: 0.6rem !important;
    line-height: 1.15 !important;
    background-color: rgba(25, 25, 25, 0.92) !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px !important;
    border: 1px solid rgba(255, 255, 255, 0.08) !important;
} 