.checkout-container {
    max-width: 800px;
    margin: 30px auto;
    padding: 30px;
    background-color: #1a1a1a;
    border-radius: 8px;
    color: #e0e0e0;
    position: relative;
}

.checkout-header {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
}

.checkout-header h2 {
    color: #29abe2;
    margin-bottom: 10px;
}

.checkout-header p {
    color: #aaa;
    font-size: 1.1rem;
}

.back-button {
    position: absolute;
    left: 0;
    top: 0;
    color: #aaa;
    border-color: #444;
    font-size: 0.9rem;
    padding: 6px 12px;
    transition: all 0.2s ease;
}

.back-button:hover {
    background-color: #333;
    color: #fff;
    border-color: #555;
}

.back-button i {
    font-size: 0.8rem;
}

/* Promo code section */
.promo-code-section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.promo-code-section h4 {
    margin-bottom: 15px;
    color: #e0e0e0;
}

.promo-code-section .input-group .form-control {
    background-color: #333;
    border-color: #444;
    color: #e0e0e0;
}

.promo-code-section .input-group .form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 171, 226, 0.25);
    border-color: #29abe2;
}

.promo-code-section .btn-primary {
    background-color: #29abe2;
    border-color: #29abe2;
}

.promo-code-section .btn-primary:hover {
    background-color: #1c8dbd;
    border-color: #1c8dbd;
}

.promo-code-section .btn-outline-secondary {
    color: #e0e0e0;
    border-color: #666;
}

.promo-code-section .btn-outline-secondary:hover {
    background-color: #444;
    color: #fff;
}

/* Price summary */
.price-summary {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #222;
    border-radius: 6px;
}

.price-summary h4 {
    margin-bottom: 15px;
    color: #e0e0e0;
}

.price-details {
    font-size: 1rem;
}

.price-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
}

.price-row.discount {
    color: #28a745;
    font-weight: 500;
}

.price-row.total {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-weight: bold;
    font-size: 1.1em;
}

.price-row.savings {
    padding-top: 4px;
    font-size: 0.85em;
    color: #28a745;
}

/* PayPal button wrapper */
.paypal-button-wrapper {
    margin: 30px 0;
    min-height: 150px;
}

/* Footer */
.checkout-footer {
    margin-top: 30px;
    text-align: center;
    color: #aaa;
    font-size: 0.9rem;
}

.checkout-footer a {
    color: #29abe2;
    text-decoration: none;
}

.checkout-footer a:hover {
    text-decoration: underline;
}

/* Trial notice */
.trial-notice {
    margin-top: 15px;
    padding: 15px;
    background-color: rgba(41, 171, 226, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: flex-start;
}

.trial-notice i {
    color: #29abe2;
    margin-right: 10px;
    font-size: 1.2rem;
    margin-top: 2px;
}

.trial-notice p {
    margin: 0;
    line-height: 1.4;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .checkout-container {
        padding: 20px;
        margin: 15px;
    }
    
    .price-details {
        font-size: 0.9rem;
    }
    
    .trial-notice {
        flex-direction: column;
    }
    
    .trial-notice i {
        margin-bottom: 10px;
    }
}

.checkout-container h2 {
    color: var(--text-primary);
    text-align: center;
    margin-bottom: 2rem;
}

.order-summary {
    background-color: var(--bg-secondary);
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.order-summary h3 {
    color: var(--text-primary);
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.order-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    color: var(--text-secondary);
}

.detail-row.total {
    border-top: 1px solid var(--bg-tertiary);
    margin-top: 0.5rem;
    padding-top: 1rem;
    color: var(--text-primary);
    font-weight: 600;
}

#paypal-button-container {
    margin: 2rem 0;
}

.secure-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--text-secondary);
    font-size: 0.9rem;
}

.secure-badge i {
    color: var(--accent-primary);
}

.paypal-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    color: var(--text-secondary);
}

.paypal-loading .spinner-border {
    width: 3rem;
    height: 3rem;
}

.alert {
    background-color: var(--bg-secondary);
    border: 1px solid var(--accent-primary);
    color: var(--text-primary);
}

.alert-danger {
    border-color: #dc3545;
}

.paypal-button-container {
    min-height: 150px;
    margin: 20px 0;
    position: relative;
}

/* Make sure the PayPal buttons are nicely positioned */
#paypal-container-FDM8FMHTZV7HU {
    max-width: 450px;
    margin: 0 auto;
    background: transparent;
}

/* Improve spinner appearance */
.paypal-button-wrapper .spinner-border {
    width: 2rem;
    height: 2rem;
    border-width: 0.2rem;
    color: #0070ba;
}

/* Ensure trial notice stands out */
.trial-notice {
    margin: 20px 0;
    border-left: 3px solid #00aaff;
}

/* Payment options */
.payment-options {
    margin: 30px 0;
}

.payment-options h4 {
    color: #fff;
    font-size: 1.3rem;
    margin-bottom: 25px;
    font-weight: 600;
    text-shadow: 0 1px 2px rgba(0,0,0,0.5);
}

.payment-option-card {
    background-color: #222;
    border-radius: 8px;
    padding: 25px;
    height: 100%;
    border: 1px solid #333;
    transition: all 0.3s ease;
}

.payment-option-card:hover {
    border-color: #29abe2;
    box-shadow: 0 0 15px rgba(41, 171, 226, 0.2);
}

.payment-option-card h5 {
    color: #29abe2;
    font-size: 1.2rem;
    margin-bottom: 15px;
}

.payment-option-card p {
    color: #bbb;
    font-size: 0.95rem;
    margin-bottom: 20px;
}

/* Traditional PayPal button */
.traditional-paypal-button {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.traditional-paypal-button form {
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    display: inline-block;
    cursor: pointer;
    transition: all 0.2s ease;
}

.traditional-paypal-button form:hover {
    box-shadow: 0 0 10px rgba(41, 171, 226, 0.3);
    transform: translateY(-2px);
}

.paypal-subscribe-button {
    cursor: pointer !important;
    transition: opacity 0.2s ease;
}

.paypal-subscribe-button:hover {
    opacity: 0.9;
}

/* Add notice about manual verification after PayPal payment */
.payment-verification-notice {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 10px;
    margin-top: 15px;
    border-left: 3px solid #29abe2;
    font-size: 0.85rem;
}

/* Cancellation message styling */
.alert-info {
    background-color: rgba(23, 162, 184, 0.1);
    border-color: rgba(23, 162, 184, 0.3);
    color: #9ddae0;
    border-radius: 6px;
    border-left: 3px solid #17a2b8;
    animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
    from { opacity: 1; transform: translateY(0); }
    to { opacity: 0; transform: translateY(-10px); }
}

.alert-info i {
    color: #17a2b8;
    margin-right: 5px;
}

/* Payment method labels */
.payment-option-card .text-muted {
    color: #aaa !important;
}

/* Responsive adjustments for payment options */
@media (max-width: 768px) {
    .payment-tabs .row {
        display: flex;
        flex-direction: column;
    }
    
    .payment-option-card {
        margin-bottom: 20px;
    }
}

.product-details {
    padding: 12px;
    background-color: #1a1a1a;
    border-radius: 6px;
    margin-bottom: 20px;
}

.product-details .d-flex {
    font-size: 0.9rem;
    color: #ddd;
}

.product-details .ms-3 {
    font-weight: bold;
    color: #fff;
}

.venmo-button {
    margin-top: 8px;
}

.payment-in-progress {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    animation: fadeIn 0.3s ease-in-out;
}

.payment-in-progress .fa-spin {
    color: #29abe2;
}

/* Make sure all links in alerts have proper styling */
.alert a {
    color: #fff;
    text-decoration: underline;
}

.alert a:hover {
    color: #fff;
    text-decoration: none;
}

/* Ensure the modal closing doesn't cause errors */
.modal-backdrop {
    z-index: 1040;
}

/* Ensure pointer cursors on all interactive elements */
button, input[type="image"], .btn, a {
    cursor: pointer;
}

.error-boundary-fallback {
    padding: 15px;
    margin: 20px 0;
    border-radius: 8px;
    background-color: rgba(220, 53, 69, 0.1);
}

.error-boundary-fallback .alert {
    margin-bottom: 0;
    background-color: transparent;
    border: none;
    text-align: center;
}

.error-boundary-fallback h5 {
    color: #dc3545;
    margin-bottom: 10px;
}

.error-boundary-fallback .btn-outline-light {
    color: #fff;
    border-color: #dc3545;
    background-color: rgba(220, 53, 69, 0.2);
}

.error-boundary-fallback .btn-outline-light:hover {
    background-color: #dc3545;
    color: #fff;
}

/* Animated success status */
.btn-outline-success {
    animation: pulse-success 2s infinite;
}

@keyframes pulse-success {
    0% {
        box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
    }
    70% {
        box-shadow: 0 0 0 6px rgba(40, 167, 69, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
    }
} 