.app-footer {
    background-color: var(--bg-secondary);
    padding: 2rem 0 1.5rem;
    margin-top: 2rem;
    border-top: 1px solid var(--border-color);
    position: relative;
    z-index: 10;
    clear: both;
}

.footer-content {
    margin-bottom: 1.5rem;
}

.footer-section {
    margin-bottom: 1.5rem;
}

.footer-section h5 {
    color: var(--text-primary);
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-section ul li {
    margin-bottom: 0.5rem;
}

.footer-section ul li a {
    color: var(--text-secondary);
    text-decoration: none;
    transition: color 0.2s ease;
    font-size: 0.9rem;
}

.footer-section ul li a:hover {
    color: var(--accent-primary);
}

/* Special style for support navigation links */
.footer-section ul li a.support-link {
    position: relative;
    padding-left: 0px;
    transition: padding-left 0.2s ease, color 0.2s ease;
}

.footer-section ul li a.support-link:hover {
    padding-left: 5px;
    color: var(--accent-primary);
}

.footer-section ul li a.support-link:active {
    color: #64B5F6;
    transform: scale(0.98);
}

.footer-bottom {
    padding-top: 1.5rem;
    border-top: 1px solid var(--border-color);
    text-align: center;
}

.footer-bottom p {
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin: 0;
}

@media (max-width: 768px) {
    .footer-section {
        text-align: center;
    }

    .footer-content {
        margin-bottom: 1rem;
    }
} 