/* Import modern fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
    /* Dark theme colors */
    --bg-primary: #121212;
    --bg-secondary: #1e1e1e;
    --bg-tertiary: #2d2d2d;
    --text-primary: #ffffff;
    --text-secondary: #b3b3b3;
    --accent-primary: #4285f4;
    --border-color: rgba(255, 255, 255, 0.1);
    /* Keep other variables */
    --gradient-primary: linear-gradient(135deg, #1a73e8 0%, #4285f4 100%);
    --tab-active-border: #4285f4;
    --tab-hover-bg: rgba(66, 133, 244, 0.1);
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    min-height: 100vh;
}

.container {
    background-color: transparent;  /* Remove white background */
    min-height: 100vh;
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex: 1;
}

/* Modern title styling */
.project-title {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--text-primary);
    letter-spacing: -0.5px;
}

.title-highlight {
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-right: 0.5rem;
}

/* Modern tabs styling */
.custom-tabs {
    border-bottom: 1px solid var(--bg-tertiary);
    margin-bottom: 2rem !important;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.custom-tabs .nav-item {
    margin: 0;
}

.custom-tabs .nav-link {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: var(--text-secondary);
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 12px;
    transition: all 0.3s ease;
    position: relative;
    background: transparent;
}

.custom-tabs .nav-link:hover {
    color: var(--text-primary);
    background-color: var(--tab-hover-bg);
}

.custom-tabs .nav-link.active {
    color: var(--accent-primary);
    background-color: var(--bg-secondary);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-tabs .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    width: 40%;
    height: 3px;
    background: var(--tab-active-border);
    border-radius: 3px 3px 0 0;
}

/* Coming soon section */
.coming-soon {
    text-align: center;
    padding: 4rem 2rem;
    background-color: var(--bg-secondary);
    border-radius: 12px;
    margin-top: 2rem;
}

.coming-soon span {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-secondary);
    opacity: 0.7;
}

/* Loading spinner */
.loading-spinner {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
    font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .project-title {
        font-size: 2rem;
    }

    .custom-tabs .nav-link {
        padding: 0.75rem 1rem;
        font-size: 0.9rem;
    }

    .custom-tabs {
        gap: 0.5rem;
    }
}

.App {
  text-align: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #f8f9fa;
}

.btn-block {
  width: 100%;
}

.nav-tabs {
  border-bottom: 1px solid var(--bg-tertiary);
}

.nav-tabs .nav-link {
  color: var(--text-secondary);
  border: none;
  padding: 12px 20px;
  margin-right: 4px;
  border-radius: 8px 8px 0 0;
  transition: all 0.2s ease;
}

.nav-tabs .nav-link:hover {
  background-color: var(--bg-tertiary);
  border-color: transparent;
  color: var(--text-primary);
}

.nav-tabs .nav-link.active {
  background-color: var(--bg-secondary);
  border-color: transparent;
  color: var(--accent-primary);
}

.tab-content {
  background-color: transparent;  /* Remove white background */
  padding: 20px 0;
}

h1 {
  color: var(--text-primary);
  font-weight: 700;
  margin-bottom: 2rem;
}

/* Update the app header styles */
.app-header {
    text-align: center;
    margin-bottom: 2rem;
    padding: 1rem;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    height: 40px; /* Fixed height to ensure alignment */
}

.title-with-equation {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.app-header h1 {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1;
    height: 100%;
}

.app-header h1 span.clutch {
    color: var(--accent-primary);
}

.app-header h1 span.rest {
    color: var(--text-primary);
}

/* Header equation styling */
.header-equation {
    font-family: 'Courier New', monospace;
    font-size: 0.65rem;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    background: rgba(0, 0, 0, 0.3);
    padding: 0.15rem 0.3rem;
    border-radius: 4px;
    margin-bottom: 0.2rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.header-equation:hover {
    transform: scale(1.1);
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.header-equation:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.5);
}

.mini-eq-term {
    color: #4CAF50;
    font-weight: 600;
    padding: 0 0.1rem;
}

.mini-eq-operator {
    color: #ffffff;
    font-weight: bold;
    padding: 0 0.1rem;
}

.mini-eq-result {
    color: #64B5F6;
    font-weight: 600;
    padding: 0 0.1rem;
}

/* Remove unused tooltip styles */
.equation-tooltip,
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
    display: none;
}

/* Re-add loading styles that were accidentally removed */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    font-size: 1.2rem;
    color: var(--text-primary);
}

.loading-progress {
    height: 16px;
    border-radius: 12px;
    margin-bottom: 1rem;
    background-color: var(--bg-tertiary);
    width: 100%;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loading-progress .progress-bar {
    background-color: var(--accent-primary);
    border-radius: 12px;
    transition: width 0.3s ease-in-out;
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loading-text {
    text-align: center;
    color: var(--text-secondary);
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.5rem;
}

/* Custom progress bar animation */
@keyframes progress-bar-stripes {
    from { background-position: 1rem 0; }
    to { background-position: 0 0; }
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

/* Add pulse animation for loading state */
@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }
}

.loading-container .loading-text {
    animation: pulse 1.5s ease-in-out infinite;
}

/* Add these styles */
#root {
    background-color: var(--bg-primary);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .header-content {
        flex-direction: column;
        height: auto;
        gap: 1rem;
    }
    
    .app-header h1 {
        font-size: 1.25rem;
    }
}

/* Add these styles */
.checkout-active .nav-link:not([data-rb-event-key="plans"]) {
    opacity: 0.5;
    pointer-events: none;
}

.checkout-active .nav-link[data-rb-event-key="plans"] {
    cursor: default;
}

.custom-progress {
    height: 25px;
    background-color: #e9ecef;
    border-radius: 8px;
    margin: 20px 0;
}

.custom-progress .progress-bar {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    font-size: 14px;
    transition: width 0.3s ease-in-out;
}

.progress-container {
    max-width: 600px;
    margin: 0 auto;
}

.player-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    margin-top: 15px;
}

.player-card {
    background: #f8f9fa;
    border-radius: 6px;
    padding: 15px;
    transition: transform 0.2s;
}

.player-card:hover {
    transform: translateY(-2px);
}

/* Add this to ensure the Research tab has a dark background */
.research-container {
  background-color: #121212;
  color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1rem;
}

/* Make form controls in dark theme */
.research-container .form-control,
.research-container .form-select {
  background-color: #333;
  color: #fff;
  border-color: #555;
}

.research-container .form-control:focus,
.research-container .form-select:focus {
  background-color: #444;
  color: #fff;
  border-color: #777;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.research-container .form-label {
  color: #fff;
}

/* Dark themed card */
.research-container .card {
  background-color: #222;
  color: #fff;
  border-color: #444;
}

/* Dark themed tabs */
.research-container .nav-tabs {
  border-bottom-color: #444;
}

.research-container .nav-tabs .nav-link {
  color: #aaa;
}

.research-container .nav-tabs .nav-link.active {
  background-color: #333;
  color: #fff;
  border-color: #444 #444 #333;
}

/* Fix for list group items */
.research-container .list-group-item {
  background-color: #333;
  color: #fff;
  border-color: #444;
}

/* Fix for text muted */
.research-container .text-muted {
  color: #aaa !important;
}

/* Player stats boxes */
.stat-box {
  background-color: #2a2a2a !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Add these media queries for responsiveness */
@media (max-width: 768px) {
  .stat-value {
    font-size: 1.8rem !important;
  }
  
  .stat-subtext {
    font-size: 0.7rem !important;
  }
}

/* Nav tab styling */
.nav-link {
    color: #6c757d;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease;
}

.nav-link:hover {
    color: #007bff;
}

.nav-link.active {
    color: #007bff !important;
    border-bottom: 2px solid #007bff;
    font-weight: 500;
}

/* Ensure PaidFeature text in active tab is also highlighted */
.nav-link.active .paid-feature {
    color: #007bff !important;
}

/* Global dark theme styles */
body.dark-theme {
  background-color: #121212;
  color: #e0e0e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-layout {
  min-height: 100vh;
  background-color: #121212;
  color: white;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  min-height: 0; /* Remove minimum height */
  flex: 1; /* Let it flex to fill available space */
}

.tab-content {
  min-height: 0; /* Remove minimum height */
  background-color: #121212;
}

/* Ensure all tab panes have a consistent layout */
.tab-pane {
  min-height: 0; /* Remove minimum height */
  display: flex;
  flex-direction: column;
}

/* Fix footer positioning */
.app-footer {
  margin-top: auto; /* Push footer to bottom */
  margin-bottom: 0;
  padding-bottom: 0;
}
