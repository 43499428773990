/* Floating Feedback Button */
.feedback-button {
    position: fixed;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    background-color: #007bff;
    color: white;
    border-radius: 50px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    z-index: 999; /* Lower z-index to not interfere with captcha */
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 0.9rem;
}

.feedback-button:hover {
    background-color: #0069d9;
    transform: translateY(-50%) translateX(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
}

.feedback-button svg {
    font-size: 1.1rem;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .feedback-button {
        bottom: 20px;
        right: 20px;
        padding: 10px 16px;
        font-size: 0.8rem;
    }
    
    .feedback-button span {
        display: none; /* Hide text on mobile, show only icon */
    }
    
    .feedback-button {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
    }
    
    .feedback-button svg {
        margin: 0;
    }
}

/* Modal Styling */
.feedback-modal .modal-content {
    border-radius: 10px;
    border: none;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    background-color: var(--bg-primary, #fff);
    color: var(--text-primary, #212529);
}

.feedback-modal .modal-header {
    border-bottom: 1px solid var(--border-color, rgba(0, 0, 0, 0.1));
    padding: 1.25rem 1.5rem;
}

.feedback-modal .modal-title {
    font-weight: 600;
    font-size: 1.25rem;
}

.feedback-modal .modal-body {
    padding: 1.5rem;
}

.feedback-modal .form-control {
    background-color: var(--bg-secondary, #f8f9fa);
    border: 1px solid var(--border-color, rgba(0, 0, 0, 0.1));
    color: var(--text-primary, #212529);
}

.feedback-modal .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    border-color: #86b7fe;
}

.feedback-modal .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    padding: 0.5rem 1.5rem;
    font-weight: 500;
}

.feedback-modal .btn-primary:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

/* Success Animation */
.success-checkmark {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    position: relative;
}

.success-checkmark .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;
}

.success-checkmark .check-icon::before {
    top: 3px;
    left: -2px;
    width: 30px;
    transform-origin: 100% 50%;
    border-radius: 100px 0 0 100px;
}

.success-checkmark .check-icon::after {
    top: 0;
    left: 30px;
    width: 60px;
    transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    animation: rotate-circle 4.25s ease-in;
}

.success-checkmark .check-icon::before, .success-checkmark .check-icon::after {
    content: '';
    height: 100px;
    position: absolute;
    background: var(--bg-primary, #fff);
    transform: rotate(-45deg);
}

.success-checkmark .check-icon .icon-line {
    height: 5px;
    background-color: #4CAF50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
    top: 46px;
    left: 14px;
    width: 25px;
    transform: rotate(45deg);
    animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
    top: 38px;
    right: 8px;
    width: 47px;
    transform: rotate(-45deg);
    animation: icon-line-long 0.75s;
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 46px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .feedback-modal .modal-content {
        background-color: #2a2a2a;
        color: #e0e0e0;
    }
    
    .feedback-modal .modal-header {
        border-bottom-color: #3a3a3a;
    }
    
    .feedback-modal .form-control {
        background-color: #333;
        border-color: #444;
        color: #e0e0e0;
    }
    
    .feedback-modal .form-control:focus {
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
    }
    
    .success-checkmark .check-icon::before, 
    .success-checkmark .check-icon::after {
        background: #2a2a2a;
    }
} 