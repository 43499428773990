.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.dashboard-container h2 {
    color: #ffffff;
    border-bottom: 2px solid #29abe2;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.dashboard-container h3 {
    font-size: 1.4rem;
    margin-bottom: 15px;
    color: #ffffff;
}

.dashboard-container .card {
    background-color: #2c2c2c;
    border: 1px solid #3c3c3c;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dashboard-container .card-header {
    background-color: #1e1e1e;
    border-bottom: 1px solid #3c3c3c;
    padding: 15px 20px;
}

.dashboard-container .card-body {
    padding: 20px;
}

.subscription-details p {
    margin-bottom: 12px;
    font-size: 1rem;
    color: #e0e0e0;
}

.subscription-details strong {
    color: #ffffff;
    font-weight: 600;
    display: inline-block;
    width: 120px;
}

.empty-payment-container {
    text-align: center;
    padding: 20px 0;
}

.empty-payment-container p {
    margin-bottom: 20px;
}

.empty-table {
    border: 1px solid #444;
}

.empty-table th {
    background-color: #1e1e1e;
    color: #fff;
}

.empty-table td {
    color: #888;
}

table {
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
}

table th {
    background-color: #1e1e1e;
    color: #ffffff;
    font-weight: 600;
    text-align: left;
    padding: 12px 15px;
    border-bottom: 2px solid #444;
}

table td {
    padding: 12px 15px;
    border-bottom: 1px solid #3c3c3c;
    color: #e0e0e0;
}

table tr:hover {
    background-color: rgba(41, 171, 226, 0.1);
}

.text-muted {
    color: #9e9e9e !important;
}

/* Status badges */
.badge {
    font-size: 0.75rem;
    padding: 5px 8px;
    border-radius: 4px;
    font-weight: 600;
    display: inline-block;
}

/* Media queries for responsive design */
@media screen and (max-width: 768px) {
    .dashboard-container {
        padding: 10px;
    }
    
    table th, 
    table td {
        padding: 8px;
    }
    
    .subscription-details strong {
        width: 100px;
    }
}

.dashboard-container {
    padding: 2rem 0;
}

.subscription-card,
.payment-history-card {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    height: 100%;
}

.subscription-card .card-title,
.payment-history-card .card-title {
    color: var(--text-primary);
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
}

.subscription-card .card-text {
    color: var(--text-secondary);
    margin-bottom: 1rem;
}

.table {
    color: var(--text-secondary);
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.table thead th {
    border-bottom: 2px solid var(--border-color);
    color: var(--text-primary);
}

.table td, .table th {
    border-color: var(--border-color);
    padding: 1rem;
}

.badge {
    font-size: 0.85rem;
    padding: 0.5em 0.85em;
}

.dashboard-loading,
.dashboard-error {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
}

.dashboard-error {
    color: #dc3545;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .subscription-card {
        margin-bottom: 1.5rem;
    }
}

/* Add these styles */
.dashboard-content {
    padding: 2rem 0;
}

.dashboard-loading {
    text-align: center;
    padding: 3rem;
    color: var(--text-secondary);
}

.dashboard-error {
    text-align: center;
    padding: 2rem;
    color: #dc3545;
    background-color: rgba(220, 53, 69, 0.1);
    border-radius: 8px;
    margin: 1rem 0;
}

/* Style the tabs consistently */
.custom-tabs {
    margin-bottom: 2rem;
}

.custom-tabs .nav-link {
    color: var(--text-secondary);
    cursor: pointer;
}

.custom-tabs .nav-link:hover {
    color: var(--text-primary);
}

.custom-tabs .nav-link.active {
    color: var(--accent-primary);
    border-color: var(--accent-primary);
}

/* Empty payment history styles */
.empty-payment-container {
    border-radius: 8px;
    padding: 0.5rem 0;
    margin-bottom: 1.5rem;
}

.empty-payment-container .text-muted {
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.empty-table {
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.empty-table tbody td {
    padding: 2rem;
    text-align: center;
    color: var(--text-secondary);
    font-style: italic;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    color: var(--text-secondary);
}

/* Create hover effects for the payment table rows */
.table tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.05);
    transition: background-color 0.2s ease;
} 