.probability-modal .modal-content {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    border-radius: 12px;
}

.probability-modal .modal-header {
    border-bottom: 1px solid var(--bg-tertiary);
    padding: 1rem 1.5rem;
}

.probability-modal .modal-header .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
}

.probability-container {
    padding: 1rem;
}

.team-probability {
    background-color: var(--bg-secondary);
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.team-probability h5 {
    color: var(--text-primary);
    margin-bottom: 1rem;
    font-weight: 600;
}

.prob-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.prob-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: var(--bg-tertiary);
    border-radius: 6px;
}

.prob-row span:first-child {
    color: var(--text-secondary);
    font-size: 0.9rem;
}

.prob-row span:last-child {
    font-weight: 500;
    color: var(--text-primary);
}

.overround-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: var(--bg-tertiary);
    border-radius: 8px;
    margin-top: 1rem;
}

.overround-info span:first-child {
    color: var(--text-secondary);
}

.overround-info span:last-child {
    font-weight: 500;
    color: var(--text-primary);
} 