.plans-container {
    padding: 2rem 1rem;
}

.plans-container h2 {
    color: var(--text-primary);
    font-weight: 600;
}

.plans-container > p {
    color: var(--text-secondary);
    font-size: 1.1rem;
}

.plan-card {
    background-color: var(--bg-secondary);
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin-bottom: 2rem;
}

.plan-card:hover {
    transform: translateY(-5px);
}

.plan-card .card-header {
    background-color: var(--bg-tertiary);
    border-bottom: none;
    border-radius: 12px 12px 0 0;
    padding: 1.5rem;
}

.plan-card .card-header h3 {
    color: var(--text-primary);
    margin: 0;
    font-weight: 600;
}

.price-section {
    text-align: center;
    margin: 1.5rem 0;
    color: var(--text-primary);
}

.price {
    font-size: 2.5rem;
    font-weight: 700;
}

.period {
    font-size: 1.1rem;
    color: var(--text-secondary);
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 2rem 0;
}

.features-list li {
    color: var(--text-secondary);
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.features-list li i {
    color: var(--accent-primary);
    margin-right: 0.75rem;
}

.select-plan-btn {
    width: 100%;
    padding: 1rem;
    font-weight: 500;
    background-color: var(--accent-primary);
    border: none;
    transition: all 0.2s ease;
}

.select-plan-btn:hover {
    transform: translateY(-2px);
    filter: brightness(1.1);
}

.money-back-guarantee {
    text-align: center;
    margin-top: 3rem;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.money-back-guarantee i {
    color: var(--accent-primary);
    font-size: 1.2rem;
}

.login-options {
    text-align: center;
    margin-top: 1.5rem;
    padding: 1rem;
}

.google-login-btn {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem;
    border-radius: 8px;
    transition: all 0.2s ease;
    background-color: var(--bg-secondary);
    border: 1px solid var(--bg-tertiary);
    color: var(--text-primary);
}

.google-login-btn:hover {
    background-color: var(--bg-tertiary);
    transform: translateY(-2px);
}

.google-login-btn i {
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .plans-container {
        padding: 1rem;
    }

    .price {
        font-size: 2rem;
    }
} 