.lay-builder {
    padding: 20px;
    width: 100%;
    color: var(--text-primary);
}

.lay-builder-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
}

.lay-builder-description {
    color: var(--text-secondary);
    font-size: 0.9rem;
    max-width: 800px;
    margin-bottom: 1rem;
}

/* Card Styles */
.available-legs-card, .selected-legs-card {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow: hidden;
}

.card-header {
    background-color: var(--bg-tertiary);
    border-bottom: 1px solid var(--border-color);
    padding: 20px;
}

.card-header h5 {
    color: var(--text-primary);
    font-weight: 600;
}

.filters-container {
    padding-top: 15px;
}

.info-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);
    margin-left: 8px;
    font-size: 0.9rem;
    cursor: help;
}

/* Legs List */
.legs-list-container {
    height: calc(100vh - 240px);
    overflow-y: auto;
    padding: 15px;
}

.legs-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.leg-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 10px;
    margin-bottom: 8px;
    background-color: var(--bg-primary);
    border-radius: 8px;
    border: 1px solid var(--border-color);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.leg-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-color: var(--accent-color, #4CAF50);
}

.leg-details {
    display: flex;
    flex: 1;
    gap: 15px;
    align-items: flex-start;
}

.leg-type-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 60px;
    padding-top: 3px;
}

.leg-type-badge .badge {
    width: 100%;
    text-align: center;
}

.leg-type-badge .player-name {
    max-width: 80px;
    text-align: center;
    line-height: 1.2;
    font-size: 0.85rem;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.leg-main-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    padding-left: 10px;
}

.player-prop-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.player-name, .selected-player-name {
    font-weight: 600;
    color: var(--text-primary);
    font-size: 1rem;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    line-height: 1.2;
}

.prop-type {
    font-weight: 500;
    padding: 0 3px;
}

.team-name, .selected-team-name {
    color: var(--text-secondary);
    font-size: 0.85rem;
    margin-bottom: 2px;
    line-height: 1.2;
}

.prop-details {
    display: flex;
    gap: 8px;
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin-bottom: 4px;
    flex-wrap: wrap;
    align-items: center;
}

.prop-line {
    font-weight: 600;
}

.moneyline-details, .spread-details, .total-details {
    font-weight: 500;
    color: var(--text-primary);
    font-size: 1.1rem;
}

.leg-metrics {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    margin-top: 10px;
}

.ev-badge {
    font-size: 0.75rem;
    font-weight: 600;
    white-space: nowrap;
}

.ev-badge.positive {
    background-color: var(--success);
}

.ev-badge.negative {
    background-color: var(--danger);
}

.implied-prob, .hold-pct {
    font-size: 0.7rem;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
}

.leg-actions {
    display: flex;
    align-items: center;
    margin-left: 8px;
    min-width: 130px;
    justify-content: flex-end;
    position: relative;
    z-index: 3;
}

.odds-display {
    font-family: monospace;
    font-weight: 600;
    font-size: 0.9rem;
    color: var(--accent-color, #4CAF50);
    margin-right: 10px;
    white-space: nowrap;
    min-width: 50px;
    text-align: right;
    overflow: hidden;
}

.add-leg-btn {
    padding: 2px 6px;
    font-size: 0.75rem;
    white-space: nowrap;
    min-width: 60px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove-leg-btn {
    padding: 0.15rem 0.3rem;
    font-size: 0.75rem;
    margin-left: 8px;
    display: flex;
    align-items: center;
}

/* Empty State */
.no-legs-message, .empty-parlay-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: var(--text-secondary);
    text-align: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

/* Selected Legs */
.selected-legs-body {
    height: calc(100vh - 240px);
    overflow-y: auto;
    padding: 15px;
}

.selected-legs-list {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 0.75rem;
}

.selected-leg-item {
    padding: 8px 10px;
    margin-bottom: 8px;
    border-radius: 4px;
    background-color: rgba(32, 32, 32, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.selected-leg-details {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex: 1;
}

.selected-leg-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 60px;
    padding-top: 3px;
}

.selected-leg-type .badge {
    width: 100%;
    text-align: center;
    font-size: 0.7rem;
    padding: 0.2rem 0.3rem;
}

.selected-leg-type .selected-player-name {
    max-width: 80px;
    text-align: center;
    line-height: 1.2;
    font-size: 0.75rem;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selected-prop-details,
.selected-spread-details,
.selected-matchup-details {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.95);
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
    font-weight: 500;
}

.selected-leg-odds {
    font-family: monospace;
    font-weight: 600;
    font-size: 0.85rem;
    color: #f6c23e;
    margin-top: 4px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
}

.selected-total-details,
.selected-moneyline-details {
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 1.2;
}

/* More Compact Parlay Summary */
.parlay-summary {
    margin-top: 0.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0.5rem 0.25rem;
    font-size: 0.8rem;
}

.compact-stats {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
}

.compact-actions {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.separator {
    color: rgba(255, 255, 255, 0.3);
    margin: 0 2px;
}

.stat-label {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-right: 3px;
}

.stat-value {
    font-weight: 700;
    font-size: 0.65rem;
    margin-right: 4px;
    color: var(--text-primary, #e9ecef);
    white-space: nowrap;
}

/* Extra contrast for important stat values */
.text-success {
    color: #54ff54 !important;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.text-danger {
    color: #ff5454 !important;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

/* Smart parlay strength badges */
.parlay-strength {
    margin-bottom: 0.5rem;
}

.strength-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0.35rem 0.6rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 600;
    cursor: help;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.badge-icon {
    font-size: 0.85rem;
}

.badge-icon.optimal {
    color: #0088ff;
}

.badge-icon.strong {
    color: #28a745;
}

.badge-icon.weak {
    color: #ffc107;
}

.strength-badge.optimal {
    background-color: rgba(0, 136, 255, 0.15);
    border: 1px solid rgba(0, 136, 255, 0.3);
}

.strength-badge.strong {
    background-color: rgba(40, 167, 69, 0.15);
    border: 1px solid rgba(40, 167, 69, 0.3);
}

.strength-badge.weak {
    background-color: rgba(255, 193, 7, 0.15);
    border: 1px solid rgba(255, 193, 7, 0.3);
}

.strength-badge.neutral {
    background-color: rgba(108, 117, 125, 0.15);
    border: 1px solid rgba(108, 117, 125, 0.3);
    color: #6c757d;
}

.clear-btn {
    padding: 0.125rem 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
}

.correlation-details {
    font-size: 0.8rem;
    opacity: 0.8;
}

.matchup-name, .selected-matchup-name {
    color: var(--text-tertiary, #6c757d);
    font-size: 0.75rem;
    font-style: italic;
    margin-top: 2px;
    line-height: 1.2;
}

.prop-details, .selected-prop-details {
    display: flex;
    gap: 8px;
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin-bottom: 4px;
    flex-wrap: wrap;
    align-items: center;
}

.selected-leg-main-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    padding-left: 8px;
    color: rgba(255, 255, 255, 0.95);
}

/* Recommended leg styles */
.recommended-leg {
    border-left: 3px solid gold;
    position: relative;
    background-color: rgba(255, 223, 0, 0.03);
}

.recommended-leg:hover {
    border-color: gold;
    box-shadow: 0 6px 16px rgba(255, 215, 0, 0.2);
}

.recommended-badge {
    position: absolute;
    top: -8px;
    right: 95px;
    background-color: #ffc107;
    color: #212529;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.7rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.recommended-icon {
    color: #212529;
    font-size: 0.7rem;
}

/* Adjust the leg-details to account for the badge */
.recommended-leg .leg-details {
    margin-top: 5px;
}

/* Responsive Design */
@media (max-width: 992px) {
    .legs-list-container, .selected-legs-body {
        height: auto;
        max-height: 600px;
    }
    
    .leg-details {
        flex-direction: column;
        gap: 10px;
    }
    
    .leg-metrics {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        min-width: auto;
    }
    
    .leg-actions {
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        min-width: auto;
    }
}

@media (max-width: 768px) {
    .lay-builder-title {
        font-size: 1.5rem;
    }
    
    .leg-item, .selected-leg-item {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .leg-actions, .parlay-actions {
        width: 100%;
        margin-top: 15px;
        justify-content: flex-start;
    }
    
    .selected-leg-details {
        width: 100%;
    }
    
    .selected-leg-odds {
        margin-right: 0;
        margin-top: 10px;
    }
    
    .remove-leg-btn {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

/* Tooltips */
.tooltip {
    font-size: 0.9rem;
    max-width: 250px;
}

.tooltip .tooltip-inner {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 8px 12px;
    border-radius: 6px;
}

/* Custom Form Controls */
.form-control-sm, .form-select-sm {
    font-size: 0.9rem;
    padding: 0.25rem 0.5rem;
}

.form-control, .form-select {
    background-color: var(--bg-primary);
    border-color: var(--border-color);
    color: var(--text-primary);
}

.form-control:focus, .form-select:focus {
    background-color: var(--bg-primary);
    border-color: var(--accent-color, #4CAF50);
    color: var(--text-primary);
    box-shadow: 0 0 0 0.25rem rgba(76, 175, 80, 0.25);
}

.form-label {
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin-bottom: 5px;
    font-weight: 500;
}

/* Badge Styles */
.badge {
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 0.35em 0.65em;
}

/* Scrollbars */
.card-body::-webkit-scrollbar {
    width: 8px;
}

.card-body::-webkit-scrollbar-track {
    background: var(--bg-secondary);
}

.card-body::-webkit-scrollbar-thumb {
    background-color: var(--border-color);
    border-radius: 4px;
}

.card-body::-webkit-scrollbar-thumb:hover {
    background-color: var(--accent-primary);
}

/* Pagination */
.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.pagination {
    margin-bottom: 0;
}

.pagination .page-item .page-link {
    color: var(--text-primary);
    background-color: var(--bg-primary);
    border-color: var(--border-color);
}

.pagination .page-item.active .page-link {
    background-color: var(--accent-color, #4CAF50);
    border-color: var(--accent-color, #4CAF50);
    color: white;
}

.pagination .page-item .page-link:hover {
    background-color: var(--bg-tertiary);
    border-color: var(--border-color);
    color: var(--text-primary);
}

.pagination .page-item.disabled .page-link {
    color: var(--text-secondary);
    background-color: var(--bg-primary);
    border-color: var(--border-color);
}

.items-per-page {
    display: flex;
    align-items: center;
}

.items-per-page-select {
    width: auto;
    font-size: 0.75rem;
    padding: 0.25rem;
}

/* Over/Under styles */
.over-under {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 600;
    margin-right: 5px;
    background-color: rgba(128, 128, 128, 0.15);
    color: var(--text-primary);
}

.over-under-over {
    background-color: rgba(76, 175, 80, 0.15);
    color: var(--success);
}

.over-under-under {
    background-color: rgba(244, 67, 54, 0.15);
    color: var(--danger);
}

.selected-over-under {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 600;
    margin-right: 5px;
    background-color: rgba(128, 128, 128, 0.2);
    color: var(--text-primary);
}

.selected-over-under-over {
    background-color: rgba(76, 175, 80, 0.2);
    color: var(--success);
}

.selected-over-under-under {
    background-color: rgba(244, 67, 54, 0.2);
    color: var(--danger);
}

.matchup-details {
    color: var(--text-secondary);
    font-size: 0.85rem;
    margin-top: 4px;
    font-style: italic;
}

.selected-matchup-details {
    color: var(--text-secondary);
    font-size: 0.85rem;
    margin-top: 4px;
    font-style: italic;
}

/* Make the parlay display cleaner */
.selected-legs-card .card-body {
    padding: 0.75rem;
}

/* Value-based badge styles */
.high-value-leg {
    border-left: 3px solid #28a745;
    position: relative;
    background-color: rgba(40, 167, 69, 0.03);
}

.high-value-leg:hover {
    border-color: #28a745;
    box-shadow: 0 6px 16px rgba(40, 167, 69, 0.2);
}

.high-value-badge {
    position: absolute;
    top: -8px;
    right: 95px;
    background-color: #28a745;
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.7rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.solid-value-leg {
    border-left: 3px solid #17a2b8;
    position: relative;
    background-color: rgba(23, 162, 184, 0.03);
}

.solid-value-leg:hover {
    border-color: #17a2b8;
    box-shadow: 0 6px 16px rgba(23, 162, 184, 0.2);
}

.solid-value-badge {
    position: absolute;
    top: -8px;
    right: 95px;
    background-color: #17a2b8;
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.7rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.high-value-icon, .solid-value-icon {
    font-size: 0.7rem;
}

/* Better contrast for the mini badge in compact mode */
.compact-strength {
    margin-top: 0.25rem;
}

.mini-badge {
    font-size: 0.65rem;
    font-weight: 600;
    padding: 4px 6px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    max-width: 100%;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.7);
    line-height: 1.2;
}

.mini-badge small {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
    display: inline;
}

.mini-badge.optimal {
    background-color: rgba(0, 136, 255, 0.25);
    border: 1px solid rgba(0, 136, 255, 0.5);
}

.mini-badge.strong {
    background-color: rgba(40, 167, 69, 0.25);
    border: 1px solid rgba(40, 167, 69, 0.5);
}

.mini-badge.weak {
    background-color: rgba(255, 193, 7, 0.25);
    border: 1px solid rgba(255, 193, 7, 0.5);
}

/* Add new styles for the three-column parlay stats grid */
.parlay-stats-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    width: 100%;
    margin-top: 8px;
}

.stat-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.stat-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.stat-label {
    font-size: 0.6rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
}

.stat-value {
    font-size: 0.8rem;
    font-weight: 700;
}

.potential-return {
    display: flex;
    flex-direction: column;
    background-color: rgba(25, 135, 84, 0.15);
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid rgba(25, 135, 84, 0.3);
}

.return-label {
    font-size: 0.6rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
}

.return-value {
    font-size: 0.95rem;
    font-weight: 700;
    color: #2ecc71;
}

/* Make form controls more visible */
.form-control-sm {
    background-color: rgba(30, 30, 30, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.9);
}

.form-control-sm:disabled {
    background-color: rgba(30, 30, 30, 0.6);
    color: rgba(255, 255, 255, 0.7);
}

/* Add stat-value color coding for different metrics */
.stat-value.odds-value {
    color: #f6c23e; /* Gold/yellow for odds */
}

.stat-value.prob-value {
    color: #4e73df; /* Blue for probability */
}

.stat-value.high-prob {
    color: #1cc88a; /* Green for high probability (>65%) */
}

.stat-value.medium-prob {
    color: #f6c23e; /* Yellow for medium probability (35-65%) */
}

.stat-value.low-prob {
    color: #e74a3b; /* Red for low probability (<35%) */
}

.stat-value.hold-value {
    color: #e74a3b; /* Red for hold % */
}

.stat-value.low-hold {
    color: #1cc88a; /* Green for low hold (<6%) */
}

.stat-value.high-hold {
    color: #e74a3b; /* Red for high hold (>10%) */
}

/* Auto correlation switch styling */
.correlation-control {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: rgba(20, 20, 20, 0.4);
    border-radius: 4px;
    padding: 4px;
}

.auto-label {
    font-size: 0.7rem;
    color: rgba(255, 255, 255, 0.8);
    margin-left: 4px;
}

/* Improve visibility for the mini-badge text */
.mini-badge small {
    color: rgba(255, 255, 255, 1);
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

/* Ensure team and matchup details are visible */
.player-moneyline {
    font-weight: 600;
    color: white;
    font-size: 0.9rem;
}

/* Enhance contrast for "Your Parlay" section */
.selected-legs-card .card-header {
    background-color: rgba(35, 35, 35, 0.95);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.selected-legs-card {
    background-color: rgba(28, 28, 28, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Fix for the text in the Moneyline entries */
.moneyline-badge {
    color: white;
    background-color: #3a7dff;
    font-weight: 600;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
}

/* Make text larger and more visible for Indiana Pacers vs Dallas Mavericks */
.selected-leg-main-info .moneyline-details,
.selected-leg-main-info .matchup-name {
    color: white;
    font-size: 0.85rem;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
    line-height: 1.3;
}

/* Add medium EV styling */
.stat-value.medium-ev {
    color: #ffab00; /* Amber color for medium EV (positive but not high) */
}

/* Additional styles to make sure text in selected legs is visible */
.selected-leg-item {
    background-color: rgba(25, 25, 25, 0.9);
    padding: 10px 12px;
}

.selected-prop-details,
.selected-spread-details,
.selected-matchup-details, 
.selected-total-details,
.selected-moneyline-details {
    color: rgba(255, 255, 255, 0.95);
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
    font-weight: 500;
}

/* Improve visibility of the decimal odds checkbox label */
.form-check-label small {
    color: #e0e0e0;
    font-weight: 500;
}

/* Update lay summary stats layout */
.lay-stats-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 100%;
}

.stat-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* Bookmaker badge styling */
.bookmaker-badge {
    font-size: 0.7rem;
    font-weight: 700;
    padding: 3px 5px;
    border-radius: 3px;
    margin-right: 5px;
    background-color: #0077c2 !important;
    color: white !important;
    display: inline-block;
    margin-top: 4px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
} 