.admin-tools-card {
  background-color: #2a2a2a;
  border: 1px solid #333;
  margin-bottom: 20px;
}

.admin-tools-card .card-header {
  background-color: #1a1a1a;
  border-bottom: 1px solid #333;
  padding: 15px 20px;
}

.admin-tools-card .card-header h4 {
  color: #29abe2;
  margin: 0;
  font-size: 1.2rem;
}

.admin-tools-card .card-body {
  padding: 20px;
}

.admin-tools-card h5 {
  color: #e0e0e0;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.admin-tools-card h6 {
  color: #29abe2;
  font-size: 1rem;
  font-weight: 600;
}

.admin-tools-card .text-muted {
  color: #aaa !important;
  margin-bottom: 20px;
}

.admin-tools-card .form-label {
  color: #e0e0e0;
}

.admin-tools-card .form-control {
  background-color: #1a1a1a;
  border: 1px solid #333;
  color: #e0e0e0;
}

.admin-tools-card .form-control:focus {
  background-color: #1a1a1a;
  border-color: #29abe2;
  color: #e0e0e0;
  box-shadow: 0 0 0 0.2rem rgba(41, 171, 226, 0.25);
}

.admin-tools-card .btn-primary {
  background-color: #29abe2;
  border-color: #29abe2;
}

.admin-tools-card .btn-primary:hover,
.admin-tools-card .btn-primary:focus {
  background-color: #1c8dbd;
  border-color: #1c8dbd;
}

.admin-tools-card .btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.admin-tools-card .btn-danger:hover,
.admin-tools-card .btn-danger:focus {
  background-color: #bd2130;
  border-color: #bd2130;
}

.admin-tools-card .alert {
  margin-bottom: 20px;
}

.admin-tools-card .alert-success {
  background-color: rgba(40, 167, 69, 0.1);
  border-color: rgba(40, 167, 69, 0.2);
  color: #28a745;
}

.admin-tools-card .alert-danger {
  background-color: rgba(220, 53, 69, 0.1);
  border-color: rgba(220, 53, 69, 0.2);
  color: #dc3545;
}

/* Tab styles */
.admin-tools-card .nav-tabs {
  border-bottom: 1px solid #333;
  margin-bottom: 20px;
}

.admin-tools-card .nav-tabs .nav-link {
  color: #aaa;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-right: 5px;
}

.admin-tools-card .nav-tabs .nav-link:hover {
  color: #e0e0e0;
  border-color: #333 #333 transparent;
}

.admin-tools-card .nav-tabs .nav-link.active {
  color: #29abe2;
  background-color: #1a1a1a;
  border-color: #333 #333 #1a1a1a;
}

.admin-tools-card .tab-content {
  padding-top: 10px;
}

/* User table styles */
.admin-tools-card .user-table {
  background-color: #1a1a1a;
  border-color: #333;
  margin-bottom: 20px;
}

.admin-tools-card .user-table thead th {
  background-color: #222;
  color: #e0e0e0;
  border-color: #333;
  font-weight: 600;
  padding: 10px;
}

.admin-tools-card .user-table tbody td {
  color: #e0e0e0;
  border-color: #333;
  padding: 10px;
  vertical-align: middle;
}

.admin-tools-card .user-table tbody tr:hover {
  background-color: rgba(41, 171, 226, 0.1);
}

.admin-tools-card .badge {
  font-size: 0.8rem;
  padding: 0.35em 0.65em;
}

.admin-tools-card .badge-admin {
  background-color: #ffc107;
  color: #212529;
}

.admin-tools-card .badge-premium {
  background-color: #28a745;
  color: #fff;
}

.admin-tools-card .badge-trial {
  background-color: #17a2b8;
  color: #fff;
}

.admin-tools-card .badge-free {
  background-color: #6c757d;
  color: #fff;
}

.admin-tools-card .table-responsive {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  border: 1px solid #333;
  border-radius: 4px;
}

.admin-tools-card .table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.admin-tools-card .table-responsive::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.admin-tools-card .table-responsive::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 4px;
}

.admin-tools-card .table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Search box styles */
.admin-tools-card .form-group {
  margin-bottom: 1rem;
}

.admin-tools-card .search-box {
  position: relative;
}

.admin-tools-card .search-box .form-control {
  padding-right: 40px;
}

.admin-tools-card .search-box .search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}

/* Pagination styles */
.admin-tools-card .pagination {
  justify-content: center;
  margin-top: 1rem;
}

.admin-tools-card .pagination .page-item .page-link {
  background-color: #1a1a1a;
  border-color: #333;
  color: #e0e0e0;
}

.admin-tools-card .pagination .page-item.active .page-link {
  background-color: #29abe2;
  border-color: #29abe2;
  color: #fff;
}

.admin-tools-card .pagination .page-item .page-link:hover {
  background-color: #333;
  border-color: #444;
  color: #fff;
}

.admin-tools-card .pagination .page-item.disabled .page-link {
  background-color: #1a1a1a;
  border-color: #333;
  color: #666;
}

/* User count and page info */
.admin-tools-card .user-count {
  color: #e0e0e0;
  font-size: 0.9rem;
}

.admin-tools-card .page-info {
  color: #aaa;
  font-size: 0.9rem;
}

/* Load more button */
.admin-tools-card .load-more-btn {
  margin-top: 1rem;
  width: 150px;
}

/* Promo code styles */
.admin-tools-card .input-group-text {
  background-color: #1a1a1a;
  border-color: #333;
  color: #e0e0e0;
}

.admin-tools-card .form-text {
  color: #aaa !important;
}

.admin-tools-card .form-check-input {
  background-color: #1a1a1a;
  border-color: #333;
}

.admin-tools-card .form-check-input:checked {
  background-color: #29abe2;
  border-color: #29abe2;
}

.admin-tools-card .form-check-label {
  color: #e0e0e0;
}

/* Banner preview styles */
.admin-tools-card .banner-preview {
  width: 100%;
  padding: 12px 20px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  font-weight: 500;
  transition: all 0.3s ease;
}

.admin-tools-card .banner-preview-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-tools-card .banner-preview-link {
  margin-left: 8px;
  font-size: 1.2rem;
}

/* Color picker styles */
.admin-tools-card input[type="color"] {
  height: 38px;
  padding: 2px;
}

.admin-tools-card .security-dashboard-btn {
  background-color: #29abe2;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-tools-card .security-dashboard-btn:hover {
  background-color: #1e8ab5;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.admin-tools-card .security-info-box {
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 15px 20px;
  margin-top: 10px;
}

.admin-tools-card .security-info-box h6 {
  color: #29abe2;
  margin-bottom: 10px;
}

.admin-tools-card .security-info-box ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.admin-tools-card .security-info-box ul li {
  color: #e0e0e0;
  padding: 6px 0;
  border-bottom: 1px solid #333;
  position: relative;
  padding-left: 20px;
}

.admin-tools-card .security-info-box ul li:last-child {
  border-bottom: none;
}

.admin-tools-card .security-info-box ul li:before {
  content: "•";
  color: #29abe2;
  position: absolute;
  left: 0;
  top: 6px;
}

/* Email Blast styles */
.email-preview-container {
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-height: 300px;
  max-height: 500px;
  overflow-y: auto;
}

.email-preview-container h5 {
  color: #333;
  margin-bottom: 15px;
}

.email-content-preview {
  color: #333;
  font-family: Arial, sans-serif;
  line-height: 1.5;
}

.email-content-preview h1,
.email-content-preview h2,
.email-content-preview h3,
.email-content-preview h4,
.email-content-preview h5,
.email-content-preview h6 {
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
}

.email-content-preview p {
  margin-bottom: 15px;
}

.email-content-preview a {
  color: #0066cc;
  text-decoration: underline;
}

.email-content-preview ul,
.email-content-preview ol {
  margin-bottom: 15px;
  padding-left: 25px;
}

.email-content-preview img {
  max-width: 100%;
  height: auto;
  margin: 10px 0;
}

.attachment-preview {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  font-size: 0.9rem;
}

/* Progress bar styling */
.progress {
  height: 10px;
  margin-bottom: 20px;
  background-color: #2a2a2a;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  background-color: #29abe2;
  transition: width 0.3s ease;
}

/* Rich editor styles for email blast */
.rich-editor-container {
  margin-bottom: 60px;
}

.ql-editor {
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #1a1a1a;
  color: #e0e0e0;
}

.ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #333;
  border-color: #444;
}

.ql-toolbar .ql-stroke {
  stroke: #e0e0e0;
}

.ql-toolbar .ql-fill {
  fill: #e0e0e0;
}

.ql-toolbar .ql-picker {
  color: #e0e0e0;
}

.ql-toolbar .ql-picker-options {
  background-color: #333;
  border-color: #444;
}

.ql-toolbar .ql-picker-item {
  color: #e0e0e0;
}

.ql-toolbar button:hover .ql-stroke,
.ql-toolbar button.ql-active .ql-stroke {
  stroke: #29abe2;
}

.ql-toolbar button:hover .ql-fill,
.ql-toolbar button.ql-active .ql-fill {
  fill: #29abe2;
}

.ql-toolbar .ql-picker-label:hover,
.ql-toolbar .ql-picker-label.ql-active,
.ql-toolbar .ql-picker-item:hover,
.ql-toolbar .ql-picker-item.ql-selected {
  color: #29abe2;
}

.ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: inherit;
  border-color: #444;
}

/* Changelog styles */
.changelog-list {
    max-height: 600px;
    overflow-y: auto;
}

.changelog-item {
    background-color: var(--bs-dark);
    border-color: var(--bs-gray-700) !important;
    transition: all 0.2s ease;
}

.changelog-item:hover {
    background-color: var(--bs-gray-900);
    border-color: var(--bs-primary) !important;
}

.changelog-preview {
    color: var(--bs-gray-400);
    font-size: 0.9rem;
    white-space: pre-line;
    max-height: 100px;
    overflow: hidden;
    position: relative;
}

.changelog-preview::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(transparent, var(--bs-dark));
}

.changelog-item:hover .changelog-preview::after {
    background: linear-gradient(transparent, var(--bs-gray-900));
}

/* Article Modal modifications for changelog */
.article-modal .ql-editor {
    min-height: 400px;
    font-family: monospace;
}

.article-modal .ql-editor pre {
    background-color: var(--bs-gray-900);
    color: var(--bs-gray-200);
    padding: 1rem;
    border-radius: 4px;
    margin: 1rem 0;
}

/* Markdown preview styles for changelog */
.changelog-preview h2 {
    color: var(--bs-primary);
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.changelog-preview h3 {
    color: var(--bs-info);
    font-size: 1.2rem;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
}

.changelog-preview ul {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
}

.changelog-preview li {
    margin-bottom: 0.5rem;
    color: var(--bs-gray-300);
} 