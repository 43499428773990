.updates-manager {
    padding: 20px;
    background-color: #1a1a1a;
    border-radius: 8px;
    margin-bottom: 30px;
}

.updates-manager h3 {
    color: #e0e0e0;
    margin-bottom: 20px;
}

.update-card {
    background-color: #2a2a2a !important;
    border: 1px solid #333;
    margin-bottom: 15px;
    transition: all 0.2s ease;
}

.update-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.update-card .card-title {
    color: #29abe2;
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.update-card .card-text {
    color: #e0e0e0;
    margin-bottom: 15px;
}

.update-card .text-muted {
    color: #999 !important;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.update-card .text-muted span.article-badge {
    background-color: #007bff;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.75rem;
}

.update-actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.update-actions button {
    min-width: 60px;
}

/* Modal Styling */
.modal-content {
    background-color: #2a2a2a;
    color: #e0e0e0;
}

.modal-header {
    border-bottom: 1px solid #333;
}

.modal-header .close {
    color: #e0e0e0;
}

.modal-title {
    color: #29abe2;
}

.form-label {
    color: #e0e0e0;
}

.form-control, .form-select {
    background-color: #1a1a1a;
    border: 1px solid #333;
    color: #e0e0e0;
}

.form-control:focus, .form-select:focus {
    background-color: #1a1a1a;
    border-color: #29abe2;
    color: #e0e0e0;
    box-shadow: 0 0 0 0.2rem rgba(41, 171, 226, 0.25);
}

.form-control::placeholder {
    color: #666;
}

/* Card types styling */
.update-card[data-type="announcement"] {
    border-left: 4px solid #007bff;
}

.update-card[data-type="update"] {
    border-left: 4px solid #28a745;
}

.update-card[data-type="tip"] {
    border-left: 4px solid #fd7e14;
}

/* Alert styling */
.alert-danger {
    background-color: rgba(220, 53, 69, 0.1);
    border: 1px solid rgba(220, 53, 69, 0.2);
    color: #dc3545;
}

/* Rich text editor styles */
.rich-editor-container {
    margin-bottom: 60px;
}

.ql-editor {
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
    background-color: #1a1a1a;
    color: #e0e0e0;
}

.ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #333;
    border-color: #444;
}

.ql-toolbar .ql-stroke {
    stroke: #e0e0e0;
}

.ql-toolbar .ql-fill {
    fill: #e0e0e0;
}

.ql-toolbar .ql-picker {
    color: #e0e0e0;
}

.ql-toolbar .ql-picker-options {
    background-color: #333;
    border-color: #444;
}

.ql-toolbar .ql-picker-item {
    color: #e0e0e0;
}

.ql-toolbar button:hover .ql-stroke,
.ql-toolbar button.ql-active .ql-stroke {
    stroke: #29abe2;
}

.ql-toolbar button:hover .ql-fill,
.ql-toolbar button.ql-active .ql-fill {
    fill: #29abe2;
}

.ql-toolbar .ql-picker-label:hover,
.ql-toolbar .ql-picker-label.ql-active,
.ql-toolbar .ql-picker-item:hover,
.ql-toolbar .ql-picker-item.ql-selected {
    color: #29abe2;
}

.ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-family: inherit;
    border-color: #444;
} 