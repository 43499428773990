.team-stats-container {
    margin-bottom: 2rem;
}

.team-stats-card {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    margin-bottom: 1rem;
}

.team-stats-card .card-header {
    background-color: var(--bg-tertiary);
    border-bottom: 1px solid var(--border-color);
}

.team-stats-card .card-header h5 {
    color: var(--text-primary);
    margin: 0;
    font-size: 1.1rem;
}

.stat-box {
    text-align: center;
    padding: 0.5rem;
}

.stat-box label {
    display: block;
    color: var(--text-secondary);
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
}

.stat-box span {
    color: var(--text-primary);
    font-size: 1.2rem;
    font-weight: 500;
} 