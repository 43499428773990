.paid-feature-container {
    position: relative;
}

.paid-feature-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-radius: 12px;
    padding: 2rem;
    text-align: center;
}

.paid-feature-overlay h4 {
    color: var(--text-primary);
    margin-bottom: 1rem;
}

.paid-feature-overlay p {
    color: var(--text-secondary);
    margin-bottom: 1.5rem;
}

.paid-feature-content.disabled {
    filter: blur(4px);
    pointer-events: none;
}

.upgrade-button {
    background-color: var(--accent-primary);
    border: none;
    padding: 0.75rem 2rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.upgrade-button:hover {
    transform: translateY(-2px);
    background-color: var(--accent-primary);
    filter: brightness(1.1);
}

.disabled-tab {
    opacity: 0.5;
    cursor: not-allowed;
} 