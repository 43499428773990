.about-container {
    padding: 2rem;
    color: var(--text-primary);
    max-width: 1200px;
    margin: 0 auto;
}

.about-title {
    color: var(--text-primary);
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.about-description {
    color: var(--text-secondary);
    font-size: 1.2rem;
    line-height: 1.6;
}

.mission-card {
    background-color: var(--bg-secondary);
    padding: 2rem;
    border-radius: 12px;
    margin: 1rem 0;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

.feature-card {
    background: var(--bg-tertiary) !important;
    border: 1px solid var(--border-color);
    margin-bottom: 1.5rem;
    height: 100%;
    transition: transform 0.2s ease;
}

.feature-card:hover {
    transform: translateY(-4px);
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

.team-card {
    background: var(--bg-tertiary) !important;
    border: 1px solid var(--border-color);
    margin-bottom: 1.5rem;
    height: 100%;
    transition: transform 0.2s ease;
}

.team-card:hover {
    transform: translateY(-4px);
}

.team-card small {
    color: var(--text-secondary);
}

.section-title {
    color: #ffffff;
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section-description {
    color: #a0aec0;
    font-size: 1.1rem;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.about-section {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.features-section,
.team-section {
    margin-bottom: 3rem;
}

.feature-title,
.member-name {
    color: #ffffff !important;
    font-size: 1.2rem;
    margin-bottom: 0.75rem;
}

.member-role {
    color: #ffffff !important;
    font-size: 1rem;
    margin-bottom: 0.75rem;
    font-weight: 500;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}

.feature-description,
.member-description {
    color: #a0aec0;
    font-size: 0.95rem;
    line-height: 1.6;
}

/* Slogan equation styling */
.slogan-equation {
    text-align: center;
    font-family: 'Courier New', monospace;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    background: rgba(0, 0, 0, 0.3);
    padding: 1rem;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: -0.5rem;
}

.eq-term {
    color: #4CAF50;
    font-weight: 600;
    padding: 0 0.5rem;
}

.eq-operator {
    color: #ffffff;
    font-weight: bold;
    padding: 0 0.5rem;
    font-size: 1.8rem;
}

.eq-result {
    color: #64B5F6;
    font-weight: 600;
    padding: 0 0.5rem;
}

/* New section styling */
.metrics-section,
.use-cases-section,
.feedback-section {
    margin-bottom: 3rem;
}

/* Metrics section styling */
.metric-card {
    background: var(--bg-tertiary) !important;
    border: 1px solid var(--border-color);
    margin-bottom: 1.5rem;
    height: 100%;
    transition: transform 0.2s ease;
    text-align: center;
    padding: 1rem;
    position: relative;
}

.metric-card:hover {
    transform: translateY(-4px);
}

.metric-title {
    color: #ffffff !important;
    font-size: 1.2rem;
    margin-bottom: 0.75rem;
}

.metric-value {
    color: #64B5F6 !important;
    font-size: 2.5rem !important;
    font-weight: 700;
    margin-bottom: 0.75rem;
}

.metric-description {
    color: #a0aec0;
    font-size: 0.95rem;
    line-height: 1.6;
}

.metrics-update-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #4CAF50;
    font-size: 1rem;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

/* Use cases section styling */
.use-case-card {
    background: var(--bg-tertiary) !important;
    border: 1px solid var(--border-color);
    margin-bottom: 1.5rem;
    height: 100%;
    transition: transform 0.2s ease;
}

.use-case-card:hover {
    transform: translateY(-4px);
}

.use-case-title {
    color: #ffffff !important;
    font-size: 1.2rem;
    margin-bottom: 0.75rem;
}

.use-case-description {
    color: #a0aec0;
    font-size: 0.95rem;
    line-height: 1.6;
}

/* Feedback section styling */
.feedback-section {
    background: rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 8px;
    margin-top: 2rem;
}

.feedback-button {
    margin-top: 1rem;
    background-color: #4CAF50 !important;
    border-color: #4CAF50 !important;
    transition: all 0.3s ease;
}

.feedback-button:hover {
    background-color: #388E3C !important;
    border-color: #388E3C !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Metrics refresh button */
.metrics-refresh-btn {
    color: #4CAF50 !important;
    background: transparent;
    border: none;
    padding: 0.25rem 0.5rem;
    margin-left: 0.5rem;
    font-size: 1.25rem;
    transition: all 0.3s ease;
}

.metrics-refresh-btn:hover {
    color: #64B5F6 !important;
    transform: rotate(90deg);
}

.metrics-refresh-btn:focus {
    box-shadow: none;
}

.metrics-refresh-btn .spinning {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .about-title {
        font-size: 2rem;
    }

    .features-grid,
    .team-grid {
        grid-template-columns: 1fr;
    }

    .about-container {
        padding: 1rem;
    }

    .section-title {
        font-size: 1.5rem;
    }

    .feature-title,
    .member-name {
        font-size: 1.1rem;
    }

    .metric-value {
        font-size: 2rem !important;
    }
    
    .use-case-title,
    .metric-title {
        font-size: 1.1rem;
    }
} 