.auth-buttons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    height: 100%;
}

.login-button, .join-button {
    height: 36px;
    padding: 0 1.25rem;
    font-weight: 500;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    min-width: 90px;
    transition: all 0.2s ease;
    margin: 0;
    line-height: 1;
}

.login-button {
    color: var(--accent-primary);
    background-color: transparent;
    border: 1.5px solid var(--accent-primary);
}

.login-button:hover {
    background-color: var(--accent-primary);
    border-color: var(--accent-primary);
    color: var(--bg-primary);
    transform: translateY(-1px);
}

.login-button:active {
    transform: translateY(0);
}

.join-button {
    background-color: var(--accent-primary);
    border: 1.5px solid var(--accent-primary);
    color: var(--bg-primary);
}

.join-button:hover {
    background-color: var(--accent-primary);
    filter: brightness(1.1);
    transform: translateY(-1px);
}

.join-button:active {
    transform: translateY(0);
}

.user-email {
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin-right: 1rem;
    line-height: 1;
}

.logout-button {
    height: 36px;
    padding: 0 1.25rem;
    font-weight: 500;
    border-radius: 8px;
    font-size: 0.9rem;
    min-width: 90px;
    transition: all 0.2s ease;
}

@media (max-width: 768px) {
    .auth-buttons {
        height: auto;
    }
}

/* Modal Styling */
.modal-content {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
}

.modal-header {
    border-bottom: 1px solid var(--border-color);
}

.modal-header .close {
    color: var(--text-primary);
}

.form-control {
    background-color: var(--bg-tertiary);
    border: 1px solid var(--border-color);
    color: var(--text-primary);
}

.form-control:focus {
    background-color: var(--bg-tertiary);
    border-color: var(--accent-primary);
    color: var(--text-primary);
    box-shadow: 0 0 0 0.2rem rgba(66, 133, 244, 0.25);
}

.form-label {
    color: var(--text-primary);
}

/* Loading state */
.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
} 