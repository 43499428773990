.homepage-container {
    padding: 0;
    overflow-x: hidden;
    background-color: #1a1a1a;
    color: #e0e0e0;
}

/* Hero Section */
.hero-section {
    background: linear-gradient(135deg, #1d4289 0%, #29abe2 100%);
    color: white;
    padding: 4rem 2rem;
    text-align: center;
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.hero-section h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.tagline {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: rgba(255, 255, 255, 0.9);
}

.cta-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.cta-button {
    padding: 0.75rem 2rem;
    font-weight: 600;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.15);
}

/* Section Styling */
section {
    margin-bottom: 4rem;
    padding: 0 2rem;
}

section h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-align: center;
    position: relative;
    padding-bottom: 0.5rem;
    color: #fff;
}

section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: #29abe2;
}

/* Today's Games Section */
.todays-games-section {
    background-color: #2a2a2a;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.game-card {
    background-color: #242424;
    border: 1px solid #333;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-size: 0.9rem;
}

.game-card .card-body {
    padding: 0.85rem;
}

.game-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
}

.game-card.live-game {
    border-color: #2e7d32;
    box-shadow: 0 0 15px rgba(46, 125, 50, 0.3);
}

.game-card.completed-game {
    border-color: #444;
    background-color: #1e1e1e;
}

.teams-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    padding: 0.75rem;
    background-color: rgba(42, 42, 42, 0.5);
    border-radius: 0.4rem;
    border: 1px solid #333;
}

.team {
    text-align: center;
    flex: 1;
}

.team-abbrev {
    font-size: 1.3rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 0.25rem;
    letter-spacing: 0.5px;
}

.team-score {
    font-size: 1.3rem;
    font-weight: bold;
    color: #29abe2;
}

.vs {
    font-size: 0.9rem;
    color: #666;
    margin: 0 0.75rem;
}

.game-time {
    text-align: center;
    color: #999;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.live-indicator, .completed-indicator {
    text-align: center;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 0.8rem;
}

.live-indicator {
    color: #4caf50;
    animation: pulse 2s infinite;
}

.completed-indicator {
    color: #888;
}

.odds-summary {
    background-color: rgba(26, 26, 26, 0.7);
    padding: 0.7rem 0.6rem;
    border-radius: 0.4rem;
    margin: 0.75rem 0;
    border: 1px solid #333;
    overflow: hidden;
}

.odds-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
    color: #e0e0e0;
}

.odds-row:last-child {
    margin-bottom: 0;
}

.odds-label {
    font-weight: 500;
    color: #999;
    width: 52px;
    min-width: 52px;
    font-size: 0.85rem;
    text-align: left;
    flex-shrink: 0;
}

.odds-values {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}

.odds-spread-value {
    font-weight: 600;
    min-width: 32px;
    text-align: center;
}

.positive-odds,
.negative-odds {
    color: #4caf50;
    font-weight: 600;
    display: flex;
    align-items: center;
    max-width: 48%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.negative-odds {
    color: #f44336;
}

.implied-prob {
    display: inline-block;
    font-size: 0.65rem;
    color: #aaa;
    margin-left: 2px;
    font-weight: normal;
    white-space: nowrap;
}

.odds-divider {
    color: #444;
    font-weight: 300;
    margin: 0 4px;
    flex-shrink: 0;
}

.game-actions {
    margin-top: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.game-actions .btn {
    border-width: 1px;
    font-weight: 500;
    padding: 0.4rem 0;
    letter-spacing: 0.5px;
    font-size: 0.85rem;
}

/* Feed Section */
.feed-section {
    padding: 2rem 0;
}

.feed-card {
    height: 100%;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: none;
    background-color: #2a2a2a;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    color: #e0e0e0;
}

.feed-card .card-body {
    background-color: transparent;
    border-radius: 0.5rem;
}

.feed-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feed-card .card-title {
    color: #fff;
}

.feed-card .card-text {
    color: #e0e0e0;
}

.feed-card .text-muted {
    color: #999 !important;
}

.feed-card.announcement {
    border-top: 4px solid #007bff;
}

.feed-card.update {
    border-top: 4px solid #28a745;
}

.feed-card.tip {
    border-top: 4px solid #fd7e14;
}

.feed-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.article-badge {
    background-color: #007bff;
    color: white;
    padding: 3px 8px;
    border-radius: 12px;
    font-size: 0.7rem;
    font-weight: 500;
    white-space: nowrap;
}

/* Matchup Predictions Section */
.matchup-predictions-section {
    background-color: #2a2a2a;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.prediction-card {
    height: 100%;
    transition: transform 0.2s ease;
    border: none;
    background-color: #2a2a2a;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 1.5rem;
    color: #e0e0e0;
}

.prediction-card .card-body {
    background-color: transparent;
    border-radius: 0.5rem;
    padding: 0;
}

.prediction-card:hover {
    transform: translateY(-5px);
}

.prediction-card .card-title {
    color: #29abe2;
    font-weight: 600;
    margin-bottom: 1rem;
}

.prediction-card .card-text {
    color: #e0e0e0;
}

/* About Preview Section */
.about-preview-section {
    padding: 2rem 0;
}

.about-text {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    color: #e0e0e0;
}

.feature-list {
    background-color: #2a2a2a;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.feature-list h4 {
    color: #29abe2;
    margin-bottom: 1rem;
    font-weight: 600;
}

.feature-list ul {
    padding-left: 1.5rem;
    color: #e0e0e0;
}

.feature-list li {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

/* Alert Styling */
.alert-info {
    background-color: #2a2a2a;
    border-color: #29abe2;
    color: #e0e0e0;
}

.alert-danger {
    background-color: #442a2a;
    border-color: #dc3545;
    color: #e0e0e0;
}

/* Spinner Styling */
.spinner-border {
    color: #29abe2;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .hero-section h1 {
        font-size: 2.5rem;
    }
    
    .tagline {
        font-size: 1.2rem;
    }
    
    .cta-buttons {
        flex-direction: column;
    }
    
    section {
        padding: 0 1rem;
    }
    
    .team-logo {
        width: 40px;
        height: 40px;
    }
}

/* Ensure sections have proper dark backgrounds */
.matchup-predictions-section, .todays-games-section {
    background-color: #1e1e1e;
}

.feature-list {
    background-color: #2a2a2a;
}

/* Override any bootstrap card backgrounds */
.card {
    background-color: transparent !important;
}

.card-body {
    background-color: transparent !important;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

/* FanDuel Best Player Props Section */
.best-bets-heading {
    color: #00d47e;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    font-weight: 600;
}

.best-props-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.best-props-game-row {
    background-color: #252525;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    margin-bottom: 1rem;
}

.best-props-game-row:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.best-props-game-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #1d4289 0%, #29abe2 100%);
    color: white;
    padding: 12px 16px;
}

.game-matchup {
    display: flex;
    flex-direction: column;
}

.game-time {
    font-size: 0.85rem;
    opacity: 0.9;
    margin-top: 4px;
}

.view-all-props {
    margin-left: auto;
}

.best-props-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.best-props-table th,
.best-props-table td {
    padding: 10px 8px;
    text-align: left;
    border-bottom: 1px solid #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.best-props-table th {
    background-color: #2a2a2a;
    color: #ccc;
    font-weight: 600;
    font-size: 0.85rem;
    text-transform: uppercase;
}

/* Column widths */
.best-props-table th:nth-child(1),
.best-props-table td:nth-child(1) {
    width: 35%;  /* Further increased Player name width */
}

.best-props-table th:nth-child(2),
.best-props-table td:nth-child(2) {
    width: 10%;  /* Prop type */
}

.best-props-table th:nth-child(3),
.best-props-table td:nth-child(3) {
    width: 7%;  /* Line */
    text-align: center;
}

.best-props-table th:nth-child(4),
.best-props-table td:nth-child(4) {
    width: 10%;  /* Selection */
    text-align: center;
}

.best-props-table th:nth-child(5),
.best-props-table td:nth-child(5) {
    width: 9%;  /* Odds */
    text-align: center;
}

.best-props-table th:nth-child(6),
.best-props-table td:nth-child(6) {
    width: 9%;  /* Edge */
    text-align: center;
}

.best-props-table th:nth-child(7),
.best-props-table td:nth-child(7) {
    width: 8%;  /* Rating */
    text-align: center;
}

.prop-row:hover {
    background-color: #2d2d2d;
}

.player-name {
    font-weight: 600;
    color: #ffffff;
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
    letter-spacing: -0.2px; /* Slightly condense text for longer names */
}

.player-name:hover {
    text-decoration: underline;
}

.prop-type {
    color: #aaa;
    font-size: 0.9rem;
}

.prop-line {
    white-space: nowrap;
}

.prop-selection {
    font-weight: 600;
    text-align: center;
}

.over-selection {
    color: #29abe2;
    font-weight: 600;
}

.under-selection {
    color: #ff9500;
    font-weight: 600;
}

.prop-odds {
    text-align: center;
    font-weight: 600;
}

.prop-edge {
    text-align: center;
    color: #00d47e;
    font-weight: 600;
}

.prop-value {
    font-weight: bold;
    text-align: center;
}

.high-value {
    color: #4cd964;
}

.medium-value {
    color: #ffcc00;
}

.low-value {
    color: #ff9500;
}

.loading-text {
    margin-top: 8px;
    font-size: 0.9rem;
    color: #aaa;
}

@media (max-width: 768px) {
    .best-props-table th,
    .best-props-table td {
        padding: 6px 4px;
        font-size: 0.75rem;
    }
    
    .player-name {
        max-width: 100px;
    }
    
    .best-props-game-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
    
    .view-all-props {
        margin-left: 0;
    }
    
    /* Mobile column widths */
    .best-props-table th:nth-child(1),
    .best-props-table td:nth-child(1) {
        width: 35%;  /* Player name */
    }
    
    .best-props-table th:nth-child(2),
    .best-props-table td:nth-child(2) {
        width: 14%;  /* Prop type */
    }
    
    .best-props-table th:nth-child(3),
    .best-props-table td:nth-child(3) {
        width: 8%;  /* Line */
    }
    
    .best-props-table th:nth-child(4),
    .best-props-table td:nth-child(4) {
        width: 10%;  /* Selection */
    }
    
    .best-props-table th:nth-child(5),
    .best-props-table td:nth-child(5) {
        width: 10%;  /* Odds */
    }
    
    .best-props-table th:nth-child(6),
    .best-props-table td:nth-child(6) {
        width: 8%;  /* Edge */
    }
    
    .best-props-table th:nth-child(7),
    .best-props-table td:nth-child(7) {
        width: 8%;  /* Rating */
    }
}

/* Premium subscription message */
.premium-message-container {
    margin-top: 20px;
}

.premium-message-card {
    background: linear-gradient(135deg, #2a2a2a 0%, #333333 100%) !important;
    border: 1px solid #444;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    padding: 20px 0;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.premium-message-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

.premium-lock-icon {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #ffc107;
    text-shadow: 0 0 15px rgba(255, 193, 7, 0.6);
    animation: floatIcon 3s ease-in-out infinite;
}

@keyframes floatIcon {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}

.premium-overlay-content {
    text-align: center;
    padding: 20px 25px;
    background: linear-gradient(to bottom, rgba(40, 40, 40, 0.95), rgba(20, 20, 20, 0.98));
    border-radius: 12px;
    max-width: 90%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.7), 0 0 20px rgba(255, 193, 7, 0.3);
    border: 3px solid rgba(255, 193, 7, 0.25);
    animation: fadeInContent 0.5s ease-out;
    min-width: 280px;
    max-height: 90vh;
    overflow: auto;
}

.premium-overlay-content h4 {
    color: #ffd54f;
    margin-bottom: 10px;
    font-size: 1.4rem;
    font-weight: bold;
    text-shadow: 0 2px 10px rgba(255, 193, 7, 0.5);
    letter-spacing: 0.5px;
    background: linear-gradient(to right, #ffd54f, #ffb300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.premium-overlay-content p {
    color: white;
    margin-bottom: 10px;
    font-size: 0.95rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.upgrade-button-container {
    margin-top: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.premium-upgrade-btn {
    padding: 8px 15px !important;
    font-size: 0.95rem !important;
    font-weight: 800 !important;
    background: #ffc107 !important;
    border: 2px solid rgba(255, 255, 255, 0.5) !important;
    border-radius: 6px !important;
    box-shadow: 0 0 20px rgba(255, 193, 7, 0.7) !important;
    color: #000 !important;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease !important;
    animation: pulse-button 2s infinite !important;
    margin-bottom: 5px;
}

@keyframes pulse-button {
    0% {
        box-shadow: 0 0 15px rgba(255, 193, 7, 0.5);
    }
    50% {
        box-shadow: 0 0 25px rgba(255, 193, 7, 0.9);
    }
    100% {
        box-shadow: 0 0 15px rgba(255, 193, 7, 0.5);
    }
}

/* Add CSS for value badges */
.value-badge {
    display: block;
    font-size: 0.7rem;
    padding: 2px 4px;
    border-radius: 3px;
    margin-top: 3px;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
}

.high-value-badge {
    background-color: rgba(40, 167, 69, 0.2);
    color: #28a745;
    border: 1px solid #28a745;
}

.medium-value-badge {
    background-color: rgba(255, 193, 7, 0.2);
    color: #ffc107;
    border: 1px solid #ffc107;
}

.low-value-badge {
    background-color: rgba(220, 53, 69, 0.2);
    color: #dc3545;
    border: 1px solid #dc3545;
}

/* Add CSS for premium content overlay with blur effect */
.premium-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.82);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    backdrop-filter: blur(6px);
    border-radius: 8px;
}

.premium-overlay-content h4 {
    color: #ffd54f;
    margin-bottom: 10px;
    font-size: 1.4rem;
    font-weight: bold;
    text-shadow: 0 2px 10px rgba(255, 193, 7, 0.5);
    letter-spacing: 0.5px;
    background: linear-gradient(to right, #ffd54f, #ffb300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.premium-overlay-content p {
    color: white;
    margin-bottom: 10px;
    font-size: 0.95rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.premium-btn {
    margin-top: 15px !important;
    width: auto;
    min-width: 220px;
}

.premium-btn i {
    margin-right: 8px;
}

.premium-overlay-content .btn-warning {
    font-weight: bold;
    padding: 12px 28px;
    font-size: 1.2rem;
    box-shadow: 0 4px 15px rgba(255, 193, 7, 0.4);
    background: linear-gradient(to bottom, #ffc107, #e0a800);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    animation: pulseButton 2s infinite;
    color: #000;
    margin-top: 8px;
}

.premium-overlay-content .btn-warning:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(255, 193, 7, 0.6);
    background: linear-gradient(to bottom, #ffcd39, #ffc107);
    border-color: rgba(255, 255, 255, 0.4);
}

.premium-overlay-content .btn-warning:active {
    transform: scale(0.98);
}

.locked-content-preview {
    position: relative;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #2a2a2a;
}

.locked-content-preview table {
    filter: blur(3px);
    opacity: 0.8;
}

.locked-content-preview tr {
    background-color: rgba(42, 42, 42, 0.5);
}

/* Make table content less recognizable but still visible through blur */
.locked-content-preview td {
    color: rgba(255, 255, 255, 0.7);
}

.premium-lock-icon i {
    font-size: 3rem;
    color: #f8d64e;
    background: -webkit-linear-gradient(#f8d64e, #e4a529);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    animation: floatIcon 3s ease-in-out infinite;
}

@keyframes pulseButton {
    0% {
        box-shadow: 0 4px 15px rgba(255, 193, 7, 0.4);
    }
    50% {
        box-shadow: 0 4px 25px rgba(255, 193, 7, 0.7);
    }
    100% {
        box-shadow: 0 4px 15px rgba(255, 193, 7, 0.4);
    }
}

/* Add new styles for the upgrade button container and improve button visibility */
.upgrade-button-container {
    margin-top: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.premium-upgrade-btn {
    padding: 15px 30px !important;
    font-size: 1.3rem !important;
    font-weight: 800 !important;
    background: #ffc107 !important;
    border: 3px solid rgba(255, 255, 255, 0.5) !important;
    border-radius: 10px !important;
    box-shadow: 0 0 20px rgba(255, 193, 7, 0.7) !important;
    color: #000 !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease !important;
    animation: pulse-button 2s infinite !important;
}

.premium-upgrade-btn:hover {
    transform: scale(1.05) !important;
    background: #ffcf40 !important;
    box-shadow: 0 0 30px rgba(255, 207, 64, 0.9) !important;
}

.premium-upgrade-btn i {
    font-size: 1.3rem;
    margin-right: 10px;
}

/* Enhance the pulse animation */
@keyframes pulse-button {
    0% {
        box-shadow: 0 0 15px rgba(255, 193, 7, 0.5);
    }
    50% {
        box-shadow: 0 0 25px rgba(255, 193, 7, 0.9);
    }
    100% {
        box-shadow: 0 0 15px rgba(255, 193, 7, 0.5);
    }
} 