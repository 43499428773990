.user-profile-container {
    margin: 20px auto 20px;
    max-width: 900px;
    color: #e0e0e0;
    padding-bottom: 20px;
    min-height: 70vh;
    background-color: #121212;
}

.profile-card, .subscription-card {
    margin-bottom: 20px;
    background-color: #1a1a1a;
    border: 1px solid #333;
    color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-card .card-header,
.subscription-card .card-header {
    background-color: #242424;
    border-bottom: 1px solid #333;
    color: #ffffff;
    font-weight: 600;
    padding: 15px 20px;
}

.profile-avatar {
    width: 120px;
    height: 120px;
    margin: 0 auto 20px;
    position: relative;
}

.profile-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #444;
    background-color: #2a2a2a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
}

.avatar-placeholder {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #2a2a2a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    color: #777;
    border: 3px solid #444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.edit-avatar-btn {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.75rem;
    padding: 4px 8px;
    border-radius: 15px;
    white-space: nowrap;
    background-color: #242424;
    border: 1px solid #007bff;
    color: #007bff;
    transition: all 0.3s ease;
    opacity: 0;
    z-index: 1;
}

.profile-avatar:hover .edit-avatar-btn {
    opacity: 1;
    bottom: -15px;
}

.edit-avatar-btn:hover {
    background-color: #007bff;
    color: white;
}

.avatar-updated-alert {
    margin-top: 15px;
    color: #28a745;
    font-size: 0.9rem;
    padding: 5px 10px;
    background-color: rgba(40, 167, 69, 0.1);
    border-radius: 4px;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.new-avatar-selected img {
    animation: pulseAvatar 0.5s ease;
}

@keyframes pulseAvatar {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
    50% {
        transform: scale(1.1);
        box-shadow: 0 0 15px rgba(0, 123, 255, 0.7);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
}

.profile-details {
    text-align: center;
    padding: 0 15px 20px;
}

.user-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 8px;
    color: #ffffff;
}

.user-email {
    color: #aaa;
    font-size: 1rem;
    word-break: break-all;
    margin-bottom: 15px;
}

.status-badge {
    font-size: 0.9rem;
    padding: 6px 12px;
    border-radius: 15px;
    display: inline-block;
    margin-top: 10px;
    font-weight: 500;
    color: #fff !important;
}

.subscription-table {
    width: 100%;
    margin-bottom: 0;
    border-collapse: separate;
    border-spacing: 0;
}

.subscription-table tr {
    border-bottom: 1px solid #333;
}

.subscription-table tr:last-child {
    border-bottom: none;
}

.subscription-table td {
    padding: 15px 20px;
    vertical-align: middle;
    line-height: 1.4;
}

.field-name {
    font-weight: 600;
    width: 40%;
    color: #bbbbbb;
    font-size: 0.95rem;
}

.field-value {
    width: 60%;
    color: #e0e0e0;
    font-size: 1rem;
}

.days-left {
    font-weight: 700;
    color: #ffc107;
    font-size: 1.1rem;
    margin-left: 5px;
}

.admin-alert, .trial-alert, .paid-alert {
    padding: 15px;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.admin-alert {
    background-color: rgba(0, 123, 255, 0.15);
    border: 1px solid rgba(0, 123, 255, 0.3);
    color: #8ebbff;
}

.trial-alert {
    background-color: rgba(255, 193, 7, 0.15);
    border: 1px solid rgba(255, 193, 7, 0.3);
    color: #ffe082;
}

.paid-alert {
    background-color: rgba(40, 167, 69, 0.15);
    border: 1px solid rgba(40, 167, 69, 0.3);
    color: #a3d9b0;
}

.upgrade-section {
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.logout-btn {
    background-color: #007bff;
    border-color: #007bff;
    margin-top: 5px;
    font-size: 0.9rem;
    padding: 5px 15px;
    width: auto;
    max-width: 120px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.logout-btn:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-warning {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #212529;
    font-weight: 500;
}

.btn-warning:hover {
    background-color: #e0a800;
    border-color: #d39e00;
    color: #212529;
}

.card-body {
    background-color: #1a1a1a;
    padding: 25px;
}

.bg-primary {
    background-color: #007bff !important;
}

.bg-success {
    background-color: #28a745 !important;
}

.bg-warning {
    background-color: #ffc107 !important;
    color: #212529 !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.bg-secondary {
    background-color: #6c757d !important;
}

.app-footer {
    margin-top: 20px;
    padding-top: 20px;
    position: relative;
    z-index: 100;
}

/* Override bootstrap's d-grid to allow for smaller centered button */
.btn-container {
    text-align: center;
    margin-top: 10px;
}

@media (max-width: 767.98px) {
    .profile-card {
        margin-bottom: 15px;
    }
    
    .field-name {
        width: 35%;
    }
    
    .field-value {
        width: 65%;
    }
    
    .user-profile-container {
        margin-bottom: 20px;
    }
}

.alert-message {
    padding: 15px;
    border-radius: 6px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}

.alert-message.success {
    background-color: rgba(40, 167, 69, 0.2);
    border-left: 4px solid #28a745;
    color: #d4edda;
}

.alert-message.warning {
    background-color: rgba(255, 193, 7, 0.2);
    border-left: 4px solid #ffc107;
    color: #fff3cd;
}

.alert-message.error {
    background-color: rgba(220, 53, 69, 0.2);
    border-left: 4px solid #dc3545;
    color: #f8d7da;
} 