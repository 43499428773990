.avatar-selector-modal .modal-content {
    background-color: #1a1a1a;
    border: 1px solid #333;
    color: #e0e0e0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

.avatar-selector-modal .modal-header {
    border-bottom: 1px solid #333;
    background-color: #242424;
    color: #ffffff;
}

.avatar-selector-modal .modal-footer {
    border-top: 1px solid #333;
    background-color: #242424;
    padding: 1rem;
}

.avatar-selector-modal .modal-title {
    font-weight: 600;
    font-size: 1.25rem;
}

.avatar-grid {
    padding: 15px 5px;
    margin: 0;
}

.avatar-option-col {
    padding: 10px;
    margin-bottom: 10px;
}

.avatar-option {
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    transition: all 0.2s ease;
    border: 2px solid transparent;
    background-color: #2a2a2a;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    height: 100%;
}

.avatar-option:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
    background-color: #333;
}

.avatar-option.selected {
    border-color: #007bff;
    background-color: rgba(0, 123, 255, 0.1);
}

.avatar-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    transition: transform 0.2s ease;
    background-color: transparent;
    padding: 5px;
    filter: brightness(1.1) contrast(1.1);
}

.avatar-option:hover .avatar-img {
    transform: scale(1.05);
}

.selected-indicator {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #007bff;
    font-size: 1.25rem;
}

/* Responsive adjustments */
@media (min-width: 576px) {
    .avatar-selector-modal .modal-dialog {
        max-width: 550px;
    }
    
    .avatar-option {
        min-height: 110px;
    }
    
    .avatar-img {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 767px) {
    .avatar-grid {
        padding: 10px 0;
    }
    
    .avatar-option {
        min-height: 90px;
        padding: 8px;
    }
    
    .avatar-img {
        width: 50px;
        height: 50px;
    }
}

/* Animation for new avatar selection */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.new-avatar-selected {
    animation: pulse 0.5s ease;
} 