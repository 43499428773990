.dark-theme-modal .modal-content {
    background-color: #1a1a1a;
    color: #ffffff;
    border: 1px solid #333;
    border-radius: 12px;
}

.dark-theme-modal .modal-header {
    border-bottom: 1px solid #333;
    background-color: #242424;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 1rem 1.5rem;
}

.dark-theme-modal .modal-header .modal-title {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.25rem;
}

.dark-theme-modal .modal-header .btn-close {
    color: #ffffff;
    filter: invert(1) grayscale(100%) brightness(200%);
    opacity: 0.8;
    transition: opacity 0.2s;
}

.dark-theme-modal .modal-header .btn-close:hover {
    opacity: 1;
}

.dark-theme-modal .modal-body {
    padding: 1.5rem;
}

.dark-theme-modal .google-button {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #ffffff;
    transition: all 0.2s ease;
    border-radius: 8px;
    padding: 0.75rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.dark-theme-modal .google-button:hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.3);
    transform: translateY(-1px);
}

.dark-theme-modal .google-button:active:not(:disabled) {
    transform: translateY(0);
}

.dark-theme-modal .google-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.dark-theme-modal .divider {
    position: relative;
    margin: 1.5rem 0;
}

.dark-theme-modal .divider span {
    background-color: #1a1a1a;
    padding: 0 1rem;
    color: #888;
    position: relative;
    z-index: 1;
    font-size: 0.9rem;
}

.dark-theme-modal .divider:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 0;
}

.dark-theme-modal .dark-input {
    background-color: #2a2a2a;
    border: 1px solid #333;
    color: #ffffff;
    border-radius: 8px;
    padding: 0.75rem 1rem;
    font-size: 0.95rem;
    transition: all 0.2s ease;
}

.dark-theme-modal .dark-input:focus {
    background-color: #2a2a2a;
    border-color: #0d6efd;
    color: #ffffff;
    box-shadow: 0 0 0 3px rgba(13, 110, 253, 0.15);
}

.dark-theme-modal .dark-input::placeholder {
    color: #666;
}

.dark-theme-modal .btn-primary {
    background-color: #0d6efd;
    border-color: #0d6efd;
    padding: 0.75rem;
    font-weight: 500;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.dark-theme-modal .btn-primary:hover:not(:disabled) {
    background-color: #0b5ed7;
    border-color: #0a58ca;
    transform: translateY(-1px);
}

.dark-theme-modal .btn-primary:active:not(:disabled) {
    transform: translateY(0);
}

.dark-theme-modal .btn-primary:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.dark-theme-modal .alert-danger {
    background-color: rgba(220, 53, 69, 0.1);
    border: 1px solid rgba(220, 53, 69, 0.2);
    color: #ff4d4d;
    border-radius: 8px;
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
}

.dark-theme-modal .form-label {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.dark-theme-modal .form-group {
    margin-bottom: 1.25rem;
} 