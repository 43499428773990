.support-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.docs-links {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
}

.doc-link {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.doc-link:hover {
    background-color: var(--accent-primary);
    color: white;
    transform: translateY(-2px);
}

.contact-form {
    background-color: var(--bg-secondary);
    padding: 2rem;
    border-radius: 12px;
    max-width: 600px;
    margin: 0 auto;
}

.accordion-button {
    background-color: var(--bg-secondary) !important;
    color: var(--text-primary) !important;
}

.accordion-body {
    background-color: var(--bg-tertiary);
    color: var(--text-secondary);
}

.submit-btn {
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.submit-btn:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.alert {
    animation: fadeIn 0.3s ease-in-out;
}

.recaptcha-container {
    display: none; /* Hide this since we're using invisible reCAPTCHA v3 */
}

.g-recaptcha-wrapper {
    display: none; /* Unused with reCAPTCHA v3 */
}

.g-recaptcha-wrapper small {
    display: none; /* Unused with reCAPTCHA v3 */
}

/* Responsive styling for smaller screens */
@media (max-width: 400px) {
    .g-recaptcha {
        display: none; /* Unused with reCAPTCHA v3 */
    }
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
} 