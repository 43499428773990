.article-modal .modal-content {
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  background-color: #2a2a2a;
  color: #e0e0e0;
}

.article-modal .modal-header {
  border-bottom: 1px solid #444;
  padding: 1.5rem;
  background-color: #252525;
}

.article-modal .modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
}

.article-modal .modal-body {
  padding: 1.5rem;
  max-height: 70vh;
  overflow-y: auto;
  background-color: #2a2a2a;
}

.article-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #444;
  color: #aaa;
  font-size: 0.9rem;
}

.article-type {
  background-color: #007bff;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
}

.article-content {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #e0e0e0;
}

.article-content img {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
  border-radius: 4px;
}

.article-content h1, 
.article-content h2, 
.article-content h3, 
.article-content h4, 
.article-content h5, 
.article-content h6 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #fff;
}

.article-content p {
  margin-bottom: 1rem;
  color: #e0e0e0;
}

.article-content a {
  color: #29abe2;
  text-decoration: none;
}

.article-content a:hover {
  text-decoration: underline;
}

.article-content blockquote {
  border-left: 4px solid #29abe2;
  padding-left: 1rem;
  margin-left: 0;
  color: #bbb;
  font-style: italic;
}

.article-content ul, 
.article-content ol {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  color: #e0e0e0;
}

.article-content table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
}

.article-content table th,
.article-content table td {
  padding: 0.5rem;
  border: 1px solid #444;
  color: #e0e0e0;
}

.article-content table th {
  background-color: #333;
  font-weight: 600;
}

.article-modal .modal-footer {
  border-top: 1px solid #444;
  background-color: #252525;
}

.article-modal .btn-secondary {
  background-color: #444;
  border-color: #555;
  color: #fff;
}

.article-modal .btn-secondary:hover {
  background-color: #555;
  border-color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .article-modal .modal-title {
    font-size: 1.25rem;
  }
  
  .article-content {
    font-size: 1rem;
  }
}

.modal-90w {
  max-width: 90%;
  width: 90%;
}

.dark-theme .modal-content {
  background-color: #2a2a2a;
  color: #e0e0e0;
}

.dark-theme .close {
  color: #fff;
  text-shadow: 0 1px 0 #000;
  opacity: 0.8;
}

.dark-theme .close:hover {
  color: #fff;
  opacity: 1;
}

.rich-editor-container {
  background-color: #1e1e1e;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.rich-editor-container .ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #2d2d2d;
  border-color: #444;
}

.rich-editor-container .ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #1e1e1e;
  border-color: #444;
  color: #fff;
}

.rich-editor-container .ql-editor {
  min-height: 200px;
}

.rich-editor-container .ql-snow.ql-toolbar button,
.rich-editor-container .ql-snow .ql-picker-label {
  color: #fff;
}

.rich-editor-container .ql-snow.ql-toolbar button:hover,
.rich-editor-container .ql-snow .ql-picker-label:hover {
  color: #29abe2;
}

.rich-editor-container .ql-snow.ql-toolbar button.ql-active,
.rich-editor-container .ql-snow .ql-picker-label.ql-active {
  color: #29abe2;
}

.rich-editor-container .ql-snow .ql-stroke {
  stroke: #fff;
}

.rich-editor-container .ql-snow .ql-fill {
  fill: #fff;
}

.rich-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.rich-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke {
  stroke: #29abe2;
}

.rich-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.rich-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill {
  fill: #29abe2;
} 