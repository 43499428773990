.stats-new-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.header-section {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.header-section h2 {
    margin: 0;
    color: var(--text-primary);
}

.form-label {
    color: var(--text-primary);
}

.form-select {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border-color: var(--border-color);
}

.form-select:focus {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border-color: var(--accent-primary);
    box-shadow: 0 0 0 0.25rem rgba(66, 133, 244, 0.25);
}

.btn-primary {
    background-color: var(--accent-primary);
    border-color: var(--accent-primary);
    margin-top: 1rem;
}

.btn-primary:hover {
    background-color: #1a73e8;
    border-color: #1a73e8;
}

.insights-container {
    padding: 15px;
    color: var(--text-primary);
}

.insight-item {
    margin-bottom: 15px;
    padding: 10px;
    border-left: 3px solid var(--accent-primary);
    background-color: var(--bg-secondary);
    border-radius: 4px;
}

.insights-button {
    font-size: 0.9em;
    padding: 5px 10px;
    margin-top: 10px;
    color: var(--accent-primary) !important;
}

/* Modal styling to match theme */
.player-insights-modal .modal-content {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
}

.player-insights-modal .modal-header {
    border-bottom: 1px solid var(--border-color);
    background-color: var(--bg-secondary);
}

.player-insights-modal .modal-header .btn-close {
    color: var(--text-primary);
    filter: invert(1);
} 