.lay-odds-container {
    padding: 1.5rem;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    color: var(--text-primary);
    background-color: var(--bg-primary);
    overflow-x: hidden;
}

.lay-odds-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
}

.header-left {
    flex: 1;
}

.header-left h2 {
    margin-bottom: 0.5rem;
    color: var(--text-primary);
    font-weight: 600;
}

.header-description {
    color: var(--text-secondary);
    font-size: 1rem;
    margin-bottom: 0;
}

.header-right {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.controls-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.date-input {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
    padding: 0.5rem;
    border-radius: 4px;
    transition: border-color 0.2s ease;
}

.date-input:focus {
    border-color: #4CAF50;
    outline: none;
}

.refresh-button {
    background-color: #2c3e50 !important;
    border-color: #2c3e50 !important;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease;
    color: white !important;
}

.refresh-button:hover {
    background-color: #34495e !important;
    border-color: #34495e !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.refresh-button-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.refresh-icon {
    font-size: 1rem;
}

.date-display {
    margin-bottom: 1.5rem;
    color: var(--text-secondary);
    font-size: 1.1rem;
}

.highlighted-date {
    color: #4CAF50;
    font-weight: 600;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    gap: 1rem;
    background-color: var(--bg-secondary);
    border-radius: 8px;
    margin: 2rem 0;
}

.parlays-stats {
    margin-bottom: 2rem;
}

.stats-card {
    background-color: var(--bg-tertiary) !important;
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem 0;
}

.stat-value {
    font-size: 1.8rem;
    font-weight: 700;
    color: #64B5F6;
    margin-bottom: 0.5rem;
}

.stat-label {
    font-size: 0.9rem;
    color: var(--text-secondary);
}

.parlays-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

@media (min-width: 768px) {
    .parlays-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 767px) {
    .parlays-grid {
        grid-template-columns: 1fr;
    }
}

.parlay-card {
    background-color: var(--bg-tertiary) !important;
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: 100%;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
}

.parlay-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.parlay-card .card-header {
    background-color: rgba(0, 0, 0, 0.2) !important;
    border-bottom: 1px solid var(--border-color);
    padding: 0.75rem 1rem;
}

.parlay-card .card-body {
    padding: 1rem;
    background-color: var(--bg-tertiary) !important;
}

.parlay-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.parlay-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.parlay-title h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--text-primary);
}

.trophy-icon {
    font-size: 1.2rem;
}

.trophy-icon.gold {
    color: #FFD700;
    filter: drop-shadow(0 0 2px rgba(255, 215, 0, 0.5));
}

.trophy-icon.silver {
    color: #C0C0C0;
    filter: drop-shadow(0 0 2px rgba(192, 192, 192, 0.5));
}

.trophy-icon.bronze {
    color: #CD7F32;
    filter: drop-shadow(0 0 2px rgba(205, 127, 50, 0.5));
}

.confidence-badge {
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    cursor: help;
}

.confidence-stars {
    color: gold;
    margin-right: 5px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    letter-spacing: -2px;
}

.high-confidence {
    background-color: #4CAF50 !important;
    color: white !important;
}

.medium-confidence {
    background-color: #FFC107 !important;
    color: #212529 !important;
}

.low-confidence {
    background-color: #F44336 !important;
    color: white !important;
}

.emergency-fallback {
    background-color: #FF9800 !important;
    color: #212529 !important;
    border: 1px dashed #d32f2f;
}

.parlay-legs-table {
    margin-bottom: 1rem;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.parlay-legs-table th,
.parlay-legs-table td {
    padding: 0.75rem;
    vertical-align: top;
    border-bottom: 1px solid var(--border-color);
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.parlay-legs-table th {
    font-weight: 600;
    color: var(--text-secondary);
    text-align: left;
    border-bottom: 2px solid var(--border-color);
    background-color: rgba(0, 0, 0, 0.1);
}

.parlay-legs-table th:first-child {
    width: 35%;
}

.parlay-legs-table th:nth-child(2) {
    width: 45%;
}

.parlay-legs-table th:last-child {
    width: 20%;
}

.table {
    color: var(--text-primary) !important;
    background-color: transparent !important;
    border-collapse: separate;
    border-spacing: 0 2px;
}

.table thead th {
    border-bottom: 1px solid var(--border-color);
    background-color: var(--bg-secondary) !important;
    color: var(--text-primary) !important;
    padding: 8px 10px;
}

.table tbody td {
    padding: 10px;
    background-color: var(--bg-tertiary) !important;
    color: var(--text-primary) !important;
    border-color: var(--border-color);
}

.table tbody tr:hover td {
    background-color: rgba(255, 255, 255, 0.05) !important;
}

/* Additional styles to ensure dark theme consistency */
.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.game-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.game-time {
    font-size: 0.8rem;
    color: var(--text-secondary);
    margin-top: 0.25rem;
}

.player-prop-row td {
    background-color: rgba(100, 181, 246, 0.05) !important;
}

.player-prop-row:hover td {
    background-color: rgba(100, 181, 246, 0.1) !important;
}

.player-name {
    font-weight: 600;
    color: #64B5F6;
    margin-bottom: 2px;
}

.prop-team {
    font-size: 0.8rem;
    color: var(--text-secondary);
    margin-top: 0.25rem;
}

.player-prop-description {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.prop-stat {
    font-weight: 600;
    color: #64B5F6;
}

.synthetic-indicator {
    display: none;
}

.prop-detail {
    color: var(--text-secondary);
    font-size: 0.9em;
}

.odds-cell {
    font-weight: 600;
    text-align: right;
}

/* Add color for different odds types */
.odds-cell[data-positive="true"] {
    color: #4CAF50;
}

.odds-cell[data-negative="true"] {
    color: #F44336;
}

.low-hold-badge {
    margin-left: 4px;
    font-size: 0.9em;
    cursor: help;
}

.parlay-summary {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--border-color);
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0 0 8px 8px;
    padding: 1rem;
    margin: 0 -1rem -1rem -1rem;
    flex-wrap: wrap;
    gap: 0.75rem;
}

.parlay-odds, .parlay-return, .parlay-hold {
    display: flex;
    flex-direction: column;
}

.parlay-odds .label, .parlay-return .label, .parlay-hold .label {
    font-size: 0.9rem;
    color: var(--text-secondary);
    margin-bottom: 0.25rem;
}

.parlay-odds .value, .parlay-return .value, .parlay-hold .value {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--text-primary);
}

.parlay-odds .value[data-positive="true"] {
    color: #4CAF50;
}

.parlay-odds .value[data-negative="true"] {
    color: #F44336;
}

.parlay-return .value {
    color: #4CAF50;
}

.parlay-hold .value.good-hold {
    color: #4CAF50;
}

.parlay-hold .value.high-hold {
    color: #F44336;
}

.disclaimer {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 1rem;
    border-radius: 8px;
    margin-top: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.disclaimer-icon {
    color: #FFC107;
    font-size: 1.2rem;
    margin-top: 0.25rem;
}

.disclaimer p {
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin-bottom: 0;
    line-height: 1.5;
}

/* Improve the bookmaker badges styling */
.bookmaker-badge {
    display: inline-block;
    background-color: #0e76fd;
    color: white;
    font-size: 0.7rem;
    padding: 2px 6px;
    border-radius: 4px;
    margin-top: 4px;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

.fanduel-badge {
    background-color: #1A75FF;
    color: white;
    margin-left: 8px;
    font-size: 0.8rem;
    padding: 3px 8px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    border-radius: 4px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-shadow: 0 1px 1px rgba(0,0,0,0.3);
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    transition: all 0.2s ease;
    display: inline-block;
    border: 2px solid white;
    margin-top: 2px;
    animation: pulse 2s infinite;
}

/* Add a pulsing animation to make the badge stand out */
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(26, 117, 255, 0.7);
    }
    70% {
        box-shadow: 0 0 0 6px rgba(26, 117, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(26, 117, 255, 0);
    }
}

.fanduel-badge:hover {
    background-color: #0052CC;
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(0,0,0,0.4);
}

.bookmaker-info {
    margin-top: 5px;
}

.bookmaker-tag {
    display: block;
    font-size: 0.7rem;
    color: #0e76fd;
    font-weight: 600;
    margin-top: 5px;
    text-transform: uppercase;
}

@media (min-width: 1400px) {
    .lay-odds-container {
        max-width: 1320px;
    }
}

@media (max-width: 768px) {
    .lay-odds-container {
        padding: 1rem;
    }
    
    .lay-odds-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .header-right {
        margin-top: 1rem;
        width: 100%;
    }
    
    .controls-group {
        width: 100%;
        justify-content: space-between;
    }
    
    .date-input {
        flex: 1;
        margin-right: 0.5rem;
    }

    .parlays-stats .row {
        flex-direction: column;
    }
    
    .stat-item {
        padding: 0.75rem 0;
        margin-bottom: 0.5rem;
    }
    
    .stat-value {
        font-size: 1.5rem;
        margin-bottom: 0.25rem;
    }
    
    .stat-label {
        font-size: 0.8rem;
    }
}

/* Add styles for the new EV badges */
.ev-badge {
    font-size: 0.7rem !important;
    padding: 2px 4px !important;
    border-radius: 4px !important;
    font-weight: 600 !important;
    margin-left: 5px;
    display: inline-block;
}

.positive-ev {
    background-color: #00C853 !important;
    color: white !important;
}

.negative-ev {
    background-color: #FF5252 !important;
    color: white !important;
}

/* Style for market inefficiency badges */
.inefficiency-badge {
    font-size: 0.65rem !important;
    padding: 2px 4px !important;
    border-radius: 4px !important;
    font-weight: 600 !important;
    margin-left: 5px;
    display: inline-block;
}

.inefficiency-badge.high {
    background-color: #7B1FA2 !important; /* Purple for high inefficiency */
    color: white !important;
}

.inefficiency-badge.medium-high {
    background-color: #673AB7 !important; /* Deep purple for medium-high inefficiency */
    color: white !important;
}

.inefficiency-badge.medium {
    background-color: #5E35B1 !important; /* Indigo for medium inefficiency */
    color: white !important;
}

/* Styles for odds range badges */
.odds-range-badge {
    font-size: 0.65rem !important;
    padding: 2px 4px !important;
    border-radius: 4px !important;
    font-weight: 600 !important;
    margin-left: 5px;
    display: inline-block;
}

.odds-range-badge.target {
    background-color: #2196F3 !important; /* Blue for target range */
    color: white !important;
}

.odds-range-badge.avoid {
    background-color: #FF9800 !important; /* Orange for caution/avoid range */
    color: white !important;
}

/* Add a subtle indicator for correlated parlays */
.parlay-header .correlated-indicator {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    font-size: 0.75rem;
    color: #64B5F6;
    background-color: rgba(100, 181, 246, 0.15);
    padding: 2px 6px;
    border-radius: 4px;
}

.correlated-icon {
    margin-right: 4px;
    font-size: 0.9rem;
}

/* Style for the bet metadata section */
.bet-metadata {
    display: flex;
    align-items: center;
    margin-top: 3px;
    flex-wrap: wrap;
    gap: 5px;
}

/* Update player prop description to accommodate new badge */
.player-prop-description {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

/* Regular bet description */
.bet-description {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

/* Make sure badges have proper padding */
.badge {
    margin: 0;
    white-space: nowrap;
}

.parlay-metrics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--border-color);
    background-color: rgba(0, 0, 0, 0.25);
    padding: 0.75rem;
    border-radius: 4px;
    gap: 0.5rem;
}

.parlay-metrics .label {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.85rem;
    font-weight: 500;
    margin-right: 0.25rem;
}

.parlay-metrics .value {
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
}

.ev-summary, .correlation-strength, .market-mix {
    margin-bottom: 0.5rem;
    flex: 1 0 30%;
}

.correlation-strength .value {
    color: #0056b3;
}

@media (max-width: 768px) {
    .parlay-metrics {
        flex-direction: column;
    }
    
    .ev-summary, .correlation-strength, .market-mix {
        margin-bottom: 5px;
    }
}

/* Modern tooltips with black background and white text */
[title] {
    position: relative;
    cursor: help;
}

/* Disable tooltips for confidence badges */
.confidence-badge {
    cursor: default;
}

.confidence-badge[title]::before,
.confidence-badge[title]::after {
    display: none !important;
}

/* Improved tooltip positioning and visibility */
[title]::before,
[title]::after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    position: fixed;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100000;
}

[title]::before {
    content: "";
    border: 5px solid transparent;
    border-top-color: #000;
    margin-bottom: -5px;
    z-index: 100000;
}

[title]::after {
    content: attr(title);
    background-color: #000;
    color: #fff;
    padding: 8px 10px;
    border-radius: 5px;
    white-space: normal;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    font-size: 0.8rem;
    max-width: 300px;
    width: max-content;
    z-index: 100000;
    text-align: center;
}

[title]:hover::before,
[title]:hover::after,
[title]:focus::before,
[title]:focus::after {
    visibility: visible;
    opacity: 1;
}

/* Position tooltips above the element */
[title]:hover::before,
[title]:focus::before {
    margin-top: -15px;
}

[title]:hover::after,
[title]:focus::after {
    margin-top: -10px;
}

/* Special styling for badge tooltips */
.badge[title]::after {
    width: 200px;
    white-space: normal;
    text-align: center;
}

@media (max-width: 576px) {
    .parlay-legs-table {
        font-size: 0.8rem;
    }
    
    .parlay-legs-table th,
    .parlay-legs-table td {
        padding: 0.4rem;
    }
    
    .parlay-legs-table th:first-child {
        width: 32%;
    }
    
    .parlay-legs-table th:nth-child(2) {
        width: 48%;
    }
    
    .parlay-legs-table th:last-child {
        width: 20%;
    }
    
    .game-info {
        font-size: 0.75rem;
    }
    
    .player-name {
        font-size: 0.85rem;
    }
    
    .table-responsive {
        margin: 0 -0.5rem;
        width: calc(100% + 1rem);
    }
}

@media (max-width: 576px) {
    .parlay-summary {
        flex-direction: column;
        gap: 0.5rem;
    }
    
    .parlay-odds, .parlay-return, .parlay-hold {
        align-items: flex-start;
        margin-bottom: 0.5rem;
    }
    
    .parlay-metrics {
        flex-direction: column;
        gap: 0.5rem;
    }
    
    .ev-summary, .correlation-strength, .market-mix {
        margin-bottom: 0.5rem;
    }
}

/* Add these styles */
.analyzing-status {
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 12px 15px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-left: 4px solid #0d6efd;
}

.analyzing-status .spinner-border {
    margin-right: 10px;
}

.parlay-generation-stats {
    margin-top: 20px;
    margin-bottom: 20px;
}

.parlay-stats-details {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 6px;
    border-left: 4px solid #28a745;
}

.parlay-stats-details div {
    flex: 1;
    min-width: 120px;
}

/* Add styles for the subtabs */
.lay-odds-subtabs {
    margin-bottom: 2rem !important;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    gap: 0.5rem;
}

.lay-odds-subtabs .nav-link {
    color: var(--text-secondary);
    border: none;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    transition: all 0.2s ease;
    border-radius: 4px 4px 0 0;
    position: relative;
}

.lay-odds-subtabs .nav-link:hover {
    color: var(--text-primary);
    background-color: rgba(255, 255, 255, 0.05);
}

.lay-odds-subtabs .nav-link.active {
    color: #4CAF50 !important;
    background-color: var(--bg-secondary) !important;
    border: 1px solid var(--border-color) !important;
    border-bottom-color: transparent !important;
}

.lay-odds-subtabs .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #4CAF50;
}

/* Responsive styles for tabs */
@media (max-width: 768px) {
    .lay-odds-subtabs {
        flex-wrap: wrap;
        gap: 0.25rem;
    }

    .lay-odds-subtabs .nav-link {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        flex: 1;
        text-align: center;
        min-width: 100px;
    }
}

/* Player Props Table Styles */
.player-props-content {
    margin-top: 1rem;
}

.player-props-table {
    margin-bottom: 0;
    color: var(--text-primary);
}

.player-props-table thead th {
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
    border-bottom: 1px solid var(--border-color);
    padding: 0.75rem;
    font-weight: 600;
    font-size: 0.9rem;
}

.player-props-table tbody td {
    padding: 0.75rem;
    border-bottom: 1px solid var(--border-color);
    vertical-align: middle;
}

.player-props-table tbody tr:hover {
    background-color: var(--bg-hover);
}

.player-props-table .positive-odds {
    color: #4CAF50;
    font-weight: 600;
}

.player-props-table .negative-odds {
    color: #f44336;
    font-weight: 600;
}

/* FanDuel Best Player Props Section */
.best-bets-heading {
    color: #00d47e;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    font-weight: 600;
}

.best-props-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.best-props-game-row {
    background-color: var(--bg-tertiary);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    margin-bottom: 1rem;
}

.best-props-game-row:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.best-props-game-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #1d4289 0%, #29abe2 100%);
    color: white;
    padding: 12px 16px;
}

.game-matchup {
    display: flex;
    flex-direction: column;
}

.game-time {
    font-size: 0.85rem;
    opacity: 0.9;
    margin-top: 4px;
}

.view-all-props {
    margin-left: auto;
}

.best-props-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    margin-bottom: 20px;
    background-color: var(--bg-tertiary);
}

.best-props-table th,
.best-props-table td {
    padding: 12px 8px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
    white-space: normal;
    line-height: 1.4;
}

.best-props-table th {
    background-color: var(--bg-secondary);
    color: #ffffff;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.best-props-table .player-name {
    color: #ffffff;
    font-weight: 500;
    max-width: none;
    white-space: normal;
}

.best-props-table .prop-type {
    color: #a8a8a8;
}

.best-props-table .prop-line {
    text-align: center;
    color: #ffffff;
}

.best-props-table .prop-selection {
    text-align: center;
}

.best-props-table .over-selection {
    color: #00d47e;
    font-weight: 500;
}

.best-props-table .under-selection {
    color: #ff4d4d;
    font-weight: 500;
}

.best-props-table .prop-odds {
    text-align: center;
}

.best-props-table .positive-odds {
    color: #00d47e;
    font-weight: 600;
}

.best-props-table .negative-odds {
    color: #ff4d4d;
    font-weight: 600;
}

.best-props-table .prop-edge {
    text-align: center;
    color: #00d47e;
    font-weight: 500;
}

.best-props-table .prop-value {
    text-align: center;
    font-weight: 600;
}

.best-props-table .high-value {
    color: #00d47e;
}

.best-props-table .medium-value {
    color: #ffd700;
}

.best-props-table .low-value {
    color: #ff4d4d;
}

.best-props-game-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #1a237e 0%, #0d47a1 100%);
    color: white;
    padding: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 0;
}

.best-props-game-row {
    margin-bottom: 24px;
    background: var(--bg-tertiary);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.game-matchup {
    font-size: 1.1rem;
    font-weight: 600;
}

.game-time {
    font-size: 0.9rem;
    opacity: 0.9;
    margin-top: 4px;
}

.view-all-props {
    margin-left: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .best-props-game-header {
        flex-direction: column;
        gap: 0.5rem;
    }

    .view-all-props {
        margin-left: 0;
    }

    .best-props-table {
        font-size: 0.85rem;
    }

    .best-props-table th,
    .best-props-table td {
        padding: 8px 4px;
    }

    .player-name {
        font-size: 0.9rem;
    }

    .prop-type {
        font-size: 0.8rem;
    }
}

/* Market comparison indicators */
.market-edge-icon {
    margin-left: 5px;
    font-size: 1rem;
    vertical-align: middle;
    color: #00C853;
    cursor: help;
}

.edge-badge.market-edge {
    display: inline-block;
    background-color: #7B1FA2;
    color: white;
    font-size: 0.6rem;
    font-weight: bold;
    padding: 1px 4px;
    border-radius: 3px;
    margin-left: 4px;
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.bookmaker-count {
    font-size: 0.7rem;
    color: #78909C;
    margin-top: 2px;
}

/* Additional icons for betting value visualization */
.value-indicator {
    margin-left: 5px;
    font-weight: bold;
}

.value-indicator.excellent {
    color: #00C853;
}

.value-indicator.good {
    color: #2196F3;
}

.value-indicator.fair {
    color: #FF9800;
}

.value-indicator.poor {
    color: #F44336;
}

/* Live Badge */
.live-badge {
    background-color: #ff3838;
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 2px 6px;
    border-radius: 4px;
    margin-left: 5px;
    animation: pulse 1.5s infinite;
    display: inline-block;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

/* Player Props Filters */
.player-props-filters {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.player-props-filters.dark-theme {
    background-color: #2a2a2a;
    border: 1px solid #3a3a3a;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.player-props-filters .form-label {
    font-size: 0.85rem;
    font-weight: 600;
    margin-bottom: 5px;
    color: #495057;
}

.player-props-filters.dark-theme .form-label {
    color: #e0e0e0;
}

.player-props-filters .form-select {
    font-size: 0.9rem;
    border-radius: 6px;
}

.player-props-filters .dark-select {
    background-color: #333;
    color: #fff;
    border-color: #444;
}

.player-props-filters .dark-select option {
    background-color: #333;
    color: #fff;
}

.player-props-filters .btn {
    padding: 8px 12px;
    font-size: 0.9rem;
}

.player-props-filters .dark-button {
    background-color: #444;
    color: #fff;
    border-color: #555;
}

.player-props-filters .dark-button:hover {
    background-color: #555;
    color: #fff;
    border-color: #666;
}

/* Dark Checkbox Styling */
.dark-checkbox {
    color: #e0e0e0;
}

.dark-checkbox .form-check-input {
    background-color: #333;
    border-color: #555;
}

.dark-checkbox .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.dark-checkbox .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    border-color: #0d6efd;
}

/* Filter Summary */
.filter-summary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    padding: 10px 15px;
    background-color: #f0f4f8;
    border-radius: 6px;
    margin-bottom: 20px;
}

.filter-summary.dark-theme {
    background-color: #2a2a2a;
    border: 1px solid #3a3a3a;
}

.filter-summary-label {
    font-weight: 600;
    color: #495057;
    margin-right: 8px;
}

.filter-summary.dark-theme .filter-summary-label {
    color: #e0e0e0;
}

.filter-badge {
    font-size: 0.85rem;
    padding: 6px 10px;
    border-radius: 20px;
    background-color: #007bff;
    margin-right: 5px;
}

.filter-results-count {
    margin-left: auto;
}

.lay-odds-tab {
    padding: 20px;
}

.nav-tabs {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
}

.nav-tabs .nav-link {
    color: var(--text-secondary);
    border: none;
    padding: 10px 20px;
    margin-right: 5px;
    border-radius: 5px 5px 0 0;
}

.nav-tabs .nav-link:hover {
    color: var(--text-primary);
    border-color: transparent;
}

.nav-tabs .nav-link.active {
    color: var(--text-primary);
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
    border-bottom-color: transparent;
}

.tab-content {
    background-color: var(--bg-primary);
    border-radius: 0 0 5px 5px;
    padding: 20px;
}

/* Odds Display */
.odds-display {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.game-card {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
}

.game-card .card-header {
    background-color: var(--bg-tertiary);
    border-bottom: 1px solid var(--border-color);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.game-card .card-header h5 {
    margin: 0;
    color: var(--text-primary);
}

.game-time {
    color: var(--text-secondary);
    font-size: 0.9rem;
}

.game-card .card-body {
    padding: 20px;
}

.game-card h6 {
    color: var(--text-primary);
    margin-bottom: 15px;
}

/* Player Props */
.player-props {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.prop-item {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 10px;
    padding: 10px;
    background-color: var(--bg-primary);
    border-radius: 5px;
    align-items: center;
}

.prop-item span {
    color: var(--text-secondary);
}

.prop-item span:first-child {
    color: var(--text-primary);
    font-weight: 500;
}

/* Responsive Design */
@media (max-width: 768px) {
    .prop-item {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .game-card .card-header {
        flex-direction: column;
        gap: 10px;
        text-align: center;
    }
} 