.api-limit-alert {
  position: fixed;
  top: 80px;
  right: 20px;
  width: 350px;
  max-width: 90%;
  z-index: 1050;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-left: 4px solid #f0ad4e;
  animation: slideIn 0.3s ease-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.api-limit-alert .alert-heading {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: #8a6d3b;
}

.api-limit-alert ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

.api-limit-alert li {
  margin-bottom: 5px;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .api-limit-alert {
    top: 60px;
    right: 10px;
    width: calc(100% - 20px);
  }
} 