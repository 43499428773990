.research-container {
    padding: 2rem 1rem;
}

.research-header {
    color: #fff;
    font-weight: 600;
    text-align: center;
}

.research-subheader {
    color: #ccc;
    font-size: 1.1rem;
    text-align: center;
}

.section-title {
    color: #fff;
    font-weight: 600;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}

/* Research Tabs */
.research-tabs {
    margin-bottom: 2rem;
}

.research-tabs .nav-link {
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    transition: all 0.2s ease;
    color: #aaa;
}

.research-tabs .nav-link.active {
    font-weight: 600;
    color: #fff;
    border-bottom-color: var(--accent-primary, #007bff);
}

/* Player Search */
.search-input {
    padding: 1rem;
    background-color: #2d2d2d;
    color: white;
    border: 1px solid #444;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.search-input:focus {
    border-color: #666;
    outline: none;
    box-shadow: 0 0 0 2px rgba(128, 128, 128, 0.3);
}

.search-suggestions {
    position: absolute;
    z-index: 1000;
    width: 100%;
    max-width: 500px;
    max-height: 300px;
    overflow-y: auto;
    background-color: #1e1e1e;
    border: 1px solid #444;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-top: 0.5rem;
}

.search-suggestions .list-group-item {
    background-color: #1e1e1e;
    color: white;
    border-color: #333;
    transition: background-color 0.2s;
    padding: 0.5rem 0.75rem;
    display: flex;
    align-items: center;
}

.search-suggestions .list-group-item:hover {
    background-color: #2c2c2c;
    cursor: pointer;
}

.search-suggestions .text-muted {
    color: #aaa !important;
}

.suggestion-headshot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

/* Player Stats */
.player-stats-container {
    margin-top: 2rem;
}

.stats-card {
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    background-color: #222;
}

.stats-card .card-header {
    background-color: #222;
    border-bottom: 1px solid #444;
    padding: 1rem 1.5rem;
    color: #fff;
}

.stats-card .card-body {
    background-color: #222;
    padding: 1.5rem;
}

.player-headshot {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid var(--accent-primary, #007bff);
}

.stat-column {
    padding: 0.5rem;
}

.stat-box {
    background-color: #2a2a2a !important;
    border-radius: 8px !important;
    padding: 1rem !important;
    text-align: center !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}

.stat-label {
    color: #aaa !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    margin-bottom: 0.5rem !important;
    text-transform: uppercase !important;
}

.stat-value {
    color: #fff !important;
    font-size: 2.4rem !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    margin-bottom: 0.75rem !important;
    letter-spacing: -0.02em !important;
}

.stat-value.positive {
    color: #28a745; /* Green for positive trend */
}

.stat-value.negative {
    color: #dc3545; /* Red for negative trend */
}

.stat-value.neutral {
    color: #fff; /* White for neutral */
}

.stat-subtext {
    color: #aaa !important;
    font-size: 0.85rem !important;
    display: flex !important;
    justify-content: space-around !important;
}

/* Custom form elements for dark theme */
.form-control, .form-select {
    background-color: #333;
    color: #fff;
    border-color: #555;
}

.form-control:focus, .form-select:focus {
    background-color: #444;
    color: #fff;
    border-color: #777;
    box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.form-label {
    color: #fff;
}

.form-select option {
    background-color: #333;
    color: #fff;
}

/* Team Roster */
.team-roster-card {
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    background-color: #222;
}

.team-roster-card .card-header {
    padding: 1.25rem;
    background-color: #1a1a1a;
    border-bottom: 1px solid #333;
}

.team-roster-card .card-header h4 {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
    color: #fff;
}

.roster-item {
    transition: background-color 0.2s ease;
    cursor: pointer;
    background-color: #333;
    color: #fff;
    border-color: #444;
}

.roster-item:hover {
    background-color: #444;
}

.roster-item .text-muted {
    color: #aaa !important;
}

.badge.bg-secondary {
    background-color: #555 !important;
}

/* Alerts and spinners */
.alert-danger {
    background-color: rgba(220, 53, 69, 0.2);
    color: #ff6b6b;
    border-color: rgba(220, 53, 69, 0.3);
}

.spinner-border {
    color: #fff;
}

/* Percentage colorizations */
.fg-percent-high, .three-pt-percent-high, .hit-rate-high, .points-high {
    color: #4ade80 !important;
    font-weight: 600 !important;
}

.fg-percent-medium, .three-pt-percent-medium, .hit-rate-medium, .points-medium {
    color: #facc15 !important;
    font-weight: 600 !important;
}

.fg-percent-low, .three-pt-percent-low, .hit-rate-low, .points-low {
    color: #f87171 !important;
    font-weight: 600 !important;
}

/* New color coding classes */
.assists-high, .rebounds-high, .steals-high, .blocks-high, .three-pm-high, .minutes-high {
    color: #4ade80 !important; /* Green for high stats */
    font-weight: 600 !important;
}

.assists-medium, .rebounds-medium, .steals-medium, .blocks-medium, .three-pm-medium, .minutes-medium {
    color: #facc15 !important; /* Yellow for medium stats */
    font-weight: 600 !important;
}

.assists-low, .rebounds-low, .steals-low, .blocks-low, .three-pm-low, .minutes-low {
    color: #f87171 !important; /* Red for low stats */
    font-weight: 600 !important;
}

.research-card {
    background-color: var(--bg-secondary, #fff);
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.research-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
}

.research-title {
    font-weight: 600;
    color: var(--text-primary, #333);
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
}

.metrics-container {
    margin-top: 1rem;
}

.metrics-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.metric-badge {
    background-color: var(--accent-primary, #007bff);
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
}

.view-research-btn {
    background-color: var(--accent-primary, #007bff);
    color: white;
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: pointer;
}

.view-research-btn:hover {
    background-color: var(--accent-primary-dark, #0069d9);
    transform: translateY(-2px);
}

.research-card .card-footer {
    background-color: transparent;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

.research-disclaimer {
    padding: 1rem;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.03);
    margin-top: 2rem;
    text-align: center;
    color: var(--text-secondary, #666);
    font-size: 0.9rem;
}

.dark-theme {
    background-color: #121212 !important;
    color: #fff !important;
}

/* Correlations Section */
.correlations-section {
    background-color: #121212;
    border-top: 1px solid #333;
    padding: 1.5rem;
}

.correlations-title {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-align: center;
}

.correlations-container {
    width: 100%;
}

.correlation-category {
    height: 100%;
    background-color: #1e1e1e;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.category-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid;
    text-align: center;
}

.category-title.strong-positive {
    color: #4ade80;
    border-color: #4ade80;
}

.category-title.strong-negative {
    color: #f87171;
    border-color: #f87171;
}

.category-title.moderate {
    color: #facc15;
    border-color: #facc15;
}

.category-title.weak {
    color: #94a3b8;
    border-color: #94a3b8;
}

.correlations-list {
    flex: 1;
    max-height: 600px; /* Only add scroll after significant content */
    overflow-y: auto;
    padding-right: 5px;
}

.correlation-item {
    background-color: #242424;
    border-radius: 8px;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.correlation-item:last-child {
    margin-bottom: 0;
}

.strong-positive-item {
    border-left: 4px solid #4ade80;
}

.strong-negative-item {
    border-left: 4px solid #f87171;
}

.moderate-item {
    border-left: 4px solid #facc15;
}

.weak-item {
    border-left: 4px solid #94a3b8;
}

.correlation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.correlation-value {
    font-weight: 600;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    background-color: rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    min-width: 60px;
    text-align: center;
}

.correlation-value.positive {
    color: #4ade80;
}

.correlation-value.negative {
    color: #f87171;
}

.correlation-pair {
    font-weight: 600;
    color: white;
    background-color: #333;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.9rem;
    margin-right: 5px;
}

.correlation-statement {
    font-size: 0.9rem;
    color: white;
    margin: 0.5rem 0;
    line-height: 1.4;
}

.correlation-details {
    color: #aaa;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    font-size: 0.85rem;
    line-height: 1.4;
}

/* Make scrollbars for correlation lists prettier */
.correlations-list::-webkit-scrollbar {
    width: 6px;
}

.correlations-list::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
}

.correlations-list::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

.correlations-list::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

/* Media queries for responsive design */
@media (max-width: 1199px) {
    .correlation-category {
        margin-bottom: 1rem;
    }
    
    .category-title {
        font-size: 0.95rem;
    }
}

@media (max-width: 991px) {
    .correlation-category {
        margin-bottom: 1.5rem;
    }
    
    .category-title {
        font-size: 1rem;
    }
    
    .correlation-statement {
        font-size: 0.85rem;
    }
    
    .correlation-pair {
        font-size: 0.85rem;
    }
}

@media (max-width: 768px) {
    .correlation-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .correlation-pair {
        margin-bottom: 0.5rem;
        width: 100%;
    }
    
    .correlation-value {
        align-self: flex-end;
    }
    
    .correlation-details {
        font-size: 0.8rem;
    }
    
    .correlations-list {
        max-height: 300px;
    }
}

/* Graph Section */
.graph-section {
    background-color: #121212;
    border-top: 1px solid #2a2a2a;
    padding: 15px;
}

/* Replace with table section */
.stats-table-section {
    background-color: #121212;
    border-top: 1px solid #2a2a2a;
    padding: 15px;
    display: block !important; /* Ensure it's always displayed */
}

.graph-title {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.stat-toggles {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.stat-toggle-btn {
    transition: all 0.2s ease;
    background-color: #1e1e1e;
    border-color: #444;
    color: #ddd;
    text-shadow: none;
}

.stat-toggle-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.stat-toggle-btn:active {
    transform: translateY(0);
}

.chart-container {
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
    stroke: #333;
}

.recharts-tooltip-cursor {
    stroke: #666;
}

.recharts-default-tooltip {
    background-color: #222 !important;
    border-color: #444 !important;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
}

.recharts-tooltip-label {
    color: #fff !important;
    font-weight: bold;
}

.recharts-tooltip-item-list {
    color: #ddd !important;
}

.graph-instruction {
    text-align: center;
    font-size: 0.9rem;
    color: #999;
    font-style: italic;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .chart-container {
        height: 300px !important;
    }

    .stat-toggles {
        justify-content: center;
    }
}

/* Player Stats Table */
.player-stats-table {
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: block !important; /* Ensure it's always displayed */
    margin-top: 20px !important; /* Add more space above the table */
}

.stats-title {
    color: #fff;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 15px !important; /* Add more space below the title */
}

.games-table-container {
    margin-top: 15px;
    max-height: 400px;
    overflow-y: auto;
    border-radius: 4px;
    display: block !important; /* Ensure it's always displayed */
    width: 100%; /* Ensure full width */
}

.games-table {
    background-color: #212121;
    color: #fff;
    margin-bottom: 0;
    width: 100%; /* Ensure full width */
    table-layout: fixed; /* Improve table layout */
}

.games-table th {
    background-color: #333;
    color: #eee;
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    padding: 10px 5px; /* Add more padding */
    font-size: 0.9rem; /* Slightly smaller font for headers */
}

.games-table td {
    text-align: center;
    vertical-align: middle;
    padding: 8px 5px; /* Add more padding */
    font-size: 0.9rem; /* Slightly smaller font for content */
    background-color: #212121 !important; /* Ensure background color is applied */
}

.highlight-column, .highlight-cell {
    background-color: #2c3e50 !important;
    font-weight: bold;
    color: #fff !important;
}

.table-instruction {
    font-size: 0.85rem;
    text-align: center;
    color: #999;
}

/* Make table responsive */
@media (max-width: 768px) {
    .games-table-container {
        max-height: 300px;
    }
    
    .stats-title {
        font-size: 1rem;
    }
}

/* Table row hover effect */
.games-table tbody tr:hover {
    background-color: #2a2a2a !important;
}

/* Stats toggle buttons for table */
.stat-toggle-btn.active {
    background-color: #2980b9;
    border-color: #2980b9;
    color: white;
}

/* Team Stats Styling */
.team-stats {
    font-size: 0.95rem;
    color: #e0e0e0;
}

.team-stats .record {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
}

.team-stats .points {
    color: #aaa;
}

/* Team Content Tabs */
.team-content-tabs {
    background-color: #1a1a1a;
    border-bottom: none;
}

.team-content-tabs .nav-link {
    color: #bbb;
    border: none;
    border-radius: 0;
    padding: 0.75rem 1.5rem;
    transition: all 0.2s ease;
    font-weight: 500;
}

.team-content-tabs .nav-link:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.05);
}

.team-content-tabs .nav-link.active {
    color: white;
    background-color: #2a2a2a;
    border-bottom: 2px solid #3498db;
}

/* Team Schedule Table */
.team-schedule-container {
    max-height: 600px;
    overflow-y: auto;
}

.team-schedule-table {
    margin-bottom: 0;
    background-color: #1a1a1a;
}

.team-schedule-table th {
    position: sticky;
    top: 0;
    background-color: #333;
    color: #fff;
    font-weight: 600;
    z-index: 1;
}

.team-schedule-table td {
    padding: 0.75rem;
    vertical-align: middle;
}

.team-schedule-table tbody tr:hover {
    background-color: #2a2a2a !important;
}

.text-success {
    color: #2ecc71 !important;
}

.text-danger {
    color: #e74c3c !important;
}

@media (max-width: 768px) {
    .team-content-tabs .nav-link {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }
    
    .team-stats {
        font-size: 0.8rem;
    }
    
    .team-stats .record {
        font-size: 0.95rem;
    }
    
    .team-schedule-table td,
    .team-schedule-table th {
        padding: 0.5rem;
        font-size: 0.85rem;
    }
}

/* Star Players Styling */
.star-players-container {
    padding: 1rem;
    background-color: #1a1a1a;
}

.star-player-card {
    background-color: #222;
    border: 1px solid #333;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.star-player-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.star-player-card .card-header {
    background-color: #2a2a2a;
    border-bottom: 1px solid #333;
    padding: 0.75rem;
}

.star-player-card .card-header h5 {
    margin-bottom: 0;
    font-weight: 600;
    color: #fff;
}

.star-player-card .card-body {
    padding: 1.25rem;
}

.star-player-headshot {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #3498db;
    margin-bottom: 1rem;
}

.star-player-card h4 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #fff;
    font-weight: 600;
}

.star-player-position {
    color: #aaa;
    font-size: 0.9rem;
    margin-bottom: 0.75rem;
}

.star-stat {
    font-size: 1.5rem;
    font-weight: 700;
    color: #3498db;
    margin-top: 0.5rem;
}

/* Responsive styles for star players */
@media (max-width: 768px) {
    .star-player-headshot {
        width: 80px;
        height: 80px;
    }
    
    .star-player-card h4 {
        font-size: 1rem;
    }
    
    .star-stat {
        font-size: 1.25rem;
    }
}

/* New player badges */
.player-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.player-badge {
    font-size: 0.7rem;
    padding: 3px 8px;
    border-radius: 12px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
}

.player-badge:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

/* Smaller badges for team roster */
.player-roster-badges {
    display: flex;
    flex-wrap: wrap;
}

.player-badge-small {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    cursor: help;
    margin-right: 3px;
    margin-bottom: 3px;
}

.player-badge-small:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* New stat colors for fouls and turnovers */
.fouls-high {
    color: #ff4d4d !important; /* Bright red */
}

.fouls-medium {
    color: #ff9933 !important; /* Orange */
}

.fouls-low {
    color: #33cc33 !important; /* Green */
}

.turnovers-high {
    color: #ff4d4d !important; /* Bright red */
}

.turnovers-medium {
    color: #ff9933 !important; /* Orange */
}

.turnovers-low {
    color: #33cc33 !important; /* Green */
}

/* Minutes shaky style */
.minutes-shaky {
    color: #e6c619 !important; /* Gold/yellow */
    animation: shake 0.5s ease-in-out;
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    20% { transform: translateX(-2px); }
    40% { transform: translateX(2px); }
    60% { transform: translateX(-2px); }
    80% { transform: translateX(2px); }
}

/* Responsive styles for badges */
@media (max-width: 576px) {
    .player-badges {
        margin-top: 10px;
    }
    
    .player-badge {
        font-size: 0.65rem;
        padding: 2px 6px;
    }
}

/* Player Card Styling */
.player-card {
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

/* Player Summary Section */
.player-summary-section {
    padding: 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
}

.summary-title {
    font-size: 18px;
    font-weight: 700;
    color: #343a40;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid #6c757d;
    padding-bottom: 5px;
    display: inline-block;
}

.summary-content {
    height: 100%;
    padding: 15px;
    background-color: #1e2023;
    border-radius: 8px;
    margin-bottom: 15px;
    overflow: hidden;
}

.summary-content h5 {
    font-size: 16px;
    font-weight: 600;
    color: #495057;
    margin-bottom: 10px;
}

.summary-list {
    list-style-type: none;
    padding-left: 5px;
    margin-bottom: 5px;
}

.summary-list li {
    padding: 3px 0;
    margin-bottom: 5px;
}

.summary-list strong {
    color: #007bff;
}

.summary-list ul {
    list-style-type: disc;
    margin-top: 5px;
    padding-left: 20px;
}

.summary-list ul li {
    font-size: 14px;
    color: #6c757d;
}

/* Divider line between columns on mobile */
@media (max-width: 767.98px) {
    .player-summary-section .col-md-6:first-child .summary-content {
        border-bottom: 1px dashed #dee2e6;
        padding-bottom: 15px;
        margin-bottom: 20px;
    }
    
    .player-summary-section .col-md-6:last-child .summary-content {
        padding-top: 5px;
    }
}

/* Divider line between columns on desktop */
@media (min-width: 768px) {
    .player-summary-section .col-md-6:first-child .summary-content {
        border-right: 1px dashed #dee2e6;
        padding-right: 20px;
    }
    
    .player-summary-section .col-md-6:last-child .summary-content {
        padding-left: 20px;
    }
}

/* Dark Theme Player Summary Section - update for two columns */
.dark-theme .player-summary-section {
    background-color: #1e1e1e;
    border-bottom: 1px solid #333;
}

.dark-theme .player-summary-section .col-md-6:first-child .summary-content {
    border-color: #444;
}

@media (max-width: 767.98px) {
    .dark-theme .player-summary-section .col-md-6:first-child .summary-content {
        border-bottom: 1px dashed #444;
    }
}

/* Restore dark theme styles */
.dark-theme .summary-title {
    color: #e0e0e0;
    border-bottom: 2px solid #555;
}

.dark-theme .summary-content h5 {
    color: #d0d0d0;
}

.dark-theme .summary-list li {
    color: #b0b0b0;
}

.dark-theme .summary-list strong {
    color: #4da3ff;
}

.dark-theme .summary-list ul li {
    color: #909090;
}

/* Improvement and Decline Styling */
.improvement-percentage {
    color: #28a745;
    font-weight: 600;
}

.decline-percentage {
    color: #dc3545;
    font-weight: 600;
}

.dark-theme .improvement-percentage {
    color: #5bd778;
}

.dark-theme .decline-percentage {
    color: #ff6b70;
}

/* Advanced Stats Section Styling */
.advanced-stats-section {
    background-color: #1a1a1a;
    border-top: 1px solid #333;
    padding: 1.5rem;
}

.advanced-stat-box {
    background-color: #252525;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.advanced-stat-box strong {
    color: #ddd;
    font-weight: 600;
}

.advanced-stat-box .small {
    font-size: 0.8rem;
    color: #999;
    margin-top: 0.5rem;
}

/* Responsive adjustments for advanced stats */
@media (max-width: 768px) {
    .advanced-stats-section {
        padding: 1rem;
    }
    
    .advanced-stat-box {
        padding: 0.75rem;
    }
}

/* Add styles for common statistical splits */
.common-split-item {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #232529;
    border-radius: 6px;
}

.common-split-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
}

.split-frequency {
    font-size: 12px;
    color: #aaa;
}

.common-split-stats {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.split-stat {
    font-size: 11px;
    padding: 3px 6px;
    border-radius: 4px;
}

/* Style for team matchups in horizontal format */
.horizontal-teams {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.team-matchup-item {
    background-color: #232529;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 5px;
}

.team-matchup-item.positive-matchup {
    background-color: #233529;
    border-left: 3px solid #4ade80;
}

.team-matchup-item strong {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
}

.team-matchup-item div {
    font-size: 13px;
    margin-bottom: 3px;
}

.efficiency-diff {
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
}

.efficiency-diff.negative {
    color: #dc3545;
}

.efficiency-diff.positive {
    color: #4ade80;
}

/* Matchup performance stats */
.matchup-performance-header {
    margin-top: 10px;
    font-weight: 600;
    font-size: 13px;
    color: #f8f9fa;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 4px 8px;
    border-radius: 4px;
    display: block;
}

.matchup-performance-header.positive {
    background-color: rgba(74, 222, 128, 0.15);
}

.matchup-performance-stats {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 5px;
}

.matchup-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 40px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 3px 6px;
    border-radius: 4px;
}

.matchup-stat-label {
    font-size: 10px;
    font-weight: 600;
    color: #adb5bd;
}

.matchup-stat-value {
    font-size: 13px;
    font-weight: 600;
    position: relative;
}

.matchup-stat-value small {
    display: block;
    font-size: 9px;
    font-weight: 600;
    text-align: center;
}

.matchup-stat-value.positive {
    color: #4ade80;
}

.matchup-stat-value.negative {
    color: #f87171;
}

/* Responsive adjustments */
@media (max-width: 991px) {
    .summary-content {
        min-height: auto;
    }
    
    .player-summary-section .col-md-3 {
        margin-bottom: 15px;
    }
}

@media (max-width: 767px) {
    .player-summary-section .row {
        flex-direction: column;
    }
    
    .summary-content {
        margin-bottom: 15px;
    }
}

/* Statistical Probabilities Section */
.prop-probabilities-section {
    background-color: #121212;
    border-top: 1px solid #333;
    padding: 1.5rem;
}

.prop-probabilities-title {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-align: center;
}

.prop-probabilities-subtitle {
    color: #aaa;
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.prop-category {
    height: 100%;
    background-color: #1e1e1e;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.prop-category-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #333;
    text-align: center;
    color: #f8f9fa;
}

.prop-table-container {
    max-height: 400px;
    overflow-y: auto;
}

.prop-table {
    margin-bottom: 0;
    font-size: 0.9rem;
}

.prop-table th {
    background-color: #212529;
    color: #f8f9fa;
    text-align: center;
    font-weight: 600;
    font-size: 0.9rem;
    padding: 0.5rem;
    white-space: nowrap;
}

.prop-table td {
    vertical-align: middle;
    text-align: center;
    padding: 0.5rem;
    background-color: #212121 !important;
}

.high-probability {
    color: #4ade80 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(74, 222, 128, 0.3) !important;
}

.medium-probability {
    color: #facc15 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(250, 204, 21, 0.3) !important;
}

.low-probability {
    color: #f87171 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(248, 113, 113, 0.3) !important;
}

.favorite-odds {
    color: #4ade80 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(74, 222, 128, 0.3) !important;
}

.underdog-odds {
    color: #f87171 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(248, 113, 113, 0.3) !important;
}

.prop-disclaimer {
    color: #aaa;
    font-style: italic;
    text-align: center;
}

/* Make scrollbars for prop tables prettier */
.prop-table-container::-webkit-scrollbar {
    width: 6px;
}

.prop-table-container::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
}

.prop-table-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

.prop-table-container::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

@media (max-width: 991px) {
    .prop-category {
        margin-bottom: 1.5rem;
    }
    
    .prop-category-title {
        font-size: 1rem;
    }
    
    .prop-table {
        font-size: 0.85rem;
    }
}

@media (max-width: 767px) {
    .prop-table-container {
        max-height: 300px;
    }
}

/* Predicted Stats Container */
.predicted-stats-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    background-color: #1a1a1a;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.predicted-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #222;
    padding: 10px;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.predicted-stat:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.predicted-stat-label {
    font-size: 0.8rem;
    font-weight: 600;
    color: #aaa;
    text-align: center;
    margin-bottom: 5px;
}

.predicted-stat-value {
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
}

.prediction-note {
    grid-column: span 2;
    font-size: 0.75rem;
    color: #888;
    margin-top: 10px;
    text-align: center;
    font-style: italic;
}

/* Media query for smaller screens */
@media (max-width: 767px) {
    .predicted-stats-container {
        grid-template-columns: repeat(3, 1fr);
    }
    
    .prediction-note {
        grid-column: span 3;
    }
}

@media (max-width: 576px) {
    .predicted-stats-container {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .prediction-note {
        grid-column: span 2;
    }
}

/* Next Game Information */
.next-game-info {
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 15px;
    border-left: 4px solid #3498db;
}

.next-game-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

.next-game-opponent {
    font-size: 1.1rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 4px;
}

.next-game-datetime {
    font-size: 0.9rem;
    color: #aaa;
}

.past-matchups-info {
    font-size: 0.85rem;
    color: #888;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #333;
}

.matchup-type {
    display: inline-block;
    margin-top: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 600;
}

.matchup-type.favorable {
    background-color: rgba(46, 204, 113, 0.2);
    color: #2ecc71;
}

.matchup-type.unfavorable {
    background-color: rgba(231, 76, 60, 0.2);
    color: #e74c3c;
}

.no-team-warning {
    background-color: rgba(255, 152, 0, 0.2);
    color: #ff9800;
    padding: 6px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 500;
    border-left: 3px solid #ff9800;
}

/* Ensure table styles apply correctly */
.games-table td {
    text-align: center;
    vertical-align: middle;
    padding: 8px 5px; /* Add more padding */
    font-size: 0.9rem; /* Slightly smaller font for content */
    background-color: #212121 !important; /* Ensure background color is applied */
}

.games-table td[class*="high"] {
    color: #4ade80 !important;
    font-weight: 600 !important;
}

.games-table td[class*="medium"] {
    color: #facc15 !important;
    font-weight: 600 !important;
}

.games-table td[class*="low"] {
    color: #f87171 !important;
    font-weight: 600 !important;
}

/* Probability and odds styling */
.high-probability {
    color: #4ade80 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(74, 222, 128, 0.3) !important;
}

.medium-probability {
    color: #facc15 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(250, 204, 21, 0.3) !important;
}

.low-probability {
    color: #f87171 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(248, 113, 113, 0.3) !important;
}

.favorite-odds {
    color: #4ade80 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(74, 222, 128, 0.3) !important;
}

.underdog-odds {
    color: #f87171 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(248, 113, 113, 0.3) !important;
}

/* Ensure prop table styles apply correctly */
.prop-table td {
    vertical-align: middle;
    text-align: center;
    padding: 0.5rem;
    background-color: #212121 !important;
}

/* Make sure the colors are visible in the tables */
.games-table, .prop-table {
    --bs-table-striped-bg: #262626 !important;
    --bs-table-striped-color: #fff !important;
}

.games-table tr:nth-child(odd) td, .prop-table tr:nth-child(odd) td {
    background-color: #262626 !important;
}

.games-table tr:nth-child(even) td, .prop-table tr:nth-child(even) td {
    background-color: #212121 !important;
}

/* Table styles for dark theme tables */
.dark-theme .table-dark,
.dark-theme .games-table,
.dark-theme .prop-table {
    --bs-table-bg: #212121 !important;
    --bs-table-striped-bg: #262626 !important;
    --bs-table-striped-color: #fff !important;
    --bs-table-active-bg: #2a2a2a !important;
    --bs-table-hover-bg: #2a2a2a !important;
    background-color: #212121 !important;
    color: #fff !important;
}

/* Override striped table backgrounds */
.dark-theme .table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: #262626 !important;
}

.dark-theme .table-striped > tbody > tr:nth-of-type(even) > * {
    background-color: #212121 !important;
}

/* Ensure color coding takes precedence */
.dark-theme .games-table td[class*="high"],
.dark-theme .games-table td[class*="-high"],
.dark-theme .prop-table td[class*="high-probability"],
.dark-theme .prop-table td[class*="favorite-odds"] {
    color: #4ade80 !important;
    font-weight: 600 !important;
}

.dark-theme .games-table td[class*="medium"],
.dark-theme .games-table td[class*="-medium"],
.dark-theme .prop-table td[class*="medium-probability"] {
    color: #facc15 !important;
    font-weight: 600 !important;
}

.dark-theme .games-table td[class*="low"],
.dark-theme .games-table td[class*="-low"],
.dark-theme .prop-table td[class*="low-probability"],
.dark-theme .prop-table td[class*="underdog-odds"] {
    color: #f87171 !important;
    font-weight: 600 !important;
}

/* Add additional styling to ensure table headers are visible */
.dark-theme .table-dark > thead > tr > th,
.dark-theme .games-table th,
.dark-theme .prop-table th {
    background-color: #333 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border-bottom: 2px solid #444 !important;
    position: sticky;
    top: 0;
    z-index: 5;
}

/* Color coding for past performances */
.points-high, .assists-high, .rebounds-high, .steals-high, .blocks-high, .three-pm-high {
    color: #4ade80 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(74, 222, 128, 0.3) !important;
}

.points-medium, .assists-medium, .rebounds-medium, .steals-medium, .blocks-medium, .three-pm-medium {
    color: #facc15 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(250, 204, 21, 0.3) !important;
}

.points-low, .assists-low, .rebounds-low, .steals-low, .blocks-low, .three-pm-low {
    color: #f87171 !important;
    font-weight: 700 !important;
    text-shadow: 0 0 1px rgba(248, 113, 113, 0.3) !important;
} 